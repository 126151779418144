.card {
  padding-top: 70px;
  &__item {
    background: $blue;
    color: #fff;
    border-radius: 10px;
    padding: 110px 20px 20px 20px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;

    &__body {
      padding-bottom: 25px;
      font-size: 16px;
      font-style: italic;
      font-family: "Roboto Condensed", sans-serif;
    }

    &__footer {
      position: relative;
      margin-top: auto;
      padding-top: 30px;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        height: 1px;
        left: 10%;
        right: 10%;
        background-color: #0075af;
        transform: rotate(-10deg);
      }
    }

    &__author {
      font-family: "Roboto Condensed Bold", sans-serif;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: condensed;
      line-height: 1.11;
      letter-spacing: normal;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
    }

    &__date {
      margin-top: 16px;
      border-radius: 10px;
      background-color: #0075af;
      font-family: "Roboto Condensed Bold", sans-serif;
      font-size: 10px;
      font-weight: normal;
      font-style: normal;
      font-stretch: condensed;
      line-height: 1.73;
      letter-spacing: 4.8px;
      color: #ffffff;
    }
    
    &__note {
      background: #fff;
      width: 127px;
      height: 127px;
      box-shadow: 0px 5px 3.5px 0.5px rgba(105, 105, 105, 0.25);
      background-color: #ffffff;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $blue;

      &--number {
        font-family: "Roboto Condensed Bold", sans-serif;
        font-size: 38px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        font-weight: bold;
        font-style: italic;
        font-stretch: condensed;
        line-height: 0.53;
        letter-spacing: normal;
        text-align: center;
        color: #0082c3;

        .sep {
          font-weight: normal;
          font-family: "Roboto Condensed", sans-serif;
        }
      }

      .global-reviews__stars {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 95px;
        margin: 10px auto 0;
      }
    }
  }
}