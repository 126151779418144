[data-toggle] {
  cursor: pointer;
}

.toggle {
  display: none;

  &.toggle--active {
    display: block;
  }
}