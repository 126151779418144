#header {
  padding-left: 0;
  position: relative;
  padding: 0 0 65px;
  overflow: hidden;
  background: #fff;
  z-index: 6;
  box-shadow: 2px 2px 5px $grey-light;
  @include media('>=desktop') {
    padding: 0 0 50px;
    box-shadow: 0px 3px 4px 0 rgba(0, 0, 0, 0.08);

    .page-header, .page-header-container {
      height: 50px;
    }

    &.page-header {
      height: 50px;
    }
  }

  @include media('>phone', '<desktop') {
    &.page-header {
      height: 52px;
    }
    .page-header-container {
      height: 52px;
    }
  }

  @media only screen and (max-width: 767px) {
    padding-left: 0 !important;

    .header-minicart {
      height: calc(100% + 20px) !important;
      width: 110px;
      background: $yellow !important;
      top: 0 !important;
    }
  }

  .header-minicart {

    @include media('>=tablet') {
      width: auto;
      text-align: left;
      display: flex;
      align-items: center;
      padding-top: 0 !important;

      i {
        font-size: 22px;
        bottom: auto;
        transform: translate(-50%, -50%);
      }
      a {
        height: auto !important;

        .picto {
          width: 30px;
          margin-right: 6px;
        }

        display: flex;
        align-items: center;
      }
    }

    p {
      line-height: 1.1;
    }
  }

  .bloc_header {
    width: 75px;

    &.header_languages {
      float: left;

      .form-language {
        width: 50px;
        @media only screen and (max-width: 767px) {
          margin-top: 10px;
        }
      }

      .select2-container {
        width: 100% !important;
        @media only screen and (max-width: 767px) {
          display: block;
          span {
            display: block;
          }
        }
      }
    }

    @include media('>=tablet') {
      width: auto;
      text-align: left;
      display: flex;
      align-items: center;
      margin-right: 30px;
      .picto {
        margin-right: 6px;
        margin-top: 0;
      }
      &.header-account {
        margin-right: 15px;
      }
      p {
        text-align: left;
        padding-top: 0;
      }
      a {
        display: flex;
        align-items: center;
      }
    }

    p {
      line-height: 1.1;
    }

    .header-minicart i {
      font-size: 23px;
    }

    .skip-account {
      left: -7px;

      @media only screen and (min-width: 768px) {
        width: 75px;
      }

      @include media('>phone', '<desktop') {
        height: 52px;
      }
    }
  }

  h1 {
    margin-bottom: 0;
  }

  @media only screen and (min-width: 771px) {
    .navigation__button {
      height: 50px;
    }
    .logo {
      height: 34px;
      min-height: 34px;
      top: 50%;

      .large {
        margin-top: 0;
        width: 140px;
      }
    }
  }

  @media only screen and (min-width: 771px) {
    .header-minicart {
      .picto {
        margin-top: 3px;

        span {
          left: 24px;
          top: -25px;
        }
      }

      .skip-cart {
        height: 50px;
      }
    }
  }

  .bloc_header {
    @media only screen and (min-width: 771px) {
      height: 50px;

      .picto {
        //margin-top: 5px;
        //height: 13px;
      }
    }
  }

  @media (max-width: 479px) {
    margin-top: -20px;
    transform: skewY(-5deg);
    padding-top: 20px;
    margin-bottom: -15px;

    > div {
      transform: skewY(5deg);
    }
  }

  .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (min-width: 810px) {
      display: block;
    }
  }

  .bloc_header.header-tel {
    display: none;

    @include media('>=desktop') {
      display: flex;
    }
  }

  /*
  &:after {
    position: absolute;
    content: '';
    pointer-events: none;
    top: 0;
    left: -25%;
    z-index: 1;
    width: 150%;
    height: 50%;
    background: #fff;
    -webkit-transform: rotate(-3deg);
    transform: rotate(-3deg);
    -webkit-transform-origin: 3% 0;
    transform-origin: 3% 0;

  }

   */

  @include media('>=tablet') {
    max-width: inherit;
  }

  /**
  * Banner info
   */
  .banner-info {
    width: 100%;
    min-height: 35px;
    background: $yellow;
    text-align: center;
    line-height: 35px;
    font-weight: bold;
    font-size: 17px;
    color: $grey-greetings;
    font-family: "Roboto Condensed Bold", sans-serif;

    @media only screen and (max-width: 479px) {
      padding-top: 35px;
    }
  }
}

#top .cms-page-view .main-container {
  box-shadow: inherit;
}
