.visually-hidden {
  position: absolute !important;
  height: 1px; width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

.visible-desktop {
  display: none;
  @include media('>=desktop') {
    display: block;
  }
}

.visible-mobile {
  display: none;
  @include media('<desktop') {
    display: block;
  }
}