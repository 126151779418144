.flexie {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include media('>=desktop') {
    flex-direction: row;
    .flexie--wrap {
      flex-wrap: wrap;
    }

    .flexie--column {
      flex-direction: column;
    }


    .flexie--row {
      flex-direction: row;
    }

    > * {
      &.flexie__item--boxed {
        padding: 25px;
      }
    }

    @for $i from 1 through 12 {
      .flexie__item--#{$i} {
        flex: inherit;
        width: calc(100% / #{$i});
        box-sizing: border-box;

        &.flexie__item--m10 {
          width: calc((100% / #{$i}) - 10px);
          margin-left: 10px;
          margin-right: 10px;
        }

        &.flexie__item--m25 {
          width: calc((100% / #{$i}) - 50px);
          margin-left: 25px;
          margin-right: 25px;
        }

        &.flexie__item--m50 {
          width: calc((100% / #{$i}) - 50px);
          margin-left: 50px;
          margin-right: 50px;
        }
      }
    }
  }
}