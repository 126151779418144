/*! CSS Used from: https://www.decathlon.be/vassets/css/header.7ec9e41210ea55ffe60f.css */

.navigation__button {
  position: relative;
  display: block;
  width: 66px;
  z-index: 2;
  height: 66px;
  background-color: #fff;
  transform: rotate(0deg);
  float: left;
  text-decoration: none;
  transition: .28s ease-in-out;
  &:hover {
    text-decoration: none;
  }

  @media (min-width: 810px) {
    height: 65px;
    border-right: 1px solid #edeff1;
  }

  div {
    position: relative;
    width: 26px;
    margin: 0 auto;
  }
  span {
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: #575859;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .28s ease-in-out;
    &:first-child {
      top: 19px;
    }
    &:nth-child(2) {
      top: 26px;
    }
    &:nth-child(3) {
      top: 33px;
    }

    @include media('>=tablet') {
      &:first-child {
        top: (19px - 7px);
      }
      &:nth-child(2) {
        top: (26px - 7px);
      }
      &:nth-child(3) {
        top: (33px - 7px);
      }
    }
  }
}

.navigation__button__label {
  margin: 0;
  padding: 41px 0 0;
  color: #575859;
  text-transform: uppercase;
  font-size: .7em;
  text-align: center;

  @include media('>=tablet') {
    padding-top: (41px - 7px);
  }
}

.navigation__button__label--opened {
  display: none;
}

/*! CSS Used from: https://www.decathlon.be/static/css/style.css */

*:focus {
  outline: none !important;
}