@font-face {
  font-family: 'Decathlon Cube';
  src: url('../fonts/decathloncube/DecathlonCube.eot');
  src: url('../fonts/decathloncube/DecathlonCube.ttf') format('truetype'),
  url('../fonts/decathloncube/DecathlonCube.eot?#iefix') format('embedded-opentype'),
  url('../fonts/decathloncube/DecathlonCube.woff') format('woff'),
  url('../fonts/decathloncube/DecathlonCube.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Decathlon Cube Extras';
  src: url('../fonts/decathloncube/icomoon.eot');
  src: url('../fonts/decathloncube/icomoon.ttf') format('truetype'),
  url('../fonts/decathloncube/icomoon.eot?#iefix') format('embedded-opentype'),
  url('../fonts/decathloncube/icomoon.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Chapaza Regular';
  src: url('../fonts/card/chapaza-webfont.ttf') format('truetype'),
  url('../fonts/card/chapaza-webfont.woff2') format('woff2'),
  url('../fonts/card/chapaza-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Bloomsbury Script';
  src: url('../fonts/card/bloomsbury-script-webfont.ttf') format('truetype'),
  url('../fonts/card/bloomsbury-script-webfont.woff2') format('woff2'),
  url('../fonts/card/bloomsbury-script-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.upper {
  text-transform: uppercase;
}

.lower {
  text-transform: inherit;
}

.muted {
  color: #7b7b7b;
}

hr {
  border: 0;
  height: 1px;
  background-color: #ced0d4;
  margin: 15px auto;
}

.title--in-movement {
  font-family: "Roboto Condensed Bold", sans-serif;
  font-size: 30px;
  font-weight: bold;
  font-style: italic;
  font-stretch: condensed;
  line-height: 1.14;
  letter-spacing: -0.4px;
  text-align: center;
  color: #000000;
  transform: skewY(-7deg) translateY(20%);
  text-align: center;
  text-transform: uppercase;
  padding: .7em 0;
  margin-bottom: 3em;

  h1 {
    color: #000;
    font-weight: bold;
    font-style: italic;
  }

  &.title--bordered {
    border-top: 1px solid #000;
    border-bottom: 1px solid $blue;
  }

  span:not(.visible-desktop) {
    display: block;
  }

  span:not(.visible-mobile) {
    @include media('>=desktop') {
      display: initial;
    }
  }

  span {
    color: $blue;
  }
}