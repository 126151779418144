/*! CSS Used from: https://www.decathlon.be/vassets/css/header.7ec9e41210ea55ffe60f.css */

$heightMenu: calc(100% - 88px);

.icon {
  font-family: "Decathlon Cube";
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  letter-spacing: 0;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  font-smoothing: subpixel-antialiased;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  zoom: 1;
}

.icon-star:before {
  content: "\E04B";
}

.icon-user:before {
  content: "\E001";
}

.icon-search:before {
  content: "\E003";
}

.icon-pin:before {
  content: "\E004";
}

.icon-arrow1-l:before {
  content: "\E006";
}

.icon-arrow1-r:before {
  content: "\E007";
}

.icon-help-line:before {
  content: "\E024";
}

.icon-close:before {
  content: "\E03B";
}

.navigation {
  position: absolute;
  top: 85px;
  z-index: 9999;
  font-size: 1.3rem;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  height: $heightMenu;
  font-weight: 400;
  font-style: normal;
  background-color: $blue;
  @media (min-width: 480px) {
    top: 0;
    height: 100vh;
  }

  &:before {
    @media (max-width: 479px) {
      content: "";
      transform: skewY(-5deg);
      background-color: $blue;
      position: absolute;
      top: -15px;
      left: 0;
      right: 0;
      height: 70px;
    }
  }

  &:before {
    content: "";
    
  }
  .list-reset {
    margin: 0;
    padding: 0;
  }
}

@media only screen and (max-width: 479px) {
  .navigation {
    left: -100vw;
    width: 100vw;
  }
}

@media only screen and (min-width: 480px) {
  .navigation {
    left: -400px;
    width: 400px;
  }
}

@media only screen and (min-width: 801px) {
  .navigation {
    left: -400px;
    width: 400px;
  }
}

@media (min-width: 801px) {
  .navigation {
    font-size: 1.4rem;
  }
}

.navigation--open {
  animation-duration: .2s;
  left: 0;

  ~ .wrapper {
    #header {
      .navigation__button {
        background: $blue;
        height: 100px;


        span {
          background: #fff;
          &:first-child {
            top: 25px;
            transform: rotate(135deg);
          }
          &:nth-child(2) {
            opacity: 0;
            left: -60px;
          }
          &:nth-child(3) {
            top: 25px;
            transform: rotate(-135deg);
          }
        }

        .navigation__button__label {
          color: #fff;

          &.navigation__button__label--opened {
            display: block;
            margin: auto;
          }

          &.navigation__button__label--closed {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 479px) and (orientation: portrait) {
  .navigation--open {
    animation-name: k;
  }
}

@media (min-width: 801px) {
  .navigation--open {
    animation-name: p;
  }
}

.navigation--logo {
  margin-top: 14px;
  padding-left: 40px;
}

.navigation--baseline {
  vertical-align: middle;
  padding-left: 10px;
  font-size: 1em;
  text-transform: uppercase;
  color: #fff;
}

.navigation__header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 66px;
  background-color: $blue;
}

.navigation__header__back, .navigation__header__title {
  display: table-cell;
  vertical-align: middle;
}

.navigation__header__back {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 66px;
  height: 66px;
  padding: 14px;
  text-align: center;
  color: #fff;
  background-color: #0075af;
  opacity: 0;
  transition: color .2s ease-in-out;
  span {
    display: inline-block;
    vertical-align: super;
    width: 100%;
    text-transform: uppercase;
    font-size: .7em;
  }
  .icon-arrow1-l {
    height: 22px;
    &:before {
      height: 22px;
      line-height: 22px;
      font-size: 22px;
    }
  }
  &:after {
    content: "";
    position: absolute;
    background-color: #0075af;
    height: 100%;
    width: 20px;
    top: 0;
    right: -6px;
    box-sizing: content-box;
    z-index: 0;
    transform: skewX(-7deg);
  }
  &:active, &:focus, &:hover {
    background-color: #005d8b;
    color: #fff;
    &:after {
      background-color: #005d8b;
      color: #fff;
    }
  }
}

.navigation__header__title {
  margin-left: 28px;
  padding: 0 0 0 60px;
  font-size: 1.3em;
  font-weight: 700;
  font-style: italic;
  text-transform: uppercase;
  color: #fff;
}

.navigation__slider {
  height: 100%;
}

.navigation__wrapper {
  height: $heightMenu;
  position: relative;
  @media (min-width: 801px) {
    height: calc(100vh - 15px);
  }

  .navigation__entry {
    position: relative;
    z-index: 2;
    &__list {
      &__item {
        &--highlighted {
          background: #fff;
          color: $blue;
          a {
            color: $blue;
            &:hover {
              color: lighten($blue, 2%);
            }
          }
          &:not(:first-child) {
            &:after {
              content: "";
              position: absolute;
              top: 0;
              left: 10%;
              right: 10%;
              background: $blue;
              height: 1px;
              opacity: .5;
            }
          }
        }
      }
    }
  }
}

.navigation__link {
  text-transform: uppercase;
  span {
    display: table-cell;
    vertical-align: middle;
    color: #575859;
  }
  .icon-arrow1-r {
    text-align: center;
    color: $blue;
    &:before {
      width: 20px;
      font-size: .9em;
    }
  }
}

.navigation__entry {
  display: none;
  z-index: 2;
  position: relative;
  height: 100%;
  position: relative;
  &[data-entry=menu-all-sports] {
    height: calc(100% - 66px) !important;
  }
}

.navigation__entry--scroll {
  width: 100%;
  height: calc(100% + 15px);
  overflow-y: auto;
  padding-bottom: 100px;
  a img {
    transition: opacity .2s ease-in-out;
  }
}

.navigation__entry--wrapper {
  overflow: hidden;
  margin-bottom: 0;
}

.navigation__entry--visible {
  display: flex;
  flex-wrap: wrap;
}

.navigation__entry__list {
  margin: 0;
  padding: 0;
  font-size: 1em;
  font-weight: 700;
  background-color: #fff;

  label,
  a {
    display: table;
    width: 100%;
    padding: 6px 20px 6px 30px;
    color: $blue;
    border-bottom: 1px solid #edeff1;
    transition: color .2s ease-in-out, background-color .2s ease-in-out;
    &:focus, &:hover {
      color: #fff;
      background-color: $blue;
    }
    &:focus {
      .icon-arrow1-r, span {
        color: #fff;
      }
    }
    &:hover {
      .icon-arrow1-r, span {
        color: #fff;
      }
    }
  }
}

.navigation__entry__list--default, .navigation__entry__list--sublinks {
  padding-left: 0;
}

.navigation__entry__list--default li, .navigation__entry__list--sublinks li {
  position: relative;
}

.navigation__entry__list--default li:before, .navigation__entry__list--sublinks li:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity .2s ease-in-out;
  opacity: 0;
  cursor: pointer;
}

.navigation__entry__list--default li {
  &:not(:last-child) {
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 10%;
      right: 10%;
      background: #fff;
      height: 1px;
      opacity: .5;
    }
  }
}

.navigation__entry__list--sublinks li {
  &:focus {
    .icon-arrow1-r, &:before {
      //opacity: 1;
    }
  }
  &:hover {
    .icon-arrow1-r, &:before {
      //opacity: 1;
    }
  }
}

.navigation__entry__list--default,
.navigation__entry__list--sublinks {
  a, label {
    font-family: Roboto-Condensed, Roboto, Arial, sans-serif;
    font-style: italic;
    position: relative;
    display: table;
    width: 100%;
    padding: 13px 40px 13px 40px;
    color: #fff;
    border: 1px solid transparent;

    &:hover {
      opacity: 0.75;
      color: #fff;
      text-decoration: none;
    }
  }

  label {
    padding-right: 75px;
  }

  input[type="checkbox"] {
    display: inline;
     ~ .navigation__entry__sublist {
      max-height: 0px;
      transition: max-height .2s ease-in-out;
      overflow: hidden;
    }

    &:checked {
      ~ .navigation__entry__sublist {
        max-height: 600px;
      }

      + label {
        .icon-arrow1-r {
          transform: rotate(90deg);
        }
      }
    }
  }
}

.navigation__entry__list--default .icon-arrow1-r, .navigation__entry__list--sublinks .icon-arrow1-r {
  position: absolute;
  top: 50%;
  right: 45px;
  width: 20px;
  height: 20px;
  color: #fff;
  margin-top: -10px;
  transition: transform .2s ease-in-out;
}

.navigation__entry__list--default .icon-arrow1-r:before, .navigation__entry__list--sublinks .icon-arrow1-r:before {
  position: static;
  width: auto;
  height: auto;
  padding-right: 40px;
  line-height: normal;
  text-align: right;
}

.navigation__entry__list--default {
  margin: 0;
  padding-top: 10px;
  a, label {
    font-weight: 700;
    @include font-ratio(16px, .9, 320px, 400px);
  }
}

.navigation__illustr {
  width: 70%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  @media (min-width: 480px) {
    margin-top: 30px;
  }
}

.navigation__entry__list--sublinks {
  margin-top: 22px;
  margin-bottom: 22px;
  padding: 0;
}

@media (min-width: 801px) {
  .navigation__entry__list a {
    padding-left: 40px;
    padding-right: 40px;
  }
}


.navigation__entry__sublist {
  li:not(:last-child) {
    &:after {
      background-color: $blue;
    }
  }
  background: #fff;
  a, label {
    color: $blue;

    &:hover {
      color: $blue;
    }
  }
  span {
    font-weight: 400;
  }
}

.navigation__entry__link {
  display: flex;
  align-items: center;
  width: 100%;
  text-transform: uppercase;
  border-bottom: 1px solid #edeff1;
  background-color: #edeff1;
  transition: background-color .2s;
  padding-right: 20px;
  &:not([data-type=all-about]) {
    min-height: 55px;
  }
  &[data-type=all-about] {
    min-height: 100px;
  }
  &:focus, &:hover {
    background-color: #fff;
    color: $blue;
  }
  &:focus .icon-arrow1-r:before, &:hover .icon-arrow1-r:before {
    color: #0075af;
    transition: .2s ease-in-out;
  }
  div, span {
    display: table-cell;
    vertical-align: middle;
  }
  > {
    span {
      padding-left: 30px;
      color: #575859;
      flex-grow: 1;
      span {
        width: 100%;
        font-weight: 700;
        color: $blue;
      }
    }
    div:first-child {
      min-width: 100px;
      img {
        width: 100%;
        height: auto;
      }
      + span {
        padding-left: 10px;
      }
    }
  }
  .icon-arrow1-r {
    width: 20px;
    text-align: center;
    &:before {
      width: 20px;
      color: #d4d7d9;
    }
  }
}

@media (min-width: 801px) {
  .navigation__entry__link {
    padding-right: 40px;
    &[data-type=all-about] {
      min-height: 140px;
    }
    > {
      span {
        padding-left: 40px;
      }
      div:first-child {
        min-width: 120px;
      }
    }
  }
}

.navigation__footer {
  position: absolute;
  bottom: -90px;
  left: 0;
  width: 100%;
  border-top: 1px solid $blue;
  //box-shadow: 0 0 10px #005d8b;
  > ul {
    display: flex;
  }
}

@media (min-width: 801px) {
  .navigation__footer {
    display: none;
  }
}

.navigation__footer__entry {
  flex-grow: 1;
  flex-basis: 0;
  vertical-align: middle;
  height: 76px;
  text-align: center;
  > a {
    position: relative;
    z-index: 1;
    display: inline-block;
    width: 100%;
    height: 76px;
    font-size: .7em;
    border-left: 1px solid #006ea5;
    color: #fff;
    background-color: #0075af;

    .icon {
      display: block;
      margin: 10px auto 5px auto;
    }


    span {
      display: block;
      font-size: 0.6em;
      line-height: 1.3;
    }
  }
}

.navigation__footer__lang {
  position: relative;
  bottom: 0;
  margin: 0;
  padding: 0;
  transition: bottom .2s ease-in-out;
  li {
    width: 100%;
    height: 50px;
    border-top: 1px solid #0075af;
  }
  a {
    width: 100%;
    height: 100%;
    background-color: #0075af;
    border-bottom: 1px solid #006ea5;
    display: flex;
    align-items: center;
    justify-content: center;
    > span {
      color: #fff;
    }
  }
}

.navigation__footer--lang {
  padding-top: 20px;
  span {
    font-size: 1.3em;
  }
  .icon-arrow-up {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #fff;
  }
}

.navigation__footer i {
  margin: 10px auto 0;
  &:before {
    font-size: 30px;
  }
}

.navigation .associated-content__list {
  background: #edeff1;
  li {
    position: relative;
    padding-top: 36%;
  }
  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 7%;
    padding-bottom: 7%;
  }
  img {
    width: 75%;
  }
  span {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 11%;
  }
}

.associated-content {
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.associated-content__title {
  margin: 0;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  font-size: .8em;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #7d7e80;
}

.associated-content__list {
  overflow: hidden;
  margin: 0;
  padding: 0;
  li {
    display: block;
    float: left;
    width: 33.3333%;
    text-align: center;
    box-sizing: border-box;
  }
  a {
    display: block;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    transition: background-color .2s ease-in-out;
    &:focus, &:hover {
      background-color: #fff;
    }
  }
  img {
    max-width: 100%;
    height: auto;
  }
  span {
    display: block;
    overflow: hidden;
    padding-right: 6px;
    padding-left: 6px;
    font-size: .9em;
    text-transform: uppercase;
    color: #575859;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.navigation__entry--scroll {
  padding-bottom: 0px;
}


i#icon-arrow-menu-all-sports {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 14px;
  &:before {
    line-height: 14px;
    font-size: 14px;
  }
}

.navigation__layout {
  display: none;
  position: fixed;
  top: 75px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9998;
  background-color: #fff;
  opacity: 0;
  transition: opacity .28s ease-in-out;
  cursor: pointer;
  &.navigation__layout--active {
    @media only screen and (min-width: 801px) {
      display: block;
      opacity: .5;
    }
  }
}


@media only screen and (min-width: 480px) {

  .navigation--closed {
    animation-name: o;
    margin-left: 0;
    animation-duration: .2s;
  }

  .navigation--opened {
    animation-duration: .2s;
    animation-name: n;
    margin-left: 400px !important;
  }
}

/*! CSS Used keyframes */

@keyframes n {
  0% {
    margin-left: 0 !important;;
  }

  to {
    margin-left: 400px !important;;
  }
}

@keyframes o {
  0% {
    margin-left: 400px !important;;
  }

  to {
    margin-left: 0 !important;;
  }
}

@keyframes k {
  0% {
    left: -100vw
  }

  to {
    left: 0;
  }
}


@keyframes p {
  0% {
    left: -400px;
  }

  to {
    left: 0;
  }
}

.menu {
  display: none;

  @include media('>=tablet') {
    display: block;
  }
}