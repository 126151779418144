.steps {
  overflow: hidden;
  padding-bottom: 10px;

  img {
    max-width: 100%;
  }
}

.step__header {
  width: 100%;

  &-wrapper {
    @include media('>=desktop') {
      background-image: linear-gradient(90deg, #0182B8 51%, #42B6C8 99%);
      overflow: hidden;
      position: relative;
      margin-bottom: -4%;
      padding-left: 15px;
      padding-right: 15px;
      &:before {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background: #F5F5F5;
        height: 100%;
        transform: skewY(-7deg);
        transform-origin: 0 50%;
      }
      img {
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;

        &.visible-desktop {
          min-height: 400px;
          max-height: calc(60vh - 50px);
          width: auto;
        }
      }
    }
  }
}

.step__header-nav {
  background-color: #fff;
  display: none;
  z-index: 20;
  position: relative;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  @include media('>=desktop') {
    display: block;
    ul {
      display: flex;
      margin: 0 -10px;
      overflow: hidden;

      .step__header-nav__item {
        flex: 1;
        text-align: center;
        position: relative;

        &:not(:last-child) {
          &:after {
            content: "";
            position: absolute;
            height: 25px;
            width: 1px;
            border-right: 1px solid #ccc;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        a {
          display: block;
          color: #29333f;
          padding: 13px 10px;
          text-transform: uppercase;
          font-family: "Roboto Condensed Bold", sans-serif;
        }
      }

      .step__header-nav__item--active {

        a {
          color: $blue;
        }
      }
    }
  }
}

.step {
  &__form {
    position: relative;
    z-index: 3;
  }
}

.step__body{

  @include media('>=tablet') {
    .btn-back {
      position: absolute;
      top: .7%;
    }
  }
}

.step__progress {
  @include media('>=desktop') {
    color: $blue;
  }

  &__inner {
    display: flex;
    padding: 10px 10px;
    flex-wrap: wrap;


    @include media('>=desktop') {
      padding-top: 20px;
    }

    .btn-back {
      order: 2;
      margin-right: auto;
    }

    .btn-next {
      order: 3;
      margin-left: auto;
      min-width: 160px;
    }

    ol {
      width: 100%;
      justify-content: center;
      margin-bottom: 20px;
    }

    @include media('>=desktop') {
      padding: 20px 30px 30px 30px;
      align-items: center;

      ol {
        width: auto;
        margin-bottom: 0;
      }

      .btn-back {
        order: -1;
      }

      .btn-next {
        order: 3;
      }
    }
  }

  ol {
    display: flex;
    margin-left: auto;
    margin-right: auto;

    .step__item {
      margin: 0 6px;
      text-transform: uppercase;
      text-indent: -9999px;
      width: 10px;
      height: 10px;
      background-color: #cbcdcf;
      border-radius: 50%;

      @include media('>=desktop') {
        margin: 0 20px;
        border-radius: 0;
        text-indent: inherit;
        padding: 10px 0;
        width: auto;
        height: auto;
        background-color: transparent;
      }

      &--current {
        font-family: "Roboto Condensed Bold", sans-serif;
        background-color: #ffea28;
        @include media('>=desktop') {
          background-color: transparent;
          border-bottom: 3px solid $blue;
        }
      }

      &--active {
        font-family: "Roboto Condensed Bold", sans-serif;
        background-color: $blue;
        cursor: pointer;
        @include media('>=desktop') {
          background-color: transparent;
          border: none;
        }
      }

      &:not(:last-child) {
        position: relative;

        @include media('>=desktop') {
          margin-right: 40px;
        }

        &:after {
          content: "\E007";
          font-family: "Decathlon Cube";
          right: -40px;
          top: 0;
          height: 100%;
          width: 2.2em;
          font-size: 1em;
          display: flex;
          position: absolute;
          justify-content: center;
          align-items: center;
          font-weight: 400;
          line-height: 1;
          z-index: 1;
        }
      }
    }
  }
}

.card-value {
  text-align: center;
  font-family: "Roboto Condensed Bold", sans-serif;
  @include font-ratio(21px);
  font-weight: bold;
  font-style: italic;
  font-stretch: condensed;
  line-height: 2.33;
  letter-spacing: -0.2px;
  text-align: center;
  color: #000000;
  text-transform: uppercase;
  @include media('>=desktop') {
    padding-top: 0;
    border-top: none;
  }
}

.step2 {
  button {
    position: relative;
  }

  .btn-card-mentions {
    position: absolute;
    left: 30%;
    @include media('<tablet') {
      position: relative;
      margin: auto;
      left: -3%;
    }
  }



  .flexie {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 0;
    padding-top: 10px;

    &.form--large {
      max-width: 960px;
    }

    @include media('>=desktop') {
      padding-top: 20px;
    }

    .btn-card {
      @include media('<desktop') {
        max-width: 190px;
        margin-top: 0px !important;
        min-width: inherit;
      }
    }

    .choice-image {
      max-width: 100%;
      position: relative;

      @include media('>=desktop') {
        max-width: 63%;
        left: 0;
      }
    }

    @include media('>=desktop') {
      border-bottom: 0;
      margin-bottom: 0;

      .choice-image {
        max-width: 100%;
      }
    }

    > div {
      margin-bottom: 20px;
      @include media('>=desktop') {
        margin-bottom: 30px;
      }
    }
  }

  button:not(.btn-back) {
    border: none;
    background: transparent;
    appearance: none;

    .btn-card {
      padding-right: 2.2em!important;
      text-align: left;
      @include font-ratio(16px);
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;
      left: -3%;
      @include media('>=desktop') {
        &:before {
          width: 2.2em;
        }
      }
    }
  }
}

.greetings-container {
  background-color: #F5F5F5;

  .step__body {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
  }

  .title--in-movement {
    margin-bottom: -5px;
    padding-top: 10px;

    h1 {
      padding: 10px 15px 20px 15px;
      @include font-ratio(30px, 0.7);
      font-family: "Roboto Condensed Bold", sans-serif;
    }
  }

  + .greetings-container {
    margin-top: 40px;
  }
}

.card__preview {
  position: relative;
  perspective: 700;
  max-width: calc(100% - 20px);
  margin: 30px auto 20px;

  &:before, &:after {
    content: "";
    position: absolute;
    z-index: -2;
  }

  &:before {
    left: 80px;
    bottom: 8px;
    width: 50%;
    height: 15%;
    max-width: 200px;
    box-shadow: -80px 5px 8px rgba(0, 0, 0, 0.2);
    transform: skew(50deg);
    transform-origin: 0 100%;
  }

  &:after {
    right: 80px;
    bottom: 8px;
    width: 50%;
    height: 15%;
    max-width: 200px;
    box-shadow: 80px 5px 8px rgba(0, 0, 0, 0.2);
    transform: skew(-50deg);
    transform-origin: 0 100%;
  }

  .card__preview__back {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(to right, #dbdbdb, lighten(#dbdbdb, 6%));
    bottom: 0;
    backface-visibility: visible;
    transition: all 0.5s ease-out;
    transform-origin: 0 0;
    transform: skewY(-5deg) scaleX(0.9);
    z-index: 2;
  }

  .card__preview__content {
    position: relative;
    z-index: 3;

    &:not(.no-customize) {
      @include media('>=desktop') {
        &:before {
          content: "";
          bottom: 100%;
          left: 10%;
          width: 28%;
          padding: 4%;
          background: transparent url(../images/card/bit.png) no-repeat top left;
          background-size: cover;
          position: absolute;
        }
      }
    }
  }
}

.text-or {
  display: none;
  margin: 10px auto 10px auto;
  text-align: center;

  @include media('>=desktop') {
    display: block;
  }

  span {
    display: inline-block;
    position: relative;
    color: #0082c3;
    font-weight: bold;
    font-size:18px;
    width: 30px;

    &:before,
    &:after {
      content: "";
      background: #c6c8cb;
      width: 130px;
      height: 1px;
      position: absolute;
      top: 50%;
      margin-top: -1px;
    }

    &:before {
      right: calc(100% + 20px);
    }

    &:after {
      left: calc(100% + 20px);
    }
  }
}

.card__preview__content {
  border: solid 1px #c6c8cb;
  background: #fff;
  padding: 6%;
  display: flex;
  width: 100%;
  @include media('>=desktop') {
    padding: 6%;
    background: #fff url(../images/card/icon-photo.svg) no-repeat center center;
  }
}

.card__preview__content__inner {
  border: solid 1px #c6c8cb;
  width: 100%;
  position: relative;
  padding-bottom: 70.04347826086957%;

  .box {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 230px;
    transform: translate(-50%, -50%);

    .btn-default, .btn-primary {
      white-space: nowrap;
    }

    .btn-default {
      margin-bottom: 10px;
    }
  }
}

.card__preview_background__slider {
  &.slick-slider {
    .slick-next,
    .slick-prev {
      &:before {
        content: "";
        font-family: inherit;
        font-size: inherit;
        width: 0;
        height: 0;
        border-style: solid;
      }
    }

    .slick-prev {
      left: -30px;

      &:before {
        border-width: 12.5px 7px 12.5px 0;
        border-color: transparent #d7d7d7 transparent transparent;
      }
    }

    .slick-next {
      right: -30px;

      &:before {
        border-width: 12.5px 0 12.5px 7px;
        border-color: transparent transparent transparent #d7d7d7;
      }
    }
  }

  .card__preview_background__item {
    padding-left: 3px;
    cursor: pointer;
    padding-right: 3px;

    img {
      display: block;
      border: 2px solid #e2e2e2;
      &:hover {
        border-color: darken(#e2e2e2, 3%);
      }
    }

    &--active {
      position: relative;

      img {
        border-color: $blue;
        &:hover {
          border-color: $blue;
        }
      }
    }
  }
}


.card__preview_background {
  margin-top: 20px;
  width: calc(100% - 20px);
  max-width: 480px;
  padding: 0px 40px 10px;
  margin: 20px auto 10px auto;
  position: relative;
  z-index: 4;

  @include media('>=desktop') {
    padding: 10px 40px 15px;
    border-radius: 5px;
    box-shadow: 0px 3px 7px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: -40px;
    background-color: #ffffff;
    margin-top: -0.5%;
    max-width: 370px;
  }

  .card__preview_background__title {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1.59;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 8px;
  }

  @include media('>=desktop') {
    margin-left: 30px;
  }
}

.cat-tabs {
  overflow: hidden;
  margin: 5px 0;

  label {
    cursor: pointer;
  }

  &__inner {
    margin: 0 -10px;
    display: flex;
    width: calc(100% + 20px);
  }

  &__item {
    transform: skewX(-5deg);
    flex: 1;
    text-align: center;
    box-shadow: 0 0 4.4px 0.7px rgba(0, 0, 0, 0.2);

    span {
      display: block;
      padding: 7px 10px;
      transform: skewX(5deg);
      text-transform: uppercase;
      font-family: "Roboto Condensed Bold", sans-serif;
    }
  }
}

.sport-select,
.event-select {
  display: none;
}

#choice-event:checked {
  background: red;

  ~ .cat-tabs {
    label[for="choice-event"] {
      background: $blue;
      color: #fff;
    }
  }

  ~ .event-select {
    display: block;
  }
}


#choice-sport:checked {
  background: red;

  ~ .cat-tabs {
    label[for="choice-sport"] {
      background: $blue;
      color: #fff;
    }
  }

  ~ .sport-select {
    display: block;
  }
}


.desktop-flexie {
  position: relative;
  z-index: 3;

  @include media('>=desktop') {
    z-index: 5;
  }

  .wrapper-function {
    padding: 0 15px;

    .btn-primary {
      width: 100%;
      display: flex;

      span {
        margin-left: auto;
        margin-right: auto;
      }

      &:before {
        content: "";
        display: inline-block;
        width: 15px;
        height: 20px;
        margin-left: 5px;
        background: transparent url(../images/steps/step3/download-icon.svg) no-repeat center center;
        background-size: cover;
        transform: inherit;
        position: relative;
        top: auto;
        left: auto;
      }
    }
  }

  .cat-tabs-container {
    margin-left: -20px;
    margin-right: -20px;
    padding: 5px 20px 0px 20px;
    min-height: 240px;
  }

  .grid-img {
    margin: 10px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: auto;
    @include media('>=desktop') {
      max-height: 190px;
    }
    @include media('<desktop') {
      height: 65vh;
      overflow-y: scroll;
    }

    &__item {
      margin: 2px 0;

      &--hidden {
        display: none;
      }

      img {
        display: block;
        border: 2px solid #fff;
      }

      &--active {
        position: relative;

        img {
          border-color: $blue;
        }
      }
    }

    .grid-img__item {
      width: calc(50% - 2.5px);
      cursor: pointer;

      img {
        width: 100%;
      }
    }
  }

  .grid-img__column {

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @include media('>=desktop') {
    margin-left: 30px;
    margin-right: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .grid-img {
      //max-width: 250px;
      margin: 10px auto;
      justify-content: space-around;

      .grid-img__item {
        width: 32%;
      }
    }

    .cat-tabs-container {
      margin-top: 0px;
    }

    .wrapper-function {
      border-radius: 5px;
      box-shadow: 0px 3px 7px 0 rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      padding: 10px 20px 15px;
      margin-top: 20px;
    }

    .component-desktop {
      width: 370px;
      margin-top: -80px;
    }

    .card__outer_wrapper {
      width: calc(98% - 370px);
      padding-top: 3%;

      .card__preview {
        max-width: 450px;
        margin-left: 15%;
      }
    }
  }
}

#step3 .desktop-flexie .card__outer_wrapper .card__preview {
  max-width: 430px;
  @include media('>=desktop') {
    margin-left: 22%;
  }
}

.title-gallery {
  color: $blue;
  font-size: 18px;
  text-align: center;
  font-family: "Roboto Condensed Bold", sans-serif;
}

.component-open {
  overflow: hidden;

  .wrapper {
    min-height: inherit;
  }

  .col-main {
    margin-bottom: 0 !important;
  }

  .block-footer,
  .reviews {
    display: none;
  }
}

#step1 {
  .title--in-movement {
    padding: 0;
  }

  input[type=text], input[type=number] {
    border: none;
  }

  @include media('<tablet') {
    input[type=number] {
      padding: 11px 17px 0 0;
    }
  }

}

#step1, #step2 {

  .title--in-movement {
    span {
      display: block;
    }
  }
}

.help-arrow {
  width: 150px;
  position: absolute;
  right: 8%;
  top: 12%;
  z-index: 10;
  @include media('<1200px'){
    width: 100px;
    top:15%;
  }
  @include media('<=1025px'){
    display: none;
  }
}

.help-icon {
  width: 95px;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 25px;
  z-index: 10;
  @include media('<=tablet'){
    width: 65px;
    right: 10px;
  }
  @include media('<480px'){
    width: 52px;
    right: 15px;
  }
}

#step3 {
  .title--in-movement {
    @include media('>=desktop') {
      padding-left: 425px;
      text-align: center;
      top: 20px;
      position: relative;

      h1 {
        text-align: center;
        display: inline-block;
        max-width: 70%;
      }
    }
  }

  .step__body {
    position: relative;
  }

  .card__outer_wrapper {
    max-width: 675px;
    margin-left: 10px;
    margin-right: 10px;
    @include media('>=tablet') {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .card-value {
    @include media('>=desktop') {
      margin-left: 400px;
      text-align: left;
      padding-left: 23%;
      margin-top: 50px;
      line-height: 1;
    }
  }
}

.img-notif {
  max-width: 350px;
  border-radius: 30px;
  background-color: #00e381;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  text-align: center;
  padding: 10px;
  margin: -20px 15px 0 15px;

  &.too-bad {
    background-color: #e34222;
  }

  @include media('>=desktop') {
    margin-left: 28%;
    margin-top: 0;
    margin-bottom: -40px;
  }
}

.smiley {
  position: relative;
  vertical-align: middle;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-radius: 20px;
  margin-right: 10px;

  &:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 50%;
    width: 7px;
    height: 2px;
    transform: translateX(-50%);
    border-left: 2px solid #fff;
    border-right: 2px solid #fff;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 2.5px;
    left: 50%;
    width: 10px;
    height: 4px;
    transform: translateX(-50%);
    border-bottom: 1.5px solid #fff;
    border-left: 1.5px solid #fff;
    border-right: 1.5px solid #fff;
    border-radius: 0 0 5px 5px;
  }

  &--sad {
    &:after {
      bottom: 5px;
      height: 2px;
      border-right: 0;
      border-left: 0;
      border-radius: 3px;
    }
  }
}

.card__preview__content__inner {
  .clear-button {
    width: 25px;
    height: 25px;
    background: #fff url(../images/steps/step5/x.svg) no-repeat center center;
    background-size: 15px 15px;
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
    border: 1px solid $blue;
    border-radius: 50%;
    display: none;
  }

  &[style*='background'] {
    border: none;

    .box {
      display: none;
    }

    .clear-button {
      display: block;
      @include media('>=desktop') {
        display: none;
      }
    }
  }
}

.card__preview__content.no-customize {
  background-image: linear-gradient(145deg, #F0EFED 0%, #EFEFEB 91%);
}

.card__outer_wrapper {
  .mentions {
    max-width: calc(100% - 20px);
    margin: 0px auto;
    @include media('>=desktop') {
      margin-left: 250px;
      margin-top: -15px;
    }
  }
}

.card__preview__content__inner {
  background-position: center center;
  background-repeat: no-repeat;

  &.no-customize {
    padding-bottom: 0;
    border: none;
    min-height: 197px;
    display: flex;
    width: 100%;
    justify-content: center;

    .box {
      position: relative;
      top: 0;
      left: 0;
      transform: inherit;
      color: $blue;
      font-family: "Open Sans Condensed", sans-serif;

      &[style*="Pacifico"] {
        label, input, textarea, .textarea {
          //font-size: (3.6vw * 0.85);
          @include media('>=560px') {
            //font-size: (18px * 0.6);
          }
        }
      }

      input[type=email], input[type=search], input[type=number], input[type=password], input[type=tel], input[type=text] {
        width: auto;
        flex-grow: 1;
        max-width: inherit;
        padding: 1vw 0;
        height: auto;
        text-indent: 5px;
        @include media('>=560px') {
          padding: 0;
        }
      }

      label, input, textarea, .textarea {
        @include font-ratio(14px, .85);
        font-family: inherit;
        padding: 1%;
      }

      .text__left {
        font-family: 'Arial', sans-serif;
        font-size: 2.5vw;
        display: block;
        text-align: right;
        color: #5c5c5c;
        margin-top: 1.5vw;
        @include media('>=560px') {
          font-size: 13px;
          margin-top: 10px;
        }
      }

      &__row {
        display: inline-flex;
        align-items: center;

        &:not(:first-child) {
          margin-top: 2vw;
        }

        &--full {
          margin-top: 2vw;
        }

        @include media('>=560px') {
          &:not(:first-child) {
            margin-top: 7px;
          }
          &--full {
            margin-top: 5px;

            label {
              padding-bottom: 5px;
            }
          }
        }
      }

      &__row, &__row--full {
        width: 100%;

        label {
          padding-right: 10px;
          white-space: nowrap;
        }

        input, textarea, .textarea {
          background: transparent;
          border: none;
          color: currentColor;
          font-family: inherit;
        }

        input {
          background: transparent;
          border: none;
          border-bottom: 1px solid #b5b7b9;
        }

        textarea, .textarea {
          background: #ECEBE8;
          border: 1px solid #ECEBE8;
          height: 30vw;
          width: 100%;
          max-width: inherit;
          resize: none;

          &:focus {
            &::placeholder {
              color: transparent;
            }
          }

          @include media('>=560px') {
            height: 145px;
          }
        }
      }
    }
  }
}

#step4 {
  .title--in-movement {
    span {
      display: block;
      margin-bottom: 5%;
    }
  }

  .step__body {
    position: relative;
  }

  .card__preview {
    .card__preview__back {
      z-index: 6;
      left: 100%;
      width: 100%;
      transform: skewY(-5deg) scaleX(0.9);

      .visible-desktop {
        height: 100%;

        .card__preview__back__item {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      @include media('>=desktop') {
        transform: perspective(50px) rotateY(-1deg) scaleX(0.75);
        box-shadow: 80px 50px 80px rgba(0, 0, 0, 0.3);
      }
    }
  }
}

body:not(.overlay--opened) {
  .overlayer {
    display: none;
  }
}

.overlay--opened {
  position: relative;
  &:before {
    content: "";
    background: #000;
    opacity: .8;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
  }

  .close-icon {
    width: 19px;
    height: 19px;
    z-index: 9999;
    background: transparent url(../images/steps/step5/x.svg) no-repeat center center;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  .alert,
  .contact-submit,
  .mentions {
    margin-left: 10px;
    margin-right: 10px;
  }

  #step3{
    .overlayer.form {
      padding: 2% 3% 2%;
    }
  }

  .overlayer.form {
    position: fixed;
    text-align: center;
    background: #fff;
    border-radius: 5px;
    top: 50%;
    left: 50%;
    z-index: 100;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 790px;
    padding: 0 3% 2%;
    .alert {
      width: auto;
      padding: 15px;
      font-size: 18px;
    }

    .btn-card {
      width: 100%;
      max-width: inherit;
      &:before {
        width: 2.2em;
      }
    }
  }

  .step__body {
    .step__pre-content {
      display: none;
      @include media('>=tablet') {
        display: block;
      }
    }
  }

  @include media('<tablet') {
    .overlayer .contact-submit {
      flex-direction: column;
      button:first-child {
        margin-bottom: 10px;
      }
    }
  }
}

.card-editor-wrapper {
  @include media('>=560px') {
    .card__preview {
      max-width: 450px;
    }
  }
  @include media('>=desktop') {

    .card__preview {
      max-width: 450px;
      margin-left: 250px;
    }
    //display: flex;
  }
}

.colorpicker {
  display: flex;
  margin: 10px auto;
  max-width: 280px;
  justify-content: space-around;


  @include media('>=desktop') {
    position: absolute;
    flex-direction: column;
    align-items: center;
    left: 3%;
    top: 0;
    padding: 20px 0;
    width: 91px * 0.95;
    height: 350px;
    border-radius: 5px;
    box-shadow: 0px 3px 7px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    top: 170px;

    &__item {
      margin-bottom: 5px;
    }
  }

  &__item {
    width: (28px * 0.85);
    height: (28px * 0.85);
    border-radius: 50%;
    cursor: pointer;

    &--active {
      border: 3px solid #fff;
      box-shadow: 0 0 1px $blue, 0 0 1px $blue, 0 0 1px $blue, 0 0 1px $blue;
    }
  }
}

.fontpicker {
  display: flex;
  margin: 20px auto;
  max-width: 280px;
  justify-content: space-around;
  font-size: 30px;
  color: $blue;
  @include media('>=desktop') {
    margin-top: 0;
  }

  &__item {
    cursor: pointer;

    &[data-font="'Chapaza Regular', serif"] {
      line-height: 1.8;
    }

    &--active {
      border-bottom: 3px solid $blue;
    }
  }
}

.card__view {
  position: relative;
  z-index: 8;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 40px 15px 15% 15px;
  @include media('>=desktop') {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 8%;
  }

  &__item {
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #e2e2e2;
    transition: background-image .25s ease-in;
    background-color: #fafafa;
    background-image: linear-gradient(to top, #e2e2e2, #fafafa);
    width: 32%;
    padding: 10px;
    text-transform: uppercase;
    font-family: "Roboto Condensed Bold", Arial, Helvetica, sans-serif;
    text-align: center;
    cursor: pointer;
    @include media('>=desktop') {
      background: none;
      box-shadow: inherit;
      border: none;
      width: auto;
    }

    &--active {
      background-color: #fff;
      background-image: linear-gradient(to top, #ffffff, #ffffff);
      @include media('>=desktop') {
        background: none;
        border-bottom: 2px solid #000;
        font-style: italic;
      }
    }
  }
}

.step5 {
  .title--in-movement h1 {
    span {
      display: block;
    }
  }
  @include media('<tablet') {
    .btn-back{
      width: 100%;
      margin-bottom: 10px;
    }

    .btn-next{
      width: 100%;
    }
  }
}


.overlayer {
  .contact-submit {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    .btn-card {
      min-width: 150px;
    }
  }

  .alert {
    border-radius: 28.5px;
    background-color: darken(#f5f5f5, 5%);
    width: 100%;
    text-align: center;
    color: $blue;
    padding: 10px;
    margin-bottom: 30px;

    &:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      margin-right: 7px;
      width: 22px;
      height: 19px;
      background: transparent url(../images/steps/step5/alert.png) no-repeat center center;
      background-size: cover;
    }
  }

  .flexie {
    > div {
      @include media('<tablet') {
        width: 100%;
      }
    }
  }

  &.form {
    margin: 0 10px;
    padding: 0;
  }

  .mentions {
    font-size: 12px;
    margin-bottom: 20px;
    text-align: right;
  }

  .delivery-label, .date-label{
    margin: 10px;
    font-size: 18px;
  }

  .arrow-more{
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 6px;
    border-top: 6px solid #F36F21;
    border-bottom: 6px solid transparent;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    margin-left: 75%;
  }

  .more-choice-beneficiary{
    width: calc(50% - 10px);
    margin: auto;
    display: block;

    #beneficiary_email{
      height: auto;
      padding: 10px 15px;
    }
  }

  .flexie.user-choice{
    justify-content: center;
  }

  .card__contact__field {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;
    position: relative;
    z-index: 8;

    label{
      flex: 1;
      border: 1px solid lightgrey;
      cursor: pointer;

      p{
        text-align: center;
        padding: 10px;
      }

      input[type="radio"]{
        display: none;
      }

    }

    label.active{
      border: 1px solid #0182c3;
      color: #0182c3;
    }
  }

  input[type=email], input[type=search], input[type=number], input[type=password], input[type=tel], input[type=text] {
    padding: 20px 15px;
    border: solid 1px #e2e2e2;
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.15);
    width: 100%;
    color: #000;
    @include media('>=tablet') {
      box-shadow: inherit;
    }

    &::placeholder {
      text-transform: uppercase;
      font-style: italic;
    }
  }

  .title--in-movement {
    margin-bottom: 20px;
  }
}


@include media('>=tablet', '<desktop') {
  .flexie {
    flex-direction: row;

    .flexie__item--1 {
      &.card__contact__field {
        width: calc((100% - 10px);
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    .flexie__item--2 {
      &.card__contact__field {
        width: calc((100% / 2) - 10px);
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
}

@keyframes lds-ripple {
  0% {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 18px;
    left: 18px;
    width: 156px;
    height: 156px;
    opacity: 0;
  }
}

#step5 {
  position: relative;
}

.ng-scope {
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;

  #step5 & {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    background-color: rgba(#F5F5F5, .7);

    span {
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(60px);
      font-size: 1.1em;
    }
  }
}

.lds-ripple {
  position: relative;
}

.lds-ripple div {
  box-sizing: content-box;
  position: absolute;
  border-width: 4px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(1) {
  border-color: #0082c3;
}

.lds-ripple div:nth-child(2) {
  border-color: #0082c3;
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.lds-ripple {
  width: 120px !important;
  height: 120px !important;
  transform: translate(-60px, -60px) scale(0.6) translate(60px, 60px);
}

.card-editor-wrapper {
  @include media('>=desktop') {
    margin-top: -30px;
  }
}

.card-overlay {
  position: fixed;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  top: 40%;
  z-index: 9999;
  display: none;

  .lds-ripple{
    display: block;
    margin: auto;
  }
}

body:not(.overlay--opened) {
  .card-overlay {
    display: none;
  }
}

.overlay--opened {
  position: relative;
  @include media('>tablet') {
    &:before {
      content: "";
      background: #000;
      opacity: .8;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      z-index: 30;
    }
  }
}

@include media('>=tablet') {
  .step3{
    .step__progress__inner{
      ol{
        margin-right: -300px;
      }
      @media screen and (min-width: 1024px) and (max-width: 1080px){
        ol{
          margin-right: -250px;
        }
      }
    }
  }
  .step4{
    .btn-back{
      top: 1.1%;
    }
    .step__progress__inner{
      ol{
        margin-right: -160px;
      }
    }
  }

  .step5{
    .step__progress__inner{
      ol{
        margin-right: -200px;
      }
    }
  }
}

@include media('<480px'){
  .step3{
    .title--in-movement{
      h1{
        max-width: 78%;
      }
    }
  }
}

#toggle-menu {
  + .menu {
    display: none;
  }
}

/* IE 1O+ Fix */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .step2{
    .flexie{

      button{
        max-width: 100%;
      }

      .flexie__item--2{
        .flexie__item--m50 {
          width: 50%;
        }
      }

      .choice-image{
        margin: auto;
      }
    }
  }
}
