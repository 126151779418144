#header {
  .header-minicart {
    background: $yellow;
    height: calc(100% + 20px);
    top: 0;
    padding-top: 10px;
    position: relative;

    .picto,
    .icon {
      z-index: 3;
    }

    @include media('>=tablet') {
      padding-top: 5px;
    }
    &:after {
      content: "";
      position: absolute;
      background: $yellow;
      height: 100%;
      width: 20px;
      top: 0;
      box-sizing: content-box;
      left: -10px;
      z-index: 0;
      transform: skewX(-7deg);
    }
  }

  .account-cart-wrapper {
    height: 100%;
  }
}

.checkout-cart-index {
  .main-container {
    background: #F5F5F5;
    @include media('>=600px') {
      background: transparent;
    }
  }

  .cart-totals-wrapper {
    @include media('>=600px') {
      .cart-totals {
        position: relative;
        margin-bottom: 150px;
      }
      .checkout-types.bottom {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
      }
    }
  }

  .cart .cart-totals table tfoot td {
    font-size: 18px;
  }

  #shopping-cart-totals-table {
    margin: 20px auto;
  }

  .cart {
    .btn-qty {
      mix-blend-mode: multiply;
    }
    .btn-card {
      font-size: 14px;
      padding: 15px 20px 15px 20px !important;
      &:before {
        @include media('>=600px') {
          width: 2.2em;
        }
      }
    }
    .btn-back {
      padding: 15px 20px 15px 20px !important;
    }
    .btn-back,
    .btn-card {
      max-width: inherit;
      width: 100%;
    }
    .cart-totals table {
      tfoot {
        border: none;
      }
      tbody {

        tr:last-child {
          td, th {
            padding-bottom: 0;
          }
        }
      }
    }

    .cart-forms .discount,
    .cart-forms .giftcard,
    .cart-forms .shipping,
    .cart-totals {
      background: transparent;
      border: 1px solid #e2e2e2;
      border-radius: 0;
    }

    .title--in-movement , .page-title.title-buttons {
      p {
        text-transform: uppercase;
        @media only screen and (max-width: 1025px) {
          font-size: 13px;
        }
      }
    }

    .title--in-movement {
      margin-top: 0;
      padding-top: 0;
      margin-bottom: 1em;
    }

    .page-title.title-buttons {
      border: none;
      padding-top: 20px;

      @media only screen and (min-width: 1025px) {
        display: block;
      }

      h1 {
        font-family: "Roboto Condensed Bold", sans-serif;
        font-style: italic;
      }
      + form {
        border-top: 1px solid #e2e2e2;
        padding-top: 10px;
        @include media('>=600px') {
          border: none
        }
      }
      .checkout-types.top {
        display: none;
      }
    }

    .cart-table {
      &.data-table {
        border-bottom: 0;
      }

      thead {
        th {
          background-color: #efefef;
          font-family: "Roboto Condensed Bold", sans-serif;
          font-style: italic;
          text-transform: uppercase;
          color: #898989;
        }
      }


      tbody {
        th, td {
          padding-top: 30px;
          padding-bottom: 30px;
        }

        .btn-remove {
          top: 3px;
        }
      }

      .product-name {
        @include font-ratio(18px);
        text-transform: uppercase;
        font-family: "Roboto Condensed Bold", sans-serif;
      }

      .product-cart-actions {
        .qty {
          margin-bottom: 0;
          @include media('>=600px') {
            margin-bottom: 5px;
          }
        }
        .wrapper-qty {
          height: auto;
        }
      }

      tr {
        border-width: 1px;
        border-color: #e2e2e2;
      }
    }

    .cart-totals {
      h2 {
        padding: 15px 10px 25px;
        font-size: 18px;
        font-family: "Roboto Condensed Bold", sans-serif;
        font-style: italic;
      }
      .checkout-types {
        li {
          margin-bottom: 0;
          margin-top: 10px;
        }
        .btn-checkout {
          margin-bottom: 0;
        }
      }
    }

    .product-cart-price {
      font-family: "Roboto Condensed Bold", sans-serif;
      font-size: 18px;
    }

    @include media('<600px') {
      .cart-table {

        .product-cart-info {
          padding-right: 115px;

          .product-cart-price {
            display: none;
          }
        }

        .product-cart-actions {
          position: absolute;
          bottom: 0;
          right: 0;
          margin-right: 0;
          width: auto;
        }

        .product-cart-remove {
          top: 0;
          transform: inherit;
        }
      }
      .product-cart-total {
          top: 0;
          padding-right: 30px;
          padding-top: 7px;
      }
    }
  }

  thead {
    @include media('<600px') {
      display: none !important;
    }

  }

  .messages {
    .success-msg {
      display: none;
    }
  }


  .shipping-info {
    position: relative;
    z-index: 8;
    .cart-totals h2 {
      margin-bottom: 0;
      text-align: left;
      position: relative;

      .help-icon {
        width: 24px;
        height: 24px;
        position: absolute;
        top: calc(50% - 5px);
        right: 40px;
        background: transparent url(../images/steps/step5/help.png) no-repeat center center;
        transform: translateY(-50%);
        display: block;

        .tooltip-text {
          position: absolute;
          bottom: calc(100% + 5px);
          background: rgba($blue, 0.85);
          color: #fff;
          font-size: 12px;
          padding: 3px 7px;
          max-width: 200px;
          text-transform: none;
          display: none;
        }

        &:hover {
          .tooltip-text {
            display: block;
          }
        }

        @include media('>=600px') {
          display: block;
        }
      }

      @include media('>=600px') {
        padding: 15px 40px 25px;
      }
    }
    tr {
      &:not(:first-child) {
        td {
          border-top: solid 1px #e2e2e2;
        }
      }
    }
    .shipping-mention {
      text-transform: initial;
    }
    .price,
    strong {
      font-family: "Roboto Condensed Bold", sans-serif;
    }
    @include media('>=600px') {
      float: left;
      width: calc(100% - 350px);
    }

    .cart-totals {
      padding-bottom: 0;
    }

    #shipping-totals-table {
      max-width: inherit;
      margin-left: -10px;
      margin-right: -10px;
      width: calc(100% + 20px);
      td {
        padding: 20px;
        @include media('>=600px') {
          padding: 30px 40px;
        }
        &:first-child {
          padding-right: 0;
        }
      }
    }
  }

  .reviews {
    margin-left: -20px;
    margin-right: -20px;
    &:before {
      @include media('>=600px') {
        display: none;
      }
    }
  }

  .cart {
    .cart-forms, .cart-totals-wrapper, .crosssell {
      @include media('<600px') {
        max-width: inherit;
      }
    }
  }
}

.checkout-onepage-index,
.checkout-onepage-success {
  .col-main {
    margin-top: 35px;
  }
}