html.noscroll {
  overflow: hidden;
  body {
    position: relative;
    overflow: hidden;
  }
}

.messages{
  z-index: 10;
  position: relative;
  padding-top: 10px;
}

.grecaptcha-badge{
  z-index: 2;
}

.wrapper {
  overflow-x: hidden;
  max-width: inherit;
  width: 100%;
  position: relative;
  transition: all .25s ease-in-out;
  background: #F5F5F5;
}