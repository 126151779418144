.icon-question-o-alt,
.icon-pin,
.icon-user,
.icon-v2,
.icon-cart {
  &:before {
    font-family: 'Decathlon Cube Extras' !important;
  }
}

// Une questions ?
.icon-question-o-alt:before {
  content: "\e904" !important;
}

// Mon magasin
.icon-pin:before {
  content: "\e901" !important;
}

// Mon compte
.icon-user:before {
  content: "\e903" !important;
}

.icon-cart:before {
  content: "\e902" !important;
}

.icon-time:before {
  content: "\e905";
}
.icon-talk:before {
  content: "\e906";
}
.icon-cart-alt:before {
  content: "\e907";
  color: #fff;
}
.icon-shop:before {
  content: "\e901";
}

.icon-pin-alt:before {
  content: "\e900";
}
