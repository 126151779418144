.data-table{
  .product-cart-info {
    .item-options {
      margin-left: 0;
      padding-left: 0;
      dd{
        padding-left: 0;
      }
    }
  }
}

#checkout-review-table-wrapper{
  .data-table tbody td .item-options {
    margin-left: 0;

    dt:after {
      content: none;
    }

    dd {
      padding-left: 0;
    }
  }
}

.bottom-checkout-review {
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 905px) {
    flex-direction: column;
  }

  .additional-text-checkout {
    width: 70%;

    @media only screen and (max-width: 905px) {
      width: 100%;
    }
  }

  #review-buttons-container {
    width: 23%;
    height: 48px;
  }
}