@import 'vars';
@import 'helpers/include-media';
@import 'helpers/fluid-type';
@import 'base/typography';
@import 'base/icons';
@import 'base/mobile';
@import 'base/form';
@import 'helpers/visibility';
@import 'helpers/grid';
@import 'helpers/toggle';

@import 'layout/base';
@import 'layout/header';
@import 'layout/footer';
@import 'components/slick';
@import 'components/slick-theme';
@import 'components/hamburger';
@import 'components/navigation';
@import 'components/cart';
@import 'components/reviews';
@import 'components/card';
@import 'components/step';
@import 'components/flip_card';
@import 'pages/myaccount';
@import 'pages/solde';
@import 'pages/checkout';

#cookiealert {
  display: none;
}

.row {
  //width: 500px;
  margin: 10px 0;

  input[type=email], input[type=search], input[type=number], input[type=password], input[type=tel], input[type=text] {
    width: auto;
  }
}

fieldset {
  border-bottom: 1px solid #aaa;
  //width: 350px;
  padding: 20px;
}

.row label {
  //width: 100px;
}

.row input, .row select {
  height: 40px;
  // width: 380px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #aaa;
  color: #636363;
}
