.form {
  padding: 40px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;

  &--large {
    max-width: 1180px;
  }
}

.form, .cart {
  &--collapsed-input {
    max-width: 580px;

    input[type=email], input[type=search], input[type=number], input[type=password], input[type=tel], input[type=text] {
      padding: 17px;
      margin-bottom: 10px;
      @include font-ratio(18px);
    }


    .input-number {
      border: 1px solid rgb(238, 238, 238);
      display: flex;
      margin-bottom: 0;
      background: #fff;
      align-items: center;
      padding-right: 22px;
      min-width: 50%;
      @-moz-document url-prefix() {
        padding-right: 50px;
        min-width: 55%;
      }

      @include media('>=desktop') {
        margin-right: 10px;
      }

      span {
        @include font-ratio(22px);
      }

      input[type=number] {
        text-align: center;
        border: none;
        @include font-ratio(22px);
        flex-grow: 1;
        margin: auto;
        margin-right: 0;
        height: auto;
      }
    }

    input[type=email], input[type=search], input[type=number], input[type=password], input[type=tel], input[type=text],
    .btn-card {
      width: 100%;
      max-width: inherit;

      &:after {
        right: -17% !important;
      }
    }

    @include media('>=desktop') {
      display: flex;
      max-width: 640px;
      input[type=email], input[type=search], input[type=number], input[type=password], input[type=tel], input[type=text] {
        margin-bottom: 0;
        padding: 15px;
        height: auto;
        margin-right: 10px;
      }
    }

    + .error {
      position: relative;
      top: -20px;
      text-align: center;
    }
  }


  .btn-card {
    background: #ffea28;
    font-family: "Roboto Condensed Bold", sans-serif;
    @include font-ratio(17px);

    @include media('>=desktop') {
      padding: 17px 20px !important;
      &:before {
        width: 3.2em;
      }
    }

    &:after {
      content: "";
      background-color: #fdd835;
      position: absolute;
      height: 150%;
      width: 150%;
      transition: -webkit-transform .18s ease;
      transition: transform .18s ease;
      transition: transform .18s ease, -webkit-transform .18s ease;
      -webkit-transform: rotate(-10deg);
      transform: rotate(-10deg);
      top: 100%;
      left: -25%;
      z-index: -1;
      -webkit-transform-origin: calc(83.33333% - 2.85714em) 0;
      transform-origin: calc(83.33333% - 2.85714em) 0;
    }
  }
}

.greetings_hu {
  .btn-card {
    @include font-ratio(15px);
  }
}

.btn-back {
  font-family: "Roboto Condensed Bold", Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.28571429;
  border-radius: 2px;
  text-transform: uppercase;
  color: #333;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: background-color .45s ease .09s;
  z-index: 1;
  border: 0 none;
  width: auto;
  height: auto;
  text-decoration: none;
  display: block;
  /* left: 50%; */
  padding: 15px 20px 15px 60px;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px #e2e2e2;
  //background-image: linear-gradient(to top, #e2e2e2, #fafafa);
  background: #e2e2e2;

  &:before {
    content: "\E006";
    font-family: "Decathlon Cube";
    left: 0;
    top: 0;
    height: 100%;
    width: 2.2em;
    font-size: 1em;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    line-height: 1;
    z-index: 1;
  }

  &:after {
    content: "";
    background-color: darken(#e2e2e2, 4%);
    position: absolute;
    height: 150%;
    width: 150%;
    transition: -webkit-transform .18s ease;
    transition: transform .18s ease, -webkit-transform .18s ease;
    transform: rotate(10deg);
    top: 100%;
    left: -50%;
    z-index: -1;
    transform-origin: calc(83.33333% - 4.85714em) 0;
  }

  @include media('>=tablet') {
    padding: 8px 20px 8px 40px;
    background: #fff;
    box-shadow: inherit;
    &:after {
      background-color: lighten(#e2e2e2, 7%);
      transform-origin: calc(90.33333% - 4.85714em) 0;
    }
  }

  &:hover {
    &:after {
      transform: rotate(0) translateY(-100%);
      transition: transform .36s ease;
    }
  }
}

body .button-arrow {
  font-family: "Roboto Condensed Bold", Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.28571429;
  border-radius: 2px;
  text-transform: uppercase;
  color: #333;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: background-color .45s ease .09s;
  z-index: 1;
  border: 0 none;
  width: auto;
  height: auto;
  font-weight: bold;
  text-decoration: none;
  /* left: 50%; */
  padding: 15px 60px 15px 20px;
  background-image: linear-gradient(to bottom, #fef63a, #eec423);

  &:before {
    content: "\E007";
    font-family: "Decathlon Cube";
    right: 0;
    top: 0;
    height: 100%;
    width: 2.2em;
    font-size: 1em;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    line-height: 1;
    z-index: 1;
  }

  &:after {
    content: '';
    background-color: rgba(255, 255, 255, 0.3);
    position: absolute;
    height: 75px;
    width: 170px;
    transition: -webkit-transform .18s ease;
    transition: transform .18s ease;
    z-index: -1;
    transform-origin: 0% 100%;
    transform: rotate(-75deg);
    left: calc(100% + 25px);
    top: 0;
  }
}

.btn-next {
  font-family: "Roboto Condensed Bold", Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.28571429;
  border-radius: 2px;
  text-transform: uppercase;
  color: #333;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: background-color .45s ease .09s;
  z-index: 1;
  border: 0 none;
  width: auto;
  height: auto;
  font-weight: bold;
  text-decoration: none;
  display: block;
  /* left: 50%; */
  padding: 15px 60px 15px 20px;
  background: #ffea28;

  &:before {
    content: "\E007";
    font-family: "Decathlon Cube";
    right: 0;
    top: 0;
    height: 100%;
    width: 2.2em;
    font-size: 1em;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    line-height: 1;
    z-index: 1;
  }

  &:after {
    content: "";
    background-color: #fdd835;
    position: absolute;
    height: 150%;
    width: 150%;
    transition: -webkit-transform .18s ease;
    transition: transform .18s ease;
    transform: rotate(-10deg);
    top: 100%;
    left: -25%;
    z-index: -1;
    -webkit-transform-origin: calc(83.33333% - 2.85714em) 0;
    transform-origin: calc(83.33333% - 2.85714em) 0;
  }

  &:hover {
    &:after {
      transform: rotate(0deg) translateY(-100%);
      transition: -webkit-transform .36s ease;
      transition: transform .36s ease;
    }
  }
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.btn-default {
  padding: 20px;
  box-shadow: 0px 2px 4px 0 rgba(255, 255, 255, 0.15);
  background-image: linear-gradient(to top, #d2d2d2, darken(#ffffff, 3%));
  width: 100%;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  @include font-ratio(17px, 0.8);
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  cursor: pointer;
  text-align: center;

  @include media('>=desktop') {
    padding: 13px 10px;
  }
}

.btn-secondary {
  cursor: pointer;
  padding: 20px;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.15);
  background-image: linear-gradient(to bottom, #fdf439, #efc724);
  width: 100%;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  @include font-ratio(17px, 0.8);
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  text-align: center;

  @include media('>=desktop') {
    padding: 13px 10px;
  }
}

.btn-primary {
  cursor: pointer;
  padding: 20px 10px;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.15);
  background-image: linear-gradient(to top, #0082c3, #0082c3);
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  @include font-ratio(17px, 0.8);
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  text-align: center;
  z-index: 1;
  position: relative;
  overflow: hidden;

  @include media('>=desktop') {
    padding: 13px 10px;
  }

  &:after {
    content: "";
    background-color: #0074af;
    position: absolute;
    height: 150%;
    width: 150%;
    transition: -webkit-transform .18s ease;
    transition: transform .18s ease;
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
    top: 100%;
    left: -25%;
    z-index: -1;
    transform-origin: calc(83.33333% - 2.85714em) 0;
  }

  &:hover {
    &:after {
      transform: rotate(0deg) translateY(-100%);
      transition: transform .36s ease;
    }
  }
}

.select2-container {
  .select2-search--dropdown,
  .select2-results {
    padding: 5px;
  }

  .select2-search--dropdown {
    padding-bottom: 0;
  }

  .select2-results__option {
    padding: 10px;

    &:not(:first-child) {
      border-top: 1px solid #e4e4e4;
    }
  }

}

.jolie-select {
  position: relative;

  .select2-container--default .select2-selection--single .select2-selection__clear {
    osition: relative;
    z-index: 5;
    right: 25px;
    font-size: 20px;
    font-weight: normal;
  }

  .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background: $blue;
  }

  .select2-container--default {
    width: 100% !important;

    .select2-selection--single {
      background-image: linear-gradient(to top, #e2e2e2, #fafafa);
      background-position: 97% center;
      background-repeat: no-repeat;
      border: 1px solid #e2e2e2;
      color: #555;
      font-size: inherit;
      font-family: "Roboto Condensed Bold", sans-serif;
      text-transform: uppercase;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      border-radius: 0;

      .select2-selection__placeholder {
        color: #000;
      }

      .select2-selection__arrow {
        b {
          border: none;
          margin-top: -11px;
          margin-left: -11px;

          &:before {
            content: "\E009";
            font-family: "Decathlon Cube";
            height: 100%;
            width: 2.2em;
            font-size: 1em;
            font-weight: 400;
            line-height: 1;
            z-index: 1;
          }
        }
      }

      .select2-selection__rendered {

        &:after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          width: 50px;
          transition: transform .18s ease;
          z-index: -1;
          top: 0px;
          z-index: 2;
          right: -10px;
          transform: skewX(-20deg);
          background: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }
}

.btn-cancel {
  position: fixed;
  cursor: pointer;
  bottom: 10px;
  left: 15px;
  right: 15px;
  padding: 10px;
  display: block;
  box-sizing: border-box;
  width: calc(100% - 30px);
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px #e2e2e2;
  background-image: linear-gradient(to top, #e2e2e2, #fafafa);
  text-align: center;
  font-family: "Roboto Condensed Bold", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: normal;
  color: #242323;
}

#login-form, .balance-form {
  .btn-card {
    margin: auto;
  }
}

#review-buttons-container {
  .btn-card {
    padding: 15px 15px !important;
  }
}

@media only screen and (max-width: 599px) {
  .checkout-onepage-index {
    .btn-card {
      order: 1;
      max-width: none;
      padding: 15px 10px 15px 10px !important;
    }

    .btn-back {
      order: 2;
    }
  }
}
