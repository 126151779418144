[class*=dkti-] {
  font-family: DecathlonIconfont;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  letter-spacing: 0;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  font-smoothing: subpixel-antialiased;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  zoom: 1;
}

.dkti-home-delivery:before {
  content: "\E00F";
}

.dkti-secured-payment:before {
  content: "\E03D";
}

.dkti-exchange-return:before {
  content: "\E050";
}

aside {
  display: block;
}

a {
  background-color: transparent;

  &:active, &:hover {
    outline: 0;
  }
}

img {
  border: 0;
}


.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.col-sm-2, .col-sm-3, .col-sm-6, .col-sm-4 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.col-xs-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
  width: 100%;
}

@media (min-width: 801px) {
  .col-sm-2, .col-sm-3 {
    float: left;
  }
  .col-sm-6 {
    float: left;
    width: 50%;
  }
  .col-sm-4 {
    float: left;
    width: 35%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    float: left;
  }
  .col-sm-offset-1 {
    margin-left: 3.33333333%;
  }
  .col-last-item{
    margin-left: 13%;
  }
}

.container-fluid {
  &:after, &:before {
    content: " ";
    display: table;
  }
}

.row {
  &:after, &:before {
    content: " ";
    display: table;
  }
}

.container-fluid:after, .row:after {
  clear: both;
}

.cta-v2 {
  font-family: "Roboto Condensed Bold", Arial, Helvetica, sans-serif;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  color: #323233;
  border-radius: 2px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #ffea28;
  position: relative;
  overflow: hidden;
  transition: background-color .45s ease .09s;
  z-index: 1;
  cursor: pointer;
  font-size: (1.4rem * .625);
  line-height: 1.28571429;
  padding: .78571429em 1.85714286em;

  &:hover {
    text-decoration: none;
  }
}

a.cta-v2 {
  text-decoration: none;
}

.cta-v2 {
  &:after {
    content: "";
    position: absolute;
    background-color: #fdd935;
    height: 150%;
    width: 150%;
    transition: transform .18s ease;
    transform: rotate(-10deg);
    top: 100%;
    left: -25%;
    z-index: -1;
  }

  &:hover:not(.cta-v2--disabled):not([disabled]) {
    background-color: #fdd935;
    transition: background-color .18s ease .09s;
    color: #323233;

    &:after {
      transform: rotate(0deg) translateY(-100%);
      transition: transform .36s ease;
    }
  }

  &:focus {
    outline: 1px dotted #b1b1b3;
    outline-offset: 1px;
    color: #323233;
  }
}

.cta-v2--alt {
  background-color: $blue;
  padding: 15px 20px;
  color: #fff;
  font-family: Roboto-Condensed, Roboto, Arial, sans-serif;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
  text-align: left;
  color: #ffffff;
  border-radius: 0;

  &:after, &:hover:not(.cta-v2--disabled):not([disabled]) {
    //background-color: rgba(#005885, 0.3);
    background-color: $blue;
    color: #fff;
  }

  &:after {
    content: "";
    background-color: #0074af;
    position: absolute;
    height: 150%;
    width: 150%;
    transition: -webkit-transform .18s ease;
    transition: transform .18s ease;
    transition: transform .18s ease, -webkit-transform .18s ease;
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
    top: 100%;
    left: -25%;
    z-index: -1;
    transform-origin: calc(83.33333% - 2.85714em) 0;
  }

  &:focus {
    color: #fff;
  }

  &:hover:not(.cta-v2--disabled):not([disabled]):after {
    transform: rotate(0deg) translateY(-100%);
  }

  &:hover {
    &:after {
      transform: rotate(0deg) translateY(-100%);
      transition: -webkit-transform .36s ease;
      transition: transform .36s ease;
    }
  }
}

.cta-v2 {
  &[data-icon]:before {
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    line-height: 1;
    z-index: 1;
    content: attr(data-icon);
  }

  &:after {
    transform-origin: calc(83.33333333333334% - 2.857142857142857em) 0;
  }

  &[data-icon] {
    padding-right: 4em;

    &:before {
      width: 2em;
      font-size: (1.42857143em * .625);
    }
  }

  &[data-icon=">"]:before {
    width: 2.85714286em;
    font-family: "Decathlon Cube";
    font-size: 100%;
    content: "\E007";
  }
}

@media print {
  *, :after, :before {
    background: transparent !important;
    color: #323233 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a {
    text-decoration: underline;

    &:visited {
      text-decoration: underline;
    }

    &[href^="#"]:after {
      content: "";
    }
  }
  blockquote {
    border: 1px solid #afb1b3;
    page-break-inside: avoid;
  }
  img {
    page-break-inside: avoid;
  }
  h3, p {
    orphans: 3;
    widows: 3;
  }
  h3 {
    page-break-after: avoid;
  }
}

@media (pointer: coarse) and (hover: none) {
  * {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
  }
  :focus {
    outline: none !important;
  }
}

h3 {
  font-weight: 400;
  margin: 0;
}

a {
  color: #575859;

  &:focus, &:hover {
    color: #575859;
  }
}

li {
  list-style: none;
}

*, :after, :before {
  box-sizing: border-box;
}

.icon-star {
  color: #ffb339;
  font-size: 12px;
  display: inline-block;
  width: 12px;
  vertical-align: middle;
  margin-right: 2px;
  margin: 0;

  &:last-child {
    margin-right: 0;
  }

  &.star-half {
    color: #d4d7d9;
    position: relative;

    &:after {
      content: "\E04B";
      position: absolute;
      left: 0;
      width: 6px;
      overflow: hidden;
      color: #ffb339;
      top: 0;
    }
  }
}

@media print {
  .block-footer {
    display: none !important;
  }
}

.navigation--closed {
  margin-left: 0;
  animation-duration: .2s;
}

@media only screen and (max-width: 479px) {
  .navigation--closed {
    animation-name: j;
  }
}

@media only screen and (min-width: 480px) {
  .navigation--closed {
    animation-name: o;
  }
}

@media only screen and (min-width: 801px) {
  .navigation--closed {
    animation-name: o;
  }
}

.global-reviews {
  display: flex;
  flex-wrap: wrap;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;
  padding: 20px 28px 30px;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  background-color: #fff;

  > * {
    width: 50%;
  }
}

global-reviews__actions {
  margin-left: auto;
  margin-right: auto;
}

.global-reviews__infos-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}

.global-reviews__rating {
  margin-left: .7em;
  display: flex;
  font-size: (2.5rem * .625);
  font-weight: 700;
  color: #575859;
}

.global-reviews__rating__rating-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.global-reviews__rating span {
  @include font-ratio(40px);
  font-family: "Roboto Condensed Bold", Arial, sans-serif;
}

.global-reviews__stars {
  display: flex;

  .icon-star {
    width: 16px;
    margin-right: 4%;
    font-size: (1.6rem * .625);
  }
}

.global-reviews__title {
  font-style: italic;
  @include font-ratio(16px, .8);
  line-height: 1.2;
  color: #575859;
  padding-top: 10px;
  font-family: "Roboto Condensed", Arial, sans-serif;

  em {
    font-family: "Roboto Condensed Bold", Arial, sans-serif;
    display: inline-block;
    width: 100%;
    margin-top: 6px;
    margin-bottom: 6px;
    text-transform: uppercase;
    @include font-ratio(22px, .8);
    font-weight: 700;
  }
}

.global-reviews__rating, .global-reviews__stars {
  align-items: baseline;
}

.global-reviews__author, .global-reviews__sample-review {
  display: none;
}

.global-reviews__actions {
  display: flex;
  flex-direction: column;
  max-width: (25rem * .625);
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;

  @include media('>=tablet') {
    width: 60%;
  }

  @media (min-width: 1080px) {
    margin-left: 22%;
    margin-right: auto;
  }

  .cta-v2 {
    display: block;

    &:first-child {
      margin-bottom: .6em;
    }
  }
}

@media (min-width: 1080px) {
  .global-reviews {
    position: relative;
    padding-top: 57px;
    padding-bottom: 57px;
    max-width: 1180px;
    margin: 0 auto;
    border-bottom: 1px solid #edeff1;
    background-color: #edeff1;
  }
  .global-reviews__rating {
    justify-content: center;
    margin-left: 0;
    margin-bottom: .3em;
    line-height: 1;
    width: 100%;
  }

  .global-reviews__rating__rating {
    display: flex;
    flex-wrap: wrap;
  }

  .global-reviews__infos-wrapper {
    flex-direction: inherit;
    width: 50%;
  }
  .global-reviews__title {
    padding-left: 20px;
    padding-right: 1%;
    margin-bottom: 0;
    text-align: left;
    padding-top: 0;
    max-width: 300px;

    em {
      display: block;
      margin-top: 0;
      font-size: (2rem * .625);
      line-height: 1;
    }
  }

  .global-reviews_quote {
    display: flex;
    flex-wrap: wrap;

    .global-reviews__actions {
      width: 100%;
    }
  }

  .global-reviews__author {
    display: flex;
    flex-direction: column;
    width: 22%;
    padding-right: 8%;
    text-transform: uppercase;
    text-align: left;

    div {
      font-size: (1.4rem * .625);
      font-weight: 700;
    }

    span {
      display: block;
      width: 100%;
      font-size: (1.2rem * .625);
      font-style: italic;

      &:last-child {
        color: #7d7e80;
      }
    }
  }
  .global-reviews__sample-review {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 24px;
    border-radius: 12px;
    background-color: #fff;
    font-size: (1.3rem * .625);
    width: 78%;
    border: 0;

    &:before {
      content: "";
      position: absolute;
      top: 15px;
      left: -6px;
      display: block;
      width: 16px;
      height: 16px;
      transform: skewX(40deg);
      background-color: #fff;
    }

    p {
      text-align: left;
      font-style: italic;
      color: #575859;
    }
  }
  .global-reviews__actions {
    width: 16%;
    margin-top: 19px;
  }
}

.container-services {
  background: #0082c3;
  position: relative;
  border-top: 6px solid #ea6a24;

  &:before {
    content: "";
    position: absolute;
    top: 0px;
    z-index: 2;
    left: 0;
    right: 0;
    height: 1px;
    background: #fff;
  }

  .inner-services {
    color: #fff;
    margin-bottom: 0;
    padding: 0;
    position: relative;

    .slick-track {
      margin: 0 !important;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 17px 0px 17px 30px;

      &:hover {
        text-decoration: none;
      }

      @include media('>=tablet') {
        padding: 30px 0px 30px 30px;
      }
    }

    @media (min-width: 1023px) {
      .slick-track {
        width: 100% !important;

        .slick-slide {
          width: 25% !important;
        }
      }
    }

    .slick-slide {
      @include media('>=1023px') {
        //width: 25%;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: relative;
        &:not(:first-child):before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          height: 105px;
          left: 10px;
          width: 3px;
          background: darken(#0082c3, 5%);
          transform: skewX(-5deg);
        }
      }
    }

    a {
      color: #fff;
    }

    .icon-v2 {
      display: inline-block;
      font-size: 30px;
      height: 35px;
      width: 35px;
      vertical-align: middle;
      position: relative;
      margin-right: 10px;

      &.icon-desktop {
        width: 47px;
        font-size: 47px;

        &:before {
          position: absolute;
          top: -13px;
          left: 0;
        }
      }
    }

    .text {
      display: inline-block;
      font-family: "Roboto Condensed Bold", Arial, Helvetica, sans-serif;
      font-weight: 700;
      font-style: normal;
      font-style: italic;
      @include font-ratio(15px);
      padding-left: 10px;
      max-width: 200px;
      text-align: left;
      text-transform: uppercase;
      vertical-align: middle;
    }
  }
}

.services-shadow {
  display: none;
}

@media (max-width: 1079px) {
  .container-services .slick-services .text {
    max-width: 125px;
  }
}

@media (max-width: 800px) {
  .container-services .slick-services .text {
    display: inline-block;
    max-width: none;
  }
}

.social-icons {
  a {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.block-footer {
  position: relative;
  overflow: hidden;
  width: 100%;
  background: #edeff1;
  font-size: 14px;

  .accordion, .container-footer-mentions {
    a {
      color: #575859;
    }
  }

  h3 {
    color: #0082c3;
    text-transform: uppercase;
    font-family: "Roboto Condensed Bold", Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: (1.4rem * .625);
  }

  .container-footer-links {
    background: #edeff1;
  }

  .slick-footer-links {
    border-bottom: 1px solid #edeff1;
    padding: 1em 20px 10px;
    margin-bottom: 0;
    max-width: 1180px;

    h3 {
      margin-right: 5px;
      margin-bottom: 15px;
    }

    a {
      display: block;
      font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
      font-weight: 400;
      font-style: normal;
    }
  }

  .container-footer-newsletter-social {
    background: #edeff1;
    margin-bottom: 10px;

    .container-fluid {
      max-width: 1180px;
      border-bottom: 1px solid #edeff1;
      padding: 20px;
    }

    a {
      &:hover {
        text-decoration: none;
      }
    }
  }

  .container-footer-mentions {
    max-width: 1180px;
    text-align: center;
    padding: 1em 20px 3em;
    position: relative;

    .mentions-links {
      padding: 0;

      li {
        display: inline-block;
        font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: (1.1rem * .625);

        &:after {
          content: " - ";
        }

        &:last-child:after {
          content: "";
        }
      }
    }
  }
}

@media (min-width: 801px) {
  .block-footer .slick-footer-links {
    display: block !important;
    position: relative !important;
    width: auto !important;
    z-index: auto !important;
  }
}

.accordion-section-title {
  display: none;
}

@media (max-width: 800px) {
  .block-footer {
    .container-footer-newsletter-social .row {
      display: flex;
      flex-direction: column;
      align-items: center;

      .table-cell-display {
        display: flex;
        width: 290px;
        justify-content: space-around;
      }

      > * {
        float: none;
        width: auto;
        text-align: center;

        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }

    .slick-footer-links {
      text-align: center;

      h3 {
        text-transform: none;
      }
    }

    .container-footer-newsletter-social .container-fluid {
      padding: 10px;
    }

    .container-footer-mentions {
      max-width: 1180px;
      text-align: center;

      .mentions-links li:after {
        content: "";
      }
    }

    .accordion-section-content {
      min-height: 199px;
      padding-bottom: 20px;
    }

    .accordion-section-title {
      display: block;
      color: #0082c3;
      text-transform: uppercase;
      font-family: "Roboto Condensed Bold", Arial, Helvetica, sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: (1.4rem * .625);
      text-align: center;
      border-bottom: 1px solid #edeff1;
      padding: 10px 0;
      position: relative;

      .image-close {
        display: inline-block;
        position: absolute;
        right: 15px;
        color: #323233;

        &:before {
          font-family: "Decathlon Cube";
          content: "\E00D";
          font-size: 10px;
        }
      }
    }
  }
}

.greetings-seo {
  display: block;
  margin: auto;
  max-width: 1140px;
  padding: 1em 20px 10px;
  text-align: justify
}

@media (min-width: 801px) and (max-width: 1080px) {
  .block-footer .container-footer-mentions {
    text-align: right;
  }
}

/* Icons */
.block-footer {
  .icon-facebook2:before {
    content: "\E034";
    font-size: 40px;
    color: #444444;
  }

  .icon-youtube2:before {
    content: "\E036";
    font-size: 40px;
    color: #444444;
  }

  .icon-twitter2:before {
    content: "\E035";
    font-size: 40px;
    color: #444444;
  }

  .social_networks li{
    display: inline-block;
    vertical-align: top;
  }
}

/*! CSS Used from: Embedded */

.container-services .slick-services .text {
  font-style: italic !important;
  font-weight: 500 !important;
  font-family: Roboto, Arial, Helvetica, sans-serif !important;
}


.homepage .block-footer {
  margin-top: (8rem * .625);
}

/*! CSS Used keyframes */

@keyframes j {
  0% {
    margin-left: 80vw;
  }

  to {
    margin-left: 0;
  }
}


@keyframes o {
  0% {
    margin-left: 400px;
  }

  to {
    margin-left: 0;
  }
}