.card-tips {
  display: flex;
  padding: 10px;

  @media only screen and (max-width: 479px) {
    flex-direction: column;
  }

  .btn-solde-left a{
    margin-left: -65px;
    @media only screen and (max-width: 1126px) {
      margin-left: 0;
    }
    @media only screen and (max-width: 479px) {
      width: 100%;
    }
  }

  .btn-solde-right{
    margin-left: auto;

    @media only screen and (max-width: 479px) {
      width: 100%;
    }

    a{
      margin-right: -65px;
      @media only screen and (max-width: 1126px) {
        margin-right: 0;
      }
      @media only screen and (max-width: 479px) {
        width: 100%;
        margin-top: 5px;
      }
    }
  }
}