.reviews {
  background: #fff;
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 40px;
  @include media('<desktop') {
    position: relative;
    padding-top: calc(40px + 10%);

    &:before {
      content: "";
      top: 0;
      transform: skewY(-5deg) translateY(-50%);
      left: 0;
      right: 0;
      background: #F5F5F5;
      height: 7%;
      min-height: 32px;
      position: absolute;
    }
  }

  &__header {
    padding-bottom: 40px;

    &--title {
      margin-top: 0;
      font-family: "Roboto Condensed Bold", sans-serif;
      @include font-ratio(36px);
      font-weight: bold;
      font-style: italic;
      font-stretch: condensed;
      line-height: 1.14;
      letter-spacing: -0.4px;
      text-align: center;
      color: #ea6a24;
      @include media('>=tablet') {
        span {
          position: relative;

          &:before,
          &:after {
            content: "";
            background: #ea6a24;
            width: 70px;
            height: 2px;
            position: absolute;
            top: 50%;
            margin-top: -1px;
          }

          &:before {
            right: calc(100% + 20px);
          }

          &:after {
            left: calc(100% + 20px);
          }
        }
      }

    }
  }

  &__list {
    counter-reset: reviewCounter;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    //max-width: 1024px;

    &.flexie {
      @include media('>=phone', '<desktop') {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: flex-start;
        .flexie__item--4.flexie__item--m25 {
          width: calc((100% / 2) - 20px);
          min-width: inherit;
          margin-left: 10px;
          margin-right: 10px;
          flex: inherit;
          max-width: inherit;
        }
      }
    }

    &__highlights {
      margin-bottom: 50px;
      display: none;

      @include media('>=desktop') {
        display: flex;
        justify-content: space-between;
        .flexie {
          align-items: initial;
        }

        .reviews__list__highlights--title {
          width: 320px;

          .title__inner {
            span {
              display: block;
            }
          }
        }

        .card {
          display: flex;
          width: calc(100% - 370px);
        }
      }

      .reviews__list__highlights--title {
        margin-top: 60px;
        max-width: 320px;
        margin-left: auto;
        margin-right: auto;

        .cta-v2 {
          display: block;
          margin-top: -15px;
          margin-bottom: 10px;
          max-width: 230px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    &--item {
      max-width: 245px;
      min-width: 205px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 40px;

      &:before {
        counter-increment: reviewCounter;
        content: counter(reviewCounter) ".";
        font-family: "Roboto Condensed Bold", sans-serif;
        @include font-ratio(50px);
        font-weight: bold;
        font-style: italic;
        font-stretch: condensed;
        line-height: 0.46;
        letter-spacing: -2.8px;
        text-align: center;
        color: #ea6a24;
        margin-right: 10px;
      }
    }

    &--icon {
      height: 40px;
    }

    &--desc {
      font-family: "Roboto Condensed", sans-serif;
      @include font-ratio(15px);
      font-weight: normal;
      font-style: italic;
      font-stretch: condensed;
      line-height: 1.37;
      letter-spacing: normal;
      text-align: left;
      color: #7b7b7b;
    }

    &--title {
      font-family: "Roboto Condensed Bold", sans-serif;
      @include font-ratio(18px);
      font-weight: bold;
      font-style: italic;
      font-stretch: condensed;
      line-height: 1.23;
      letter-spacing: normal;
      text-align: left;
      margin-top: 10px;
      color: #0082c3;
    }
  }
}

.cms-index-index,.checkout-cart-index {

  .global-reviews__rating__rating {
    padding-top: 10px;

    &-wrapper, .global-reviews__title {
      margin: auto;
    }

    .global-reviews__title {
      padding-top: 10px;
    }
  }
}