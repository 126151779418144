$mobile_ratio : .6;
$tablet_ratio : .8;

.card__preview__back__item {
  height: 100%;
}

.card-cover.card__bg_img {
  box-shadow: 0px -2px 5px inset rgba(60, 57, 57, 0.15);
}

.card-wrapper {
  list-style: none;
  position: relative;
  display: flex;
  justify-content: center;
  min-height: (350px * $mobile_ratio);

  @include media('>=tablet') {
    min-height: (350px * $tablet_ratio);
  }

  @include media('>=desktop') {
    min-height: 350px;
  }

  .card-wrapper__item {
    position: relative;
    width: 400px;
    z-index: 1;
    perspective: 1800px;
    width: (450px * $mobile_ratio);

    @include media('>=tablet') {
      width: (450px * $tablet_ratio);
    }

    @include media('>=desktop') {
      width: 450px;
    }
  }
}

.card-wrapper .card-wrapper__item {
  .card-preview {
    position: absolute;
    width: 100%;
    transform-style: preserve-3d;
    transition: transform .5s;
    transform: rotate3d(4, 2, -2, 43deg);
    height: (300px * $mobile_ratio);

    @include media('>=tablet') {
      min-height: (300px * $tablet_ratio);
    }

    @include media('>=desktop') {
      height: 300px;
    }

    > div {
      display: block;
      position: absolute;
    }
  }
  .card-front {
    > div {
      display: block;
      position: absolute;
      z-index: 1;
      backface-visibility: hidden;
      transform-style: preserve-3d;
    }
    transform-style: preserve-3d;
    transform-origin: 0% 50%;
    transition: transform .5s;
    transform: translate3d(0, 0, 0) rotate3d(0, 1, 0, -17deg);
    z-index: 10;
  }

  .card-page {
    height: (300px * $mobile_ratio);
    width: (400px * $mobile_ratio);

    @include media('>=tablet') {
      height: (300px * $tablet_ratio);
      width: (400px * $tablet_ratio);
    }

    @include media('>=desktop') {
      width: 400px;
      height: 300px;
    }
  }
  .card-front, .card-back, .card-front > div {
    height: (300px * $mobile_ratio);
    width: (400px * $mobile_ratio);

    @include media('>=tablet') {
      height: (300px * $tablet_ratio);
      width: (400px * $tablet_ratio);
    }

    @include media('>=desktop') {
      width: 400px;
      height: 300px;
    }
  }
  .card-back {
    transition: transform .5s;
    transform: rotate3d(0, 0, 0, -18deg) scale3d(1, 1, 1)  translate3d(0, 0, 0);
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.3);
    transform-style: preserve-3d;

    > div {
      display: block;
      position: absolute;
      top: 0;
      z-index: 1;
      backface-visibility: hidden;
      transform-style: preserve-3d;
    }

    .card-back-cover {
      top: 0;
      transform: rotateY(180deg);
      height: (300px * $mobile_ratio);
      width: (400px * $mobile_ratio);

      @include media('>=tablet') {
        height: (300px * $tablet_ratio);
        width: (400px * $tablet_ratio);
      }

      @include media('>=desktop') {
        width: 400px;
        height: 300px;
      }
    }
  }
  .card-cover-back {
    background-color: #fff;
    transform: rotate3d(0, 1, 0, -179deg);
  }

  .card-viewinside {
    .card-front {
      transform: translate3d(0, 0, 20px) rotate3d(0, 1, 0, -180deg);
    }
    .card-back {
      transform: rotate3d(0, 1, 0, -18deg) scale3d(1, 1, 1) translate3d(15px, 0, 77px);
    }
  }

  .card-preview {
    &.card-viewdefault{
      .card-cover-back{
        backface-visibility: visible;
        display: none;
      }
    }
    &.card-viewinside {
      transform: translate3d(50%, 0, 150px);
      .card-front {
        > div {
          backface-visibility: visible;
        }
      }
      .card__bg_img{
        display: none;
      }
    }
    &.card-viewback {
      transform: translate3d(-20px, -7px, 80px) rotate3d(-0.2, 1.7, -0.2, -190deg);
      @include media('>=tablet') {
        transform: translate3d(0, -30px, 80px) rotate3d(-0.2, 1.7, -0.2, -190deg);
      }

      .card-front {
        transform: translate3d(0, 0, 0) rotate3d(0, 1, 0, -1deg);
      }
    }
  }
  .card-page {
    background-color: #fff;
  }

  /* FIX IE */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

    .card-front{
      transition: transform .0s;
    }

    .card-viewdefault{
      transform: none;
      .card-front{
        transform: none;
      }
    }

    .card-viewinside{
      .card-front{
        transform: translate3d(-400px, 0px, 20px);
        .card-cover-back{
          backface-visibility: visible;
          transform: none;
        }
        .card__bg_img{
          display: none;
        }
      }

      .card-back{
        transform: none
      }
    }

    .card-viewback{
      transform: none!important;

      .card-front{
        z-index: 0;
      }

      .card-back-cover{
        backface-visibility: visible;
        transform: none;
      }
    }
  }
  /********/
}

.card-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 30px;
  overflow: hidden;
  background: #fff;
  opacity: 0;
  pointer-events: none;
  backface-visibility: hidden;
  transition: opacity 0.3s ease-in-out;
  cursor: default;
  padding: (30px * $mobile_ratio);
  color: $blue;
  font-family: "Open Sans Condensed", sans-serif;

  p {
    padding: 0 0 10px;
    -webkit-font-smoothing: antialiased;
    color: currentColor;
    font-size: (11px * $mobile_ratio);
    line-height: 1.6;
    text-align: justify;
    -webkit-touch-callout: none;
    user-select: none;
  }

  @include media('>=tablet') {
    padding: (30px * $tablet_ratio);
    p {
      font-size: (11px * $tablet_ratio);
    }
  }

  @include media('>=desktop') {
    padding: 30px;
    p {
      font-size: 11px;
    }
  }
}

.card-content-current {
  opacity: 1;
  pointer-events: auto;

  .card-supplier{
    text-align: right;
  }

  &-message{
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }
}

.card-preview {
  .card-cover {
    background-color: #fff;
    background-repeat: no-repeat;

    .card__main_img {
      position: absolute;
      top: (30px * $mobile_ratio);
      right: (30px * $mobile_ratio);
      bottom: (30px * $mobile_ratio);
      left: (30px * $mobile_ratio);
      background: no-repeat;
      background-position: center center;

      @include media('>=tablet') {
        top: (30px * $tablet_ratio);
        right: (30px * $tablet_ratio);
        bottom: (30px * $tablet_ratio);
        left: (30px * $tablet_ratio);
      }

      @include media('>=desktop') {
        top: 30px;
        right: 30px;
        bottom: 30px;
        left: 30px;
      }
    }
  }

  .card-back p {
    color: #fff;
    font-size: 13px;
    padding: 40px;
    text-align: center;
    font-weight: 700;
  }
}

@include media('<530px') {
    .card-wrapper .card-wrapper__item .card-preview.card-viewinside {
      transform: translate3d(45%, 0, 150px) rotate3d(0, 1, 0, 0deg) scale(0.7);
    }
}