@charset "UTF-8";
@font-face {
  font-family: 'Decathlon Cube';
  src: url("../fonts/decathloncube/DecathlonCube.eot");
  src: url("../fonts/decathloncube/DecathlonCube.ttf") format("truetype"), url("../fonts/decathloncube/DecathlonCube.eot?#iefix") format("embedded-opentype"), url("../fonts/decathloncube/DecathlonCube.woff") format("woff"), url("../fonts/decathloncube/DecathlonCube.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Decathlon Cube Extras';
  src: url("../fonts/decathloncube/icomoon.eot");
  src: url("../fonts/decathloncube/icomoon.ttf") format("truetype"), url("../fonts/decathloncube/icomoon.eot?#iefix") format("embedded-opentype"), url("../fonts/decathloncube/icomoon.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Chapaza Regular';
  src: url("../fonts/card/chapaza-webfont.ttf") format("truetype"), url("../fonts/card/chapaza-webfont.woff2") format("woff2"), url("../fonts/card/chapaza-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Bloomsbury Script';
  src: url("../fonts/card/bloomsbury-script-webfont.ttf") format("truetype"), url("../fonts/card/bloomsbury-script-webfont.woff2") format("woff2"), url("../fonts/card/bloomsbury-script-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

.upper {
  text-transform: uppercase; }

.lower {
  text-transform: inherit; }

.muted {
  color: #7b7b7b; }

hr {
  border: 0;
  height: 1px;
  background-color: #ced0d4;
  margin: 15px auto; }

.title--in-movement {
  font-family: "Roboto Condensed Bold", sans-serif;
  font-size: 30px;
  font-weight: bold;
  font-style: italic;
  font-stretch: condensed;
  line-height: 1.14;
  letter-spacing: -0.4px;
  text-align: center;
  color: #000000;
  transform: skewY(-7deg) translateY(20%);
  text-align: center;
  text-transform: uppercase;
  padding: .7em 0;
  margin-bottom: 3em; }
  .title--in-movement h1 {
    color: #000;
    font-weight: bold;
    font-style: italic; }
  .title--in-movement.title--bordered {
    border-top: 1px solid #000;
    border-bottom: 1px solid #0082c3; }
  .title--in-movement span:not(.visible-desktop) {
    display: block; }
  @media (min-width: 1025px) {
    .title--in-movement span:not(.visible-mobile) {
      display: initial; } }
  .title--in-movement span {
    color: #0082c3; }

.icon-question-o-alt:before,
.icon-pin:before,
.icon-user:before,
.icon-v2:before,
.icon-cart:before {
  font-family: 'Decathlon Cube Extras' !important; }

.icon-question-o-alt:before {
  content: "\e904" !important; }

.icon-pin:before {
  content: "\e901" !important; }

.icon-user:before {
  content: "\e903" !important; }

.icon-cart:before {
  content: "\e902" !important; }

.icon-time:before {
  content: "\e905"; }

.icon-talk:before {
  content: "\e906"; }

.icon-cart-alt:before {
  content: "\e907";
  color: #fff; }

.icon-shop:before {
  content: "\e901"; }

.icon-pin-alt:before {
  content: "\e900"; }

.form {
  padding: 40px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto; }
  .form--large {
    max-width: 1180px; }

.form--collapsed-input, .cart--collapsed-input {
  max-width: 580px; }
  .form--collapsed-input input[type=email], .form--collapsed-input input[type=search], .form--collapsed-input input[type=number], .form--collapsed-input input[type=password], .form--collapsed-input input[type=tel], .form--collapsed-input input[type=text], .cart--collapsed-input input[type=email], .cart--collapsed-input input[type=search], .cart--collapsed-input input[type=number], .cart--collapsed-input input[type=password], .cart--collapsed-input input[type=tel], .cart--collapsed-input input[type=text] {
    padding: 17px;
    margin-bottom: 10px; }
    .form--collapsed-input input[type=email], .form--collapsed-input input[type=search], .form--collapsed-input input[type=number], .form--collapsed-input input[type=password], .form--collapsed-input input[type=tel], .form--collapsed-input input[type=text], .cart--collapsed-input input[type=email], .cart--collapsed-input input[type=search], .cart--collapsed-input input[type=number], .cart--collapsed-input input[type=password], .cart--collapsed-input input[type=tel], .cart--collapsed-input input[type=text] {
      font-size: 13.5px; }
      @media screen and (min-width: 320px) {
        .form--collapsed-input input[type=email], .form--collapsed-input input[type=search], .form--collapsed-input input[type=number], .form--collapsed-input input[type=password], .form--collapsed-input input[type=tel], .form--collapsed-input input[type=text], .cart--collapsed-input input[type=email], .cart--collapsed-input input[type=search], .cart--collapsed-input input[type=number], .cart--collapsed-input input[type=password], .cart--collapsed-input input[type=tel], .cart--collapsed-input input[type=text] {
          font-size: calc(13.5px + 4.5 * ((100vw - 320px) / 704)); } }
      @media screen and (min-width: 1024px) {
        .form--collapsed-input input[type=email], .form--collapsed-input input[type=search], .form--collapsed-input input[type=number], .form--collapsed-input input[type=password], .form--collapsed-input input[type=tel], .form--collapsed-input input[type=text], .cart--collapsed-input input[type=email], .cart--collapsed-input input[type=search], .cart--collapsed-input input[type=number], .cart--collapsed-input input[type=password], .cart--collapsed-input input[type=tel], .cart--collapsed-input input[type=text] {
          font-size: 18px; } }
  .form--collapsed-input .input-number, .cart--collapsed-input .input-number {
    border: 1px solid #eeeeee;
    display: flex;
    margin-bottom: 0;
    background: #fff;
    align-items: center;
    padding-right: 22px;
    min-width: 50%; }

@-moz-document url-prefix() {
  .form--collapsed-input .input-number, .cart--collapsed-input .input-number {
    padding-right: 50px;
    min-width: 55%; } }
    @media (min-width: 1025px) {
      .form--collapsed-input .input-number, .cart--collapsed-input .input-number {
        margin-right: 10px; } }
    .form--collapsed-input .input-number span, .cart--collapsed-input .input-number span {
      font-size: 16.5px; }
      @media screen and (min-width: 320px) {
        .form--collapsed-input .input-number span, .cart--collapsed-input .input-number span {
          font-size: calc(16.5px + 5.5 * ((100vw - 320px) / 704)); } }
      @media screen and (min-width: 1024px) {
        .form--collapsed-input .input-number span, .cart--collapsed-input .input-number span {
          font-size: 22px; } }
    .form--collapsed-input .input-number input[type=number], .cart--collapsed-input .input-number input[type=number] {
      text-align: center;
      border: none;
      flex-grow: 1;
      margin: auto;
      margin-right: 0;
      height: auto; }
      .form--collapsed-input .input-number input[type=number], .cart--collapsed-input .input-number input[type=number] {
        font-size: 16.5px; }
        @media screen and (min-width: 320px) {
          .form--collapsed-input .input-number input[type=number], .cart--collapsed-input .input-number input[type=number] {
            font-size: calc(16.5px + 5.5 * ((100vw - 320px) / 704)); } }
        @media screen and (min-width: 1024px) {
          .form--collapsed-input .input-number input[type=number], .cart--collapsed-input .input-number input[type=number] {
            font-size: 22px; } }
  .form--collapsed-input input[type=email], .form--collapsed-input input[type=search], .form--collapsed-input input[type=number], .form--collapsed-input input[type=password], .form--collapsed-input input[type=tel], .form--collapsed-input input[type=text],
  .form--collapsed-input .btn-card, .cart--collapsed-input input[type=email], .cart--collapsed-input input[type=search], .cart--collapsed-input input[type=number], .cart--collapsed-input input[type=password], .cart--collapsed-input input[type=tel], .cart--collapsed-input input[type=text],
  .cart--collapsed-input .btn-card {
    width: 100%;
    max-width: inherit; }
    .form--collapsed-input input[type=email]:after, .form--collapsed-input input[type=search]:after, .form--collapsed-input input[type=number]:after, .form--collapsed-input input[type=password]:after, .form--collapsed-input input[type=tel]:after, .form--collapsed-input input[type=text]:after,
    .form--collapsed-input .btn-card:after, .cart--collapsed-input input[type=email]:after, .cart--collapsed-input input[type=search]:after, .cart--collapsed-input input[type=number]:after, .cart--collapsed-input input[type=password]:after, .cart--collapsed-input input[type=tel]:after, .cart--collapsed-input input[type=text]:after,
    .cart--collapsed-input .btn-card:after {
      right: -17% !important; }
  @media (min-width: 1025px) {
    .form--collapsed-input, .cart--collapsed-input {
      display: flex;
      max-width: 640px; }
      .form--collapsed-input input[type=email], .form--collapsed-input input[type=search], .form--collapsed-input input[type=number], .form--collapsed-input input[type=password], .form--collapsed-input input[type=tel], .form--collapsed-input input[type=text], .cart--collapsed-input input[type=email], .cart--collapsed-input input[type=search], .cart--collapsed-input input[type=number], .cart--collapsed-input input[type=password], .cart--collapsed-input input[type=tel], .cart--collapsed-input input[type=text] {
        margin-bottom: 0;
        padding: 15px;
        height: auto;
        margin-right: 10px; } }
  .form--collapsed-input + .error, .cart--collapsed-input + .error {
    position: relative;
    top: -20px;
    text-align: center; }

.form .btn-card, .cart .btn-card {
  background: #ffea28;
  font-family: "Roboto Condensed Bold", sans-serif; }
  .form .btn-card, .cart .btn-card {
    font-size: 12.75px; }
    @media screen and (min-width: 320px) {
      .form .btn-card, .cart .btn-card {
        font-size: calc(12.75px + 4.25 * ((100vw - 320px) / 704)); } }
    @media screen and (min-width: 1024px) {
      .form .btn-card, .cart .btn-card {
        font-size: 17px; } }
  @media (min-width: 1025px) {
    .form .btn-card, .cart .btn-card {
      padding: 17px 20px !important; }
      .form .btn-card:before, .cart .btn-card:before {
        width: 3.2em; } }
  .form .btn-card:after, .cart .btn-card:after {
    content: "";
    background-color: #fdd835;
    position: absolute;
    height: 150%;
    width: 150%;
    transition: -webkit-transform .18s ease;
    transition: transform .18s ease;
    transition: transform .18s ease, -webkit-transform .18s ease;
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
    top: 100%;
    left: -25%;
    z-index: -1;
    -webkit-transform-origin: calc(83.33333% - 2.85714em) 0;
    transform-origin: calc(83.33333% - 2.85714em) 0; }

.greetings_hu .btn-card {
  font-size: 11.25px; }
  @media screen and (min-width: 320px) {
    .greetings_hu .btn-card {
      font-size: calc(11.25px + 3.75 * ((100vw - 320px) / 704)); } }
  @media screen and (min-width: 1024px) {
    .greetings_hu .btn-card {
      font-size: 15px; } }

.btn-back {
  font-family: "Roboto Condensed Bold", Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.28571429;
  border-radius: 2px;
  text-transform: uppercase;
  color: #333;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: background-color .45s ease .09s;
  z-index: 1;
  border: 0 none;
  width: auto;
  height: auto;
  text-decoration: none;
  display: block;
  /* left: 50%; */
  padding: 15px 20px 15px 60px;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px #e2e2e2;
  background: #e2e2e2; }
  .btn-back:before {
    content: "\E006";
    font-family: "Decathlon Cube";
    left: 0;
    top: 0;
    height: 100%;
    width: 2.2em;
    font-size: 1em;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    line-height: 1;
    z-index: 1; }
  .btn-back:after {
    content: "";
    background-color: #d8d8d8;
    position: absolute;
    height: 150%;
    width: 150%;
    transition: -webkit-transform .18s ease;
    transition: transform .18s ease, -webkit-transform .18s ease;
    transform: rotate(10deg);
    top: 100%;
    left: -50%;
    z-index: -1;
    transform-origin: calc(83.33333% - 4.85714em) 0; }
  @media (min-width: 768px) {
    .btn-back {
      padding: 8px 20px 8px 40px;
      background: #fff;
      box-shadow: inherit; }
      .btn-back:after {
        background-color: #f4f4f4;
        transform-origin: calc(90.33333% - 4.85714em) 0; } }
  .btn-back:hover:after {
    transform: rotate(0) translateY(-100%);
    transition: transform .36s ease; }

body .button-arrow {
  font-family: "Roboto Condensed Bold", Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.28571429;
  border-radius: 2px;
  text-transform: uppercase;
  color: #333;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: background-color .45s ease .09s;
  z-index: 1;
  border: 0 none;
  width: auto;
  height: auto;
  font-weight: bold;
  text-decoration: none;
  /* left: 50%; */
  padding: 15px 60px 15px 20px;
  background-image: linear-gradient(to bottom, #fef63a, #eec423); }
  body .button-arrow:before {
    content: "\E007";
    font-family: "Decathlon Cube";
    right: 0;
    top: 0;
    height: 100%;
    width: 2.2em;
    font-size: 1em;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    line-height: 1;
    z-index: 1; }
  body .button-arrow:after {
    content: '';
    background-color: rgba(255, 255, 255, 0.3);
    position: absolute;
    height: 75px;
    width: 170px;
    transition: -webkit-transform .18s ease;
    transition: transform .18s ease;
    z-index: -1;
    transform-origin: 0% 100%;
    transform: rotate(-75deg);
    left: calc(100% + 25px);
    top: 0; }

.btn-next {
  font-family: "Roboto Condensed Bold", Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.28571429;
  border-radius: 2px;
  text-transform: uppercase;
  color: #333;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: background-color .45s ease .09s;
  z-index: 1;
  border: 0 none;
  width: auto;
  height: auto;
  font-weight: bold;
  text-decoration: none;
  display: block;
  /* left: 50%; */
  padding: 15px 60px 15px 20px;
  background: #ffea28; }
  .btn-next:before {
    content: "\E007";
    font-family: "Decathlon Cube";
    right: 0;
    top: 0;
    height: 100%;
    width: 2.2em;
    font-size: 1em;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    line-height: 1;
    z-index: 1; }
  .btn-next:after {
    content: "";
    background-color: #fdd835;
    position: absolute;
    height: 150%;
    width: 150%;
    transition: -webkit-transform .18s ease;
    transition: transform .18s ease;
    transform: rotate(-10deg);
    top: 100%;
    left: -25%;
    z-index: -1;
    -webkit-transform-origin: calc(83.33333% - 2.85714em) 0;
    transform-origin: calc(83.33333% - 2.85714em) 0; }
  .btn-next:hover:after {
    transform: rotate(0deg) translateY(-100%);
    transition: -webkit-transform .36s ease;
    transition: transform .36s ease; }

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }

.btn-default {
  padding: 20px;
  box-shadow: 0px 2px 4px 0 rgba(255, 255, 255, 0.15);
  background-image: linear-gradient(to top, #d2d2d2, #f7f7f7);
  width: 100%;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  cursor: pointer;
  text-align: center; }
  .btn-default {
    font-size: 13.6px; }
    @media screen and (min-width: 320px) {
      .btn-default {
        font-size: calc(13.6px + 3.4 * ((100vw - 320px) / 704)); } }
    @media screen and (min-width: 1024px) {
      .btn-default {
        font-size: 17px; } }
  @media (min-width: 1025px) {
    .btn-default {
      padding: 13px 10px; } }

.btn-secondary {
  cursor: pointer;
  padding: 20px;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.15);
  background-image: linear-gradient(to bottom, #fdf439, #efc724);
  width: 100%;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  text-align: center; }
  .btn-secondary {
    font-size: 13.6px; }
    @media screen and (min-width: 320px) {
      .btn-secondary {
        font-size: calc(13.6px + 3.4 * ((100vw - 320px) / 704)); } }
    @media screen and (min-width: 1024px) {
      .btn-secondary {
        font-size: 17px; } }
  @media (min-width: 1025px) {
    .btn-secondary {
      padding: 13px 10px; } }

.btn-primary {
  cursor: pointer;
  padding: 20px 10px;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.15);
  background-image: linear-gradient(to top, #0082c3, #0082c3);
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  text-align: center;
  z-index: 1;
  position: relative;
  overflow: hidden; }
  .btn-primary {
    font-size: 13.6px; }
    @media screen and (min-width: 320px) {
      .btn-primary {
        font-size: calc(13.6px + 3.4 * ((100vw - 320px) / 704)); } }
    @media screen and (min-width: 1024px) {
      .btn-primary {
        font-size: 17px; } }
  @media (min-width: 1025px) {
    .btn-primary {
      padding: 13px 10px; } }
  .btn-primary:after {
    content: "";
    background-color: #0074af;
    position: absolute;
    height: 150%;
    width: 150%;
    transition: -webkit-transform .18s ease;
    transition: transform .18s ease;
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
    top: 100%;
    left: -25%;
    z-index: -1;
    transform-origin: calc(83.33333% - 2.85714em) 0; }
  .btn-primary:hover:after {
    transform: rotate(0deg) translateY(-100%);
    transition: transform .36s ease; }

.select2-container .select2-search--dropdown,
.select2-container .select2-results {
  padding: 5px; }

.select2-container .select2-search--dropdown {
  padding-bottom: 0; }

.select2-container .select2-results__option {
  padding: 10px; }
  .select2-container .select2-results__option:not(:first-child) {
    border-top: 1px solid #e4e4e4; }

.jolie-select {
  position: relative; }
  .jolie-select .select2-container--default .select2-selection--single .select2-selection__clear {
    osition: relative;
    z-index: 5;
    right: 25px;
    font-size: 20px;
    font-weight: normal; }
  .jolie-select .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background: #0082c3; }
  .jolie-select .select2-container--default {
    width: 100% !important; }
    .jolie-select .select2-container--default .select2-selection--single {
      background-image: linear-gradient(to top, #e2e2e2, #fafafa);
      background-position: 97% center;
      background-repeat: no-repeat;
      border: 1px solid #e2e2e2;
      color: #555;
      font-size: inherit;
      font-family: "Roboto Condensed Bold", sans-serif;
      text-transform: uppercase;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      border-radius: 0; }
      .jolie-select .select2-container--default .select2-selection--single .select2-selection__placeholder {
        color: #000; }
      .jolie-select .select2-container--default .select2-selection--single .select2-selection__arrow b {
        border: none;
        margin-top: -11px;
        margin-left: -11px; }
        .jolie-select .select2-container--default .select2-selection--single .select2-selection__arrow b:before {
          content: "\E009";
          font-family: "Decathlon Cube";
          height: 100%;
          width: 2.2em;
          font-size: 1em;
          font-weight: 400;
          line-height: 1;
          z-index: 1; }
      .jolie-select .select2-container--default .select2-selection--single .select2-selection__rendered:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 50px;
        transition: transform .18s ease;
        z-index: -1;
        top: 0px;
        z-index: 2;
        right: -10px;
        transform: skewX(-20deg);
        background: rgba(255, 255, 255, 0.3); }

.btn-cancel {
  position: fixed;
  cursor: pointer;
  bottom: 10px;
  left: 15px;
  right: 15px;
  padding: 10px;
  display: block;
  box-sizing: border-box;
  width: calc(100% - 30px);
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px #e2e2e2;
  background-image: linear-gradient(to top, #e2e2e2, #fafafa);
  text-align: center;
  font-family: "Roboto Condensed Bold", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: normal;
  color: #242323; }

#login-form .btn-card, .balance-form .btn-card {
  margin: auto; }

#review-buttons-container .btn-card {
  padding: 15px 15px !important; }

@media only screen and (max-width: 599px) {
  .checkout-onepage-index .btn-card {
    order: 1;
    max-width: none;
    padding: 15px 10px 15px 10px !important; }
  .checkout-onepage-index .btn-back {
    order: 2; } }

.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px); }

.visible-desktop {
  display: none; }
  @media (min-width: 1025px) {
    .visible-desktop {
      display: block; } }

.visible-mobile {
  display: none; }
  @media (max-width: 1024px) {
    .visible-mobile {
      display: block; } }

.flexie {
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (min-width: 1025px) {
    .flexie {
      flex-direction: row; }
      .flexie .flexie--wrap {
        flex-wrap: wrap; }
      .flexie .flexie--column {
        flex-direction: column; }
      .flexie .flexie--row {
        flex-direction: row; }
      .flexie > *.flexie__item--boxed {
        padding: 25px; }
      .flexie .flexie__item--1 {
        flex: inherit;
        width: calc(100% / 1);
        box-sizing: border-box; }
        .flexie .flexie__item--1.flexie__item--m10 {
          width: calc((100% / 1) - 10px);
          margin-left: 10px;
          margin-right: 10px; }
        .flexie .flexie__item--1.flexie__item--m25 {
          width: calc((100% / 1) - 50px);
          margin-left: 25px;
          margin-right: 25px; }
        .flexie .flexie__item--1.flexie__item--m50 {
          width: calc((100% / 1) - 50px);
          margin-left: 50px;
          margin-right: 50px; }
      .flexie .flexie__item--2 {
        flex: inherit;
        width: calc(100% / 2);
        box-sizing: border-box; }
        .flexie .flexie__item--2.flexie__item--m10 {
          width: calc((100% / 2) - 10px);
          margin-left: 10px;
          margin-right: 10px; }
        .flexie .flexie__item--2.flexie__item--m25 {
          width: calc((100% / 2) - 50px);
          margin-left: 25px;
          margin-right: 25px; }
        .flexie .flexie__item--2.flexie__item--m50 {
          width: calc((100% / 2) - 50px);
          margin-left: 50px;
          margin-right: 50px; }
      .flexie .flexie__item--3 {
        flex: inherit;
        width: calc(100% / 3);
        box-sizing: border-box; }
        .flexie .flexie__item--3.flexie__item--m10 {
          width: calc((100% / 3) - 10px);
          margin-left: 10px;
          margin-right: 10px; }
        .flexie .flexie__item--3.flexie__item--m25 {
          width: calc((100% / 3) - 50px);
          margin-left: 25px;
          margin-right: 25px; }
        .flexie .flexie__item--3.flexie__item--m50 {
          width: calc((100% / 3) - 50px);
          margin-left: 50px;
          margin-right: 50px; }
      .flexie .flexie__item--4 {
        flex: inherit;
        width: calc(100% / 4);
        box-sizing: border-box; }
        .flexie .flexie__item--4.flexie__item--m10 {
          width: calc((100% / 4) - 10px);
          margin-left: 10px;
          margin-right: 10px; }
        .flexie .flexie__item--4.flexie__item--m25 {
          width: calc((100% / 4) - 50px);
          margin-left: 25px;
          margin-right: 25px; }
        .flexie .flexie__item--4.flexie__item--m50 {
          width: calc((100% / 4) - 50px);
          margin-left: 50px;
          margin-right: 50px; }
      .flexie .flexie__item--5 {
        flex: inherit;
        width: calc(100% / 5);
        box-sizing: border-box; }
        .flexie .flexie__item--5.flexie__item--m10 {
          width: calc((100% / 5) - 10px);
          margin-left: 10px;
          margin-right: 10px; }
        .flexie .flexie__item--5.flexie__item--m25 {
          width: calc((100% / 5) - 50px);
          margin-left: 25px;
          margin-right: 25px; }
        .flexie .flexie__item--5.flexie__item--m50 {
          width: calc((100% / 5) - 50px);
          margin-left: 50px;
          margin-right: 50px; }
      .flexie .flexie__item--6 {
        flex: inherit;
        width: calc(100% / 6);
        box-sizing: border-box; }
        .flexie .flexie__item--6.flexie__item--m10 {
          width: calc((100% / 6) - 10px);
          margin-left: 10px;
          margin-right: 10px; }
        .flexie .flexie__item--6.flexie__item--m25 {
          width: calc((100% / 6) - 50px);
          margin-left: 25px;
          margin-right: 25px; }
        .flexie .flexie__item--6.flexie__item--m50 {
          width: calc((100% / 6) - 50px);
          margin-left: 50px;
          margin-right: 50px; }
      .flexie .flexie__item--7 {
        flex: inherit;
        width: calc(100% / 7);
        box-sizing: border-box; }
        .flexie .flexie__item--7.flexie__item--m10 {
          width: calc((100% / 7) - 10px);
          margin-left: 10px;
          margin-right: 10px; }
        .flexie .flexie__item--7.flexie__item--m25 {
          width: calc((100% / 7) - 50px);
          margin-left: 25px;
          margin-right: 25px; }
        .flexie .flexie__item--7.flexie__item--m50 {
          width: calc((100% / 7) - 50px);
          margin-left: 50px;
          margin-right: 50px; }
      .flexie .flexie__item--8 {
        flex: inherit;
        width: calc(100% / 8);
        box-sizing: border-box; }
        .flexie .flexie__item--8.flexie__item--m10 {
          width: calc((100% / 8) - 10px);
          margin-left: 10px;
          margin-right: 10px; }
        .flexie .flexie__item--8.flexie__item--m25 {
          width: calc((100% / 8) - 50px);
          margin-left: 25px;
          margin-right: 25px; }
        .flexie .flexie__item--8.flexie__item--m50 {
          width: calc((100% / 8) - 50px);
          margin-left: 50px;
          margin-right: 50px; }
      .flexie .flexie__item--9 {
        flex: inherit;
        width: calc(100% / 9);
        box-sizing: border-box; }
        .flexie .flexie__item--9.flexie__item--m10 {
          width: calc((100% / 9) - 10px);
          margin-left: 10px;
          margin-right: 10px; }
        .flexie .flexie__item--9.flexie__item--m25 {
          width: calc((100% / 9) - 50px);
          margin-left: 25px;
          margin-right: 25px; }
        .flexie .flexie__item--9.flexie__item--m50 {
          width: calc((100% / 9) - 50px);
          margin-left: 50px;
          margin-right: 50px; }
      .flexie .flexie__item--10 {
        flex: inherit;
        width: calc(100% / 10);
        box-sizing: border-box; }
        .flexie .flexie__item--10.flexie__item--m10 {
          width: calc((100% / 10) - 10px);
          margin-left: 10px;
          margin-right: 10px; }
        .flexie .flexie__item--10.flexie__item--m25 {
          width: calc((100% / 10) - 50px);
          margin-left: 25px;
          margin-right: 25px; }
        .flexie .flexie__item--10.flexie__item--m50 {
          width: calc((100% / 10) - 50px);
          margin-left: 50px;
          margin-right: 50px; }
      .flexie .flexie__item--11 {
        flex: inherit;
        width: calc(100% / 11);
        box-sizing: border-box; }
        .flexie .flexie__item--11.flexie__item--m10 {
          width: calc((100% / 11) - 10px);
          margin-left: 10px;
          margin-right: 10px; }
        .flexie .flexie__item--11.flexie__item--m25 {
          width: calc((100% / 11) - 50px);
          margin-left: 25px;
          margin-right: 25px; }
        .flexie .flexie__item--11.flexie__item--m50 {
          width: calc((100% / 11) - 50px);
          margin-left: 50px;
          margin-right: 50px; }
      .flexie .flexie__item--12 {
        flex: inherit;
        width: calc(100% / 12);
        box-sizing: border-box; }
        .flexie .flexie__item--12.flexie__item--m10 {
          width: calc((100% / 12) - 10px);
          margin-left: 10px;
          margin-right: 10px; }
        .flexie .flexie__item--12.flexie__item--m25 {
          width: calc((100% / 12) - 50px);
          margin-left: 25px;
          margin-right: 25px; }
        .flexie .flexie__item--12.flexie__item--m50 {
          width: calc((100% / 12) - 50px);
          margin-left: 50px;
          margin-right: 50px; } }

[data-toggle] {
  cursor: pointer; }

.toggle {
  display: none; }
  .toggle.toggle--active {
    display: block; }

html.noscroll {
  overflow: hidden; }
  html.noscroll body {
    position: relative;
    overflow: hidden; }

.messages {
  z-index: 10;
  position: relative;
  padding-top: 10px; }

.grecaptcha-badge {
  z-index: 2; }

.wrapper {
  overflow-x: hidden;
  max-width: inherit;
  width: 100%;
  position: relative;
  transition: all .25s ease-in-out;
  background: #F5F5F5; }

#header {
  padding-left: 0;
  position: relative;
  padding: 0 0 65px;
  overflow: hidden;
  background: #fff;
  z-index: 6;
  box-shadow: 2px 2px 5px #f5f5f5;
  /*
  &:after {
    position: absolute;
    content: '';
    pointer-events: none;
    top: 0;
    left: -25%;
    z-index: 1;
    width: 150%;
    height: 50%;
    background: #fff;
    -webkit-transform: rotate(-3deg);
    transform: rotate(-3deg);
    -webkit-transform-origin: 3% 0;
    transform-origin: 3% 0;

  }

   */
  /**
  * Banner info
   */ }
  @media (min-width: 1025px) {
    #header {
      padding: 0 0 50px;
      box-shadow: 0px 3px 4px 0 rgba(0, 0, 0, 0.08); }
      #header .page-header, #header .page-header-container {
        height: 50px; }
      #header.page-header {
        height: 50px; } }

@media (min-width: 321px) and (max-width: 1024px) {
  #header.page-header {
    height: 52px; }
  #header .page-header-container {
    height: 52px; } }
  @media only screen and (max-width: 767px) {
    #header {
      padding-left: 0 !important; }
      #header .header-minicart {
        height: calc(100% + 20px) !important;
        width: 110px;
        background: #ffea28 !important;
        top: 0 !important; } }
  @media (min-width: 768px) {
    #header .header-minicart {
      width: auto;
      text-align: left;
      display: flex;
      align-items: center;
      padding-top: 0 !important; }
      #header .header-minicart i {
        font-size: 22px;
        bottom: auto;
        transform: translate(-50%, -50%); }
      #header .header-minicart a {
        height: auto !important;
        display: flex;
        align-items: center; }
        #header .header-minicart a .picto {
          width: 30px;
          margin-right: 6px; } }
  #header .header-minicart p {
    line-height: 1.1; }
  #header .bloc_header {
    width: 75px; }
    #header .bloc_header.header_languages {
      float: left; }
      #header .bloc_header.header_languages .form-language {
        width: 50px; }
        @media only screen and (max-width: 767px) {
          #header .bloc_header.header_languages .form-language {
            margin-top: 10px; } }
      #header .bloc_header.header_languages .select2-container {
        width: 100% !important; }
        @media only screen and (max-width: 767px) {
          #header .bloc_header.header_languages .select2-container {
            display: block; }
            #header .bloc_header.header_languages .select2-container span {
              display: block; } }
    @media (min-width: 768px) {
      #header .bloc_header {
        width: auto;
        text-align: left;
        display: flex;
        align-items: center;
        margin-right: 30px; }
        #header .bloc_header .picto {
          margin-right: 6px;
          margin-top: 0; }
        #header .bloc_header.header-account {
          margin-right: 15px; }
        #header .bloc_header p {
          text-align: left;
          padding-top: 0; }
        #header .bloc_header a {
          display: flex;
          align-items: center; } }
    #header .bloc_header p {
      line-height: 1.1; }
    #header .bloc_header .header-minicart i {
      font-size: 23px; }
    #header .bloc_header .skip-account {
      left: -7px; }
      @media only screen and (min-width: 768px) {
        #header .bloc_header .skip-account {
          width: 75px; } }

@media (min-width: 321px) and (max-width: 1024px) {
  #header .bloc_header .skip-account {
    height: 52px; } }
  #header h1 {
    margin-bottom: 0; }
  @media only screen and (min-width: 771px) {
    #header .navigation__button {
      height: 50px; }
    #header .logo {
      height: 34px;
      min-height: 34px;
      top: 50%; }
      #header .logo .large {
        margin-top: 0;
        width: 140px; } }
  @media only screen and (min-width: 771px) {
    #header .header-minicart .picto {
      margin-top: 3px; }
      #header .header-minicart .picto span {
        left: 24px;
        top: -25px; }
    #header .header-minicart .skip-cart {
      height: 50px; } }
  @media only screen and (min-width: 771px) {
    #header .bloc_header {
      height: 50px; } }
  @media (max-width: 479px) {
    #header {
      margin-top: -20px;
      transform: skewY(-5deg);
      padding-top: 20px;
      margin-bottom: -15px; }
      #header > div {
        transform: skewY(5deg); } }
  #header .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    @media (min-width: 810px) {
      #header .logo {
        display: block; } }
  #header .bloc_header.header-tel {
    display: none; }
    @media (min-width: 1025px) {
      #header .bloc_header.header-tel {
        display: flex; } }
  @media (min-width: 768px) {
    #header {
      max-width: inherit; } }
  #header .banner-info {
    width: 100%;
    min-height: 35px;
    background: #ffea28;
    text-align: center;
    line-height: 35px;
    font-weight: bold;
    font-size: 17px;
    color: #333;
    font-family: "Roboto Condensed Bold", sans-serif; }
    @media only screen and (max-width: 479px) {
      #header .banner-info {
        padding-top: 35px; } }

#top .cms-page-view .main-container {
  box-shadow: inherit; }

[class*=dkti-] {
  font-family: DecathlonIconfont;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  letter-spacing: 0;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  font-smoothing: subpixel-antialiased;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  zoom: 1; }

.dkti-home-delivery:before {
  content: "\E00F"; }

.dkti-secured-payment:before {
  content: "\E03D"; }

.dkti-exchange-return:before {
  content: "\E050"; }

aside {
  display: block; }

a {
  background-color: transparent; }
  a:active, a:hover {
    outline: 0; }

img {
  border: 0; }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }

.row {
  margin-left: -15px;
  margin-right: -15px; }

.col-sm-2, .col-sm-3, .col-sm-6, .col-sm-4 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px; }

.col-xs-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
  width: 100%; }

@media (min-width: 801px) {
  .col-sm-2, .col-sm-3 {
    float: left; }
  .col-sm-6 {
    float: left;
    width: 50%; }
  .col-sm-4 {
    float: left;
    width: 35%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-2 {
    width: 16.66666667%; }
  .col-sm-1 {
    float: left; }
  .col-sm-offset-1 {
    margin-left: 3.33333333%; }
  .col-last-item {
    margin-left: 13%; } }

.container-fluid:after, .container-fluid:before {
  content: " ";
  display: table; }

.row:after, .row:before {
  content: " ";
  display: table; }

.container-fluid:after, .row:after {
  clear: both; }

.cta-v2 {
  font-family: "Roboto Condensed Bold", Arial, Helvetica, sans-serif;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  color: #323233;
  border-radius: 2px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #ffea28;
  position: relative;
  overflow: hidden;
  transition: background-color .45s ease .09s;
  z-index: 1;
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.28571429;
  padding: .78571429em 1.85714286em; }
  .cta-v2:hover {
    text-decoration: none; }

a.cta-v2 {
  text-decoration: none; }

.cta-v2:after {
  content: "";
  position: absolute;
  background-color: #fdd935;
  height: 150%;
  width: 150%;
  transition: transform .18s ease;
  transform: rotate(-10deg);
  top: 100%;
  left: -25%;
  z-index: -1; }

.cta-v2:hover:not(.cta-v2--disabled):not([disabled]) {
  background-color: #fdd935;
  transition: background-color .18s ease .09s;
  color: #323233; }
  .cta-v2:hover:not(.cta-v2--disabled):not([disabled]):after {
    transform: rotate(0deg) translateY(-100%);
    transition: transform .36s ease; }

.cta-v2:focus {
  outline: 1px dotted #b1b1b3;
  outline-offset: 1px;
  color: #323233; }

.cta-v2--alt {
  background-color: #0082c3;
  padding: 15px 20px;
  color: #fff;
  font-family: Roboto-Condensed, Roboto, Arial, sans-serif;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
  text-align: left;
  color: #ffffff;
  border-radius: 0; }
  .cta-v2--alt:after, .cta-v2--alt:hover:not(.cta-v2--disabled):not([disabled]) {
    background-color: #0082c3;
    color: #fff; }
  .cta-v2--alt:after {
    content: "";
    background-color: #0074af;
    position: absolute;
    height: 150%;
    width: 150%;
    transition: -webkit-transform .18s ease;
    transition: transform .18s ease;
    transition: transform .18s ease, -webkit-transform .18s ease;
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
    top: 100%;
    left: -25%;
    z-index: -1;
    transform-origin: calc(83.33333% - 2.85714em) 0; }
  .cta-v2--alt:focus {
    color: #fff; }
  .cta-v2--alt:hover:not(.cta-v2--disabled):not([disabled]):after {
    transform: rotate(0deg) translateY(-100%); }
  .cta-v2--alt:hover:after {
    transform: rotate(0deg) translateY(-100%);
    transition: -webkit-transform .36s ease;
    transition: transform .36s ease; }

.cta-v2[data-icon]:before {
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  line-height: 1;
  z-index: 1;
  content: attr(data-icon); }

.cta-v2:after {
  transform-origin: calc(83.33333333333334% - 2.857142857142857em) 0; }

.cta-v2[data-icon] {
  padding-right: 4em; }
  .cta-v2[data-icon]:before {
    width: 2em;
    font-size: 0.89286em; }

.cta-v2[data-icon=">"]:before {
  width: 2.85714286em;
  font-family: "Decathlon Cube";
  font-size: 100%;
  content: "\E007"; }

@media print {
  *, :after, :before {
    background: transparent !important;
    color: #323233 !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  a {
    text-decoration: underline; }
    a:visited {
      text-decoration: underline; }
    a[href^="#"]:after {
      content: ""; }
  blockquote {
    border: 1px solid #afb1b3;
    page-break-inside: avoid; }
  img {
    page-break-inside: avoid; }
  h3, p {
    orphans: 3;
    widows: 3; }
  h3 {
    page-break-after: avoid; } }

@media (pointer: coarse) and (hover: none) {
  * {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent; }
  :focus {
    outline: none !important; } }

h3 {
  font-weight: 400;
  margin: 0; }

a {
  color: #575859; }
  a:focus, a:hover {
    color: #575859; }

li {
  list-style: none; }

*, :after, :before {
  box-sizing: border-box; }

.icon-star {
  color: #ffb339;
  font-size: 12px;
  display: inline-block;
  width: 12px;
  vertical-align: middle;
  margin-right: 2px;
  margin: 0; }
  .icon-star:last-child {
    margin-right: 0; }
  .icon-star.star-half {
    color: #d4d7d9;
    position: relative; }
    .icon-star.star-half:after {
      content: "\E04B";
      position: absolute;
      left: 0;
      width: 6px;
      overflow: hidden;
      color: #ffb339;
      top: 0; }

@media print {
  .block-footer {
    display: none !important; } }

.navigation--closed {
  margin-left: 0;
  animation-duration: .2s; }

@media only screen and (max-width: 479px) {
  .navigation--closed {
    animation-name: j; } }

@media only screen and (min-width: 480px) {
  .navigation--closed {
    animation-name: o; } }

@media only screen and (min-width: 801px) {
  .navigation--closed {
    animation-name: o; } }

.global-reviews {
  display: flex;
  flex-wrap: wrap;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;
  padding: 20px 28px 30px;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  background-color: #fff; }
  .global-reviews > * {
    width: 50%; }

global-reviews__actions {
  margin-left: auto;
  margin-right: auto; }

.global-reviews__infos-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center; }

.global-reviews__rating {
  margin-left: .7em;
  display: flex;
  font-size: 1.5625rem;
  font-weight: 700;
  color: #575859; }

.global-reviews__rating__rating-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; }

.global-reviews__rating span {
  font-family: "Roboto Condensed Bold", Arial, sans-serif; }
  .global-reviews__rating span {
    font-size: 30px; }
    @media screen and (min-width: 320px) {
      .global-reviews__rating span {
        font-size: calc(30px + 10 * ((100vw - 320px) / 704)); } }
    @media screen and (min-width: 1024px) {
      .global-reviews__rating span {
        font-size: 40px; } }

.global-reviews__stars {
  display: flex; }
  .global-reviews__stars .icon-star {
    width: 16px;
    margin-right: 4%;
    font-size: 1rem; }

.global-reviews__title {
  font-style: italic;
  line-height: 1.2;
  color: #575859;
  padding-top: 10px;
  font-family: "Roboto Condensed", Arial, sans-serif; }
  .global-reviews__title {
    font-size: 12.8px; }
    @media screen and (min-width: 320px) {
      .global-reviews__title {
        font-size: calc(12.8px + 3.2 * ((100vw - 320px) / 704)); } }
    @media screen and (min-width: 1024px) {
      .global-reviews__title {
        font-size: 16px; } }
  .global-reviews__title em {
    font-family: "Roboto Condensed Bold", Arial, sans-serif;
    display: inline-block;
    width: 100%;
    margin-top: 6px;
    margin-bottom: 6px;
    text-transform: uppercase;
    font-weight: 700; }
    .global-reviews__title em {
      font-size: 17.6px; }
      @media screen and (min-width: 320px) {
        .global-reviews__title em {
          font-size: calc(17.6px + 4.4 * ((100vw - 320px) / 704)); } }
      @media screen and (min-width: 1024px) {
        .global-reviews__title em {
          font-size: 22px; } }

.global-reviews__rating, .global-reviews__stars {
  align-items: baseline; }

.global-reviews__author, .global-reviews__sample-review {
  display: none; }

.global-reviews__actions {
  display: flex;
  flex-direction: column;
  max-width: 15.625rem;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto; }
  @media (min-width: 768px) {
    .global-reviews__actions {
      width: 60%; } }
  @media (min-width: 1080px) {
    .global-reviews__actions {
      margin-left: 22%;
      margin-right: auto; } }
  .global-reviews__actions .cta-v2 {
    display: block; }
    .global-reviews__actions .cta-v2:first-child {
      margin-bottom: .6em; }

@media (min-width: 1080px) {
  .global-reviews {
    position: relative;
    padding-top: 57px;
    padding-bottom: 57px;
    max-width: 1180px;
    margin: 0 auto;
    border-bottom: 1px solid #edeff1;
    background-color: #edeff1; }
  .global-reviews__rating {
    justify-content: center;
    margin-left: 0;
    margin-bottom: .3em;
    line-height: 1;
    width: 100%; }
  .global-reviews__rating__rating {
    display: flex;
    flex-wrap: wrap; }
  .global-reviews__infos-wrapper {
    flex-direction: inherit;
    width: 50%; }
  .global-reviews__title {
    padding-left: 20px;
    padding-right: 1%;
    margin-bottom: 0;
    text-align: left;
    padding-top: 0;
    max-width: 300px; }
    .global-reviews__title em {
      display: block;
      margin-top: 0;
      font-size: 1.25rem;
      line-height: 1; }
  .global-reviews_quote {
    display: flex;
    flex-wrap: wrap; }
    .global-reviews_quote .global-reviews__actions {
      width: 100%; }
  .global-reviews__author {
    display: flex;
    flex-direction: column;
    width: 22%;
    padding-right: 8%;
    text-transform: uppercase;
    text-align: left; }
    .global-reviews__author div {
      font-size: 0.875rem;
      font-weight: 700; }
    .global-reviews__author span {
      display: block;
      width: 100%;
      font-size: 0.75rem;
      font-style: italic; }
      .global-reviews__author span:last-child {
        color: #7d7e80; }
  .global-reviews__sample-review {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 24px;
    border-radius: 12px;
    background-color: #fff;
    font-size: 0.8125rem;
    width: 78%;
    border: 0; }
    .global-reviews__sample-review:before {
      content: "";
      position: absolute;
      top: 15px;
      left: -6px;
      display: block;
      width: 16px;
      height: 16px;
      transform: skewX(40deg);
      background-color: #fff; }
    .global-reviews__sample-review p {
      text-align: left;
      font-style: italic;
      color: #575859; }
  .global-reviews__actions {
    width: 16%;
    margin-top: 19px; } }

.container-services {
  background: #0082c3;
  position: relative;
  border-top: 6px solid #ea6a24; }
  .container-services:before {
    content: "";
    position: absolute;
    top: 0px;
    z-index: 2;
    left: 0;
    right: 0;
    height: 1px;
    background: #fff; }
  .container-services .inner-services {
    color: #fff;
    margin-bottom: 0;
    padding: 0;
    position: relative; }
    .container-services .inner-services .slick-track {
      margin: 0 !important; }
    .container-services .inner-services a {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 17px 0px 17px 30px; }
      .container-services .inner-services a:hover {
        text-decoration: none; }
      @media (min-width: 768px) {
        .container-services .inner-services a {
          padding: 30px 0px 30px 30px; } }
    @media (min-width: 1023px) {
      .container-services .inner-services .slick-track {
        width: 100% !important; }
        .container-services .inner-services .slick-track .slick-slide {
          width: 25% !important; } }
    @media (min-width: 1023px) {
      .container-services .inner-services .slick-slide {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: relative; }
        .container-services .inner-services .slick-slide:not(:first-child):before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          height: 105px;
          left: 10px;
          width: 3px;
          background: #0071aa;
          transform: skewX(-5deg); } }
    .container-services .inner-services a {
      color: #fff; }
    .container-services .inner-services .icon-v2 {
      display: inline-block;
      font-size: 30px;
      height: 35px;
      width: 35px;
      vertical-align: middle;
      position: relative;
      margin-right: 10px; }
      .container-services .inner-services .icon-v2.icon-desktop {
        width: 47px;
        font-size: 47px; }
        .container-services .inner-services .icon-v2.icon-desktop:before {
          position: absolute;
          top: -13px;
          left: 0; }
    .container-services .inner-services .text {
      display: inline-block;
      font-family: "Roboto Condensed Bold", Arial, Helvetica, sans-serif;
      font-weight: 700;
      font-style: normal;
      font-style: italic;
      padding-left: 10px;
      max-width: 200px;
      text-align: left;
      text-transform: uppercase;
      vertical-align: middle; }
      .container-services .inner-services .text {
        font-size: 11.25px; }
        @media screen and (min-width: 320px) {
          .container-services .inner-services .text {
            font-size: calc(11.25px + 3.75 * ((100vw - 320px) / 704)); } }
        @media screen and (min-width: 1024px) {
          .container-services .inner-services .text {
            font-size: 15px; } }

.services-shadow {
  display: none; }

@media (max-width: 1079px) {
  .container-services .slick-services .text {
    max-width: 125px; } }

@media (max-width: 800px) {
  .container-services .slick-services .text {
    display: inline-block;
    max-width: none; } }

.social-icons a {
  display: inline-block; }
  .social-icons a:not(:last-child) {
    margin-right: 10px; }

.block-footer {
  position: relative;
  overflow: hidden;
  width: 100%;
  background: #edeff1;
  font-size: 14px; }
  .block-footer .accordion a, .block-footer .container-footer-mentions a {
    color: #575859; }
  .block-footer h3 {
    color: #0082c3;
    text-transform: uppercase;
    font-family: "Roboto Condensed Bold", Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 0.875rem; }
  .block-footer .container-footer-links {
    background: #edeff1; }
  .block-footer .slick-footer-links {
    border-bottom: 1px solid #edeff1;
    padding: 1em 20px 10px;
    margin-bottom: 0;
    max-width: 1180px; }
    .block-footer .slick-footer-links h3 {
      margin-right: 5px;
      margin-bottom: 15px; }
    .block-footer .slick-footer-links a {
      display: block;
      font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
      font-weight: 400;
      font-style: normal; }
  .block-footer .container-footer-newsletter-social {
    background: #edeff1;
    margin-bottom: 10px; }
    .block-footer .container-footer-newsletter-social .container-fluid {
      max-width: 1180px;
      border-bottom: 1px solid #edeff1;
      padding: 20px; }
    .block-footer .container-footer-newsletter-social a:hover {
      text-decoration: none; }
  .block-footer .container-footer-mentions {
    max-width: 1180px;
    text-align: center;
    padding: 1em 20px 3em;
    position: relative; }
    .block-footer .container-footer-mentions .mentions-links {
      padding: 0; }
      .block-footer .container-footer-mentions .mentions-links li {
        display: inline-block;
        font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 0.6875rem; }
        .block-footer .container-footer-mentions .mentions-links li:after {
          content: " - "; }
        .block-footer .container-footer-mentions .mentions-links li:last-child:after {
          content: ""; }

@media (min-width: 801px) {
  .block-footer .slick-footer-links {
    display: block !important;
    position: relative !important;
    width: auto !important;
    z-index: auto !important; } }

.accordion-section-title {
  display: none; }

@media (max-width: 800px) {
  .block-footer .container-footer-newsletter-social .row {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .block-footer .container-footer-newsletter-social .row .table-cell-display {
      display: flex;
      width: 290px;
      justify-content: space-around; }
    .block-footer .container-footer-newsletter-social .row > * {
      float: none;
      width: auto;
      text-align: center; }
      .block-footer .container-footer-newsletter-social .row > *:not(:last-child) {
        margin-bottom: 20px; }
  .block-footer .slick-footer-links {
    text-align: center; }
    .block-footer .slick-footer-links h3 {
      text-transform: none; }
  .block-footer .container-footer-newsletter-social .container-fluid {
    padding: 10px; }
  .block-footer .container-footer-mentions {
    max-width: 1180px;
    text-align: center; }
    .block-footer .container-footer-mentions .mentions-links li:after {
      content: ""; }
  .block-footer .accordion-section-content {
    min-height: 199px;
    padding-bottom: 20px; }
  .block-footer .accordion-section-title {
    display: block;
    color: #0082c3;
    text-transform: uppercase;
    font-family: "Roboto Condensed Bold", Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 0.875rem;
    text-align: center;
    border-bottom: 1px solid #edeff1;
    padding: 10px 0;
    position: relative; }
    .block-footer .accordion-section-title .image-close {
      display: inline-block;
      position: absolute;
      right: 15px;
      color: #323233; }
      .block-footer .accordion-section-title .image-close:before {
        font-family: "Decathlon Cube";
        content: "\E00D";
        font-size: 10px; } }

.greetings-seo {
  display: block;
  margin: auto;
  max-width: 1140px;
  padding: 1em 20px 10px;
  text-align: justify; }

@media (min-width: 801px) and (max-width: 1080px) {
  .block-footer .container-footer-mentions {
    text-align: right; } }

/* Icons */
.block-footer .icon-facebook2:before {
  content: "\E034";
  font-size: 40px;
  color: #444444; }

.block-footer .icon-youtube2:before {
  content: "\E036";
  font-size: 40px;
  color: #444444; }

.block-footer .icon-twitter2:before {
  content: "\E035";
  font-size: 40px;
  color: #444444; }

.block-footer .social_networks li {
  display: inline-block;
  vertical-align: top; }

/*! CSS Used from: Embedded */
.container-services .slick-services .text {
  font-style: italic !important;
  font-weight: 500 !important;
  font-family: Roboto, Arial, Helvetica, sans-serif !important; }

.homepage .block-footer {
  margin-top: 5rem; }

/*! CSS Used keyframes */
@keyframes j {
  0% {
    margin-left: 80vw; }
  to {
    margin-left: 0; } }

@keyframes o {
  0% {
    margin-left: 400px; }
  to {
    margin-left: 0; } }

/* Slider */
@media (min-width: 801px) {
  #accordion-1 .slick-list .slick-track {
    width: unset !important; } }

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  /*background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;*/
  background: #fff center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: 10px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: 10px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 10px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.slick-slider > .slick-prev,
.slick-slider > .slick-next {
  position: absolute;
  display: block;
  height: 30px;
  width: 30px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  padding: 0;
  border: none;
  outline: none;
  z-index: 1; }
  .slick-slider > .slick-prev:focus, .slick-slider > .slick-prev:hover,
  .slick-slider > .slick-next:focus,
  .slick-slider > .slick-next:hover {
    outline: none; }
  .slick-slider > .slick-prev:before,
  .slick-slider > .slick-next:before {
    font-family: "Decathlon Cube";
    content: "\E00E";
    font-size: 18px;
    line-height: 10px;
    color: #fff; }

.slick-slider .slick-next:before {
  content: "\E00F"; }

@media (max-width: 800px) {
  .slick-slider > .slick-next:before {
    font-size: 14px; }
  .slick-slider > .slick-prev:before {
    font-size: 14px; } }

.container-services .slick-services .slick-next {
  border: 0 none;
  height: 25px;
  margin-top: -12px;
  top: 50%;
  width: 25px;
  background-position: -7px -63px;
  right: 10px; }
  .container-services .slick-services .slick-next:before {
    color: #fff;
    line-height: 1; }

@media (max-width: 800px) {
  .container-services .slick-services .slick-next:before {
    height: 14px;
    margin-top: -7px;
    width: 14px; } }

.slick-slider .slick-dots {
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%;
  margin-top: 20px; }
  .slick-slider .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-slider .slick-dots li:only-child {
      display: none; }
    .slick-slider .slick-dots li button {
      border: 0;
      text-indent: -99999px;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0;
      font-size: 0;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-slider .slick-dots li button:focus, .slick-slider .slick-dots li button:hover {
        outline: none; }
      .slick-slider .slick-dots li button:focus:before, .slick-slider .slick-dots li button:hover:before {
        opacity: 1; }
      .slick-slider .slick-dots li button:before {
        position: absolute;
        top: 7px;
        left: 7px;
        content: " ";
        font-family: slick;
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: #323233;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        width: 6px;
        height: 6px;
        border-radius: 20px;
        background-color: #afb1b3; }
    .slick-slider .slick-dots li.slick-active button:before {
      color: #323233;
      border: 3px solid #0082c3;
      background-color: #fff;
      height: 12px;
      width: 12px;
      top: 4px;
      left: 4px; }

.slick-slider.slick-initialized.toggle {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100vw; }
  .slick-slider.slick-initialized.toggle.toggle--active {
    width: auto;
    position: relative;
    left: auto;
    z-index: auto; }

/*! CSS Used from: https://www.decathlon.be/vassets/css/header.7ec9e41210ea55ffe60f.css */
.navigation__button {
  position: relative;
  display: block;
  width: 66px;
  z-index: 2;
  height: 66px;
  background-color: #fff;
  transform: rotate(0deg);
  float: left;
  text-decoration: none;
  transition: .28s ease-in-out; }
  .navigation__button:hover {
    text-decoration: none; }
  @media (min-width: 810px) {
    .navigation__button {
      height: 65px;
      border-right: 1px solid #edeff1; } }
  .navigation__button div {
    position: relative;
    width: 26px;
    margin: 0 auto; }
  .navigation__button span {
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: #575859;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .28s ease-in-out; }
    .navigation__button span:first-child {
      top: 19px; }
    .navigation__button span:nth-child(2) {
      top: 26px; }
    .navigation__button span:nth-child(3) {
      top: 33px; }
    @media (min-width: 768px) {
      .navigation__button span:first-child {
        top: 12px; }
      .navigation__button span:nth-child(2) {
        top: 19px; }
      .navigation__button span:nth-child(3) {
        top: 26px; } }

.navigation__button__label {
  margin: 0;
  padding: 41px 0 0;
  color: #575859;
  text-transform: uppercase;
  font-size: .7em;
  text-align: center; }
  @media (min-width: 768px) {
    .navigation__button__label {
      padding-top: 34px; } }

.navigation__button__label--opened {
  display: none; }

/*! CSS Used from: https://www.decathlon.be/static/css/style.css */
*:focus {
  outline: none !important; }

/*! CSS Used from: https://www.decathlon.be/vassets/css/header.7ec9e41210ea55ffe60f.css */
.icon {
  font-family: "Decathlon Cube";
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  letter-spacing: 0;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  font-smoothing: subpixel-antialiased;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  zoom: 1; }

.icon-star:before {
  content: "\E04B"; }

.icon-user:before {
  content: "\E001"; }

.icon-search:before {
  content: "\E003"; }

.icon-pin:before {
  content: "\E004"; }

.icon-arrow1-l:before {
  content: "\E006"; }

.icon-arrow1-r:before {
  content: "\E007"; }

.icon-help-line:before {
  content: "\E024"; }

.icon-close:before {
  content: "\E03B"; }

.navigation {
  position: absolute;
  top: 85px;
  z-index: 9999;
  font-size: 1.3rem;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  height: calc(100% - 88px);
  font-weight: 400;
  font-style: normal;
  background-color: #0082c3; }
  @media (min-width: 480px) {
    .navigation {
      top: 0;
      height: 100vh; } }
  @media (max-width: 479px) {
    .navigation:before {
      content: "";
      transform: skewY(-5deg);
      background-color: #0082c3;
      position: absolute;
      top: -15px;
      left: 0;
      right: 0;
      height: 70px; } }
  .navigation:before {
    content: ""; }
  .navigation .list-reset {
    margin: 0;
    padding: 0; }

@media only screen and (max-width: 479px) {
  .navigation {
    left: -100vw;
    width: 100vw; } }

@media only screen and (min-width: 480px) {
  .navigation {
    left: -400px;
    width: 400px; } }

@media only screen and (min-width: 801px) {
  .navigation {
    left: -400px;
    width: 400px; } }

@media (min-width: 801px) {
  .navigation {
    font-size: 1.4rem; } }

.navigation--open {
  animation-duration: .2s;
  left: 0; }
  .navigation--open ~ .wrapper #header .navigation__button {
    background: #0082c3;
    height: 100px; }
    .navigation--open ~ .wrapper #header .navigation__button span {
      background: #fff; }
      .navigation--open ~ .wrapper #header .navigation__button span:first-child {
        top: 25px;
        transform: rotate(135deg); }
      .navigation--open ~ .wrapper #header .navigation__button span:nth-child(2) {
        opacity: 0;
        left: -60px; }
      .navigation--open ~ .wrapper #header .navigation__button span:nth-child(3) {
        top: 25px;
        transform: rotate(-135deg); }
    .navigation--open ~ .wrapper #header .navigation__button .navigation__button__label {
      color: #fff; }
      .navigation--open ~ .wrapper #header .navigation__button .navigation__button__label.navigation__button__label--opened {
        display: block;
        margin: auto; }
      .navigation--open ~ .wrapper #header .navigation__button .navigation__button__label.navigation__button__label--closed {
        display: none; }

@media only screen and (max-width: 479px) and (orientation: portrait) {
  .navigation--open {
    animation-name: k; } }

@media (min-width: 801px) {
  .navigation--open {
    animation-name: p; } }

.navigation--logo {
  margin-top: 14px;
  padding-left: 40px; }

.navigation--baseline {
  vertical-align: middle;
  padding-left: 10px;
  font-size: 1em;
  text-transform: uppercase;
  color: #fff; }

.navigation__header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 66px;
  background-color: #0082c3; }

.navigation__header__back, .navigation__header__title {
  display: table-cell;
  vertical-align: middle; }

.navigation__header__back {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 66px;
  height: 66px;
  padding: 14px;
  text-align: center;
  color: #fff;
  background-color: #0075af;
  opacity: 0;
  transition: color .2s ease-in-out; }
  .navigation__header__back span {
    display: inline-block;
    vertical-align: super;
    width: 100%;
    text-transform: uppercase;
    font-size: .7em; }
  .navigation__header__back .icon-arrow1-l {
    height: 22px; }
    .navigation__header__back .icon-arrow1-l:before {
      height: 22px;
      line-height: 22px;
      font-size: 22px; }
  .navigation__header__back:after {
    content: "";
    position: absolute;
    background-color: #0075af;
    height: 100%;
    width: 20px;
    top: 0;
    right: -6px;
    box-sizing: content-box;
    z-index: 0;
    transform: skewX(-7deg); }
  .navigation__header__back:active, .navigation__header__back:focus, .navigation__header__back:hover {
    background-color: #005d8b;
    color: #fff; }
    .navigation__header__back:active:after, .navigation__header__back:focus:after, .navigation__header__back:hover:after {
      background-color: #005d8b;
      color: #fff; }

.navigation__header__title {
  margin-left: 28px;
  padding: 0 0 0 60px;
  font-size: 1.3em;
  font-weight: 700;
  font-style: italic;
  text-transform: uppercase;
  color: #fff; }

.navigation__slider {
  height: 100%; }

.navigation__wrapper {
  height: calc(100% - 88px);
  position: relative; }
  @media (min-width: 801px) {
    .navigation__wrapper {
      height: calc(100vh - 15px); } }
  .navigation__wrapper .navigation__entry {
    position: relative;
    z-index: 2; }
    .navigation__wrapper .navigation__entry__list__item--highlighted {
      background: #fff;
      color: #0082c3; }
      .navigation__wrapper .navigation__entry__list__item--highlighted a {
        color: #0082c3; }
        .navigation__wrapper .navigation__entry__list__item--highlighted a:hover {
          color: #0089cd; }
      .navigation__wrapper .navigation__entry__list__item--highlighted:not(:first-child):after {
        content: "";
        position: absolute;
        top: 0;
        left: 10%;
        right: 10%;
        background: #0082c3;
        height: 1px;
        opacity: .5; }

.navigation__link {
  text-transform: uppercase; }
  .navigation__link span {
    display: table-cell;
    vertical-align: middle;
    color: #575859; }
  .navigation__link .icon-arrow1-r {
    text-align: center;
    color: #0082c3; }
    .navigation__link .icon-arrow1-r:before {
      width: 20px;
      font-size: .9em; }

.navigation__entry {
  display: none;
  z-index: 2;
  position: relative;
  height: 100%;
  position: relative; }
  .navigation__entry[data-entry=menu-all-sports] {
    height: calc(100% - 66px) !important; }

.navigation__entry--scroll {
  width: 100%;
  height: calc(100% + 15px);
  overflow-y: auto;
  padding-bottom: 100px; }
  .navigation__entry--scroll a img {
    transition: opacity .2s ease-in-out; }

.navigation__entry--wrapper {
  overflow: hidden;
  margin-bottom: 0; }

.navigation__entry--visible {
  display: flex;
  flex-wrap: wrap; }

.navigation__entry__list {
  margin: 0;
  padding: 0;
  font-size: 1em;
  font-weight: 700;
  background-color: #fff; }
  .navigation__entry__list label,
  .navigation__entry__list a {
    display: table;
    width: 100%;
    padding: 6px 20px 6px 30px;
    color: #0082c3;
    border-bottom: 1px solid #edeff1;
    transition: color .2s ease-in-out, background-color .2s ease-in-out; }
    .navigation__entry__list label:focus, .navigation__entry__list label:hover,
    .navigation__entry__list a:focus,
    .navigation__entry__list a:hover {
      color: #fff;
      background-color: #0082c3; }
    .navigation__entry__list label:focus .icon-arrow1-r, .navigation__entry__list label:focus span,
    .navigation__entry__list a:focus .icon-arrow1-r,
    .navigation__entry__list a:focus span {
      color: #fff; }
    .navigation__entry__list label:hover .icon-arrow1-r, .navigation__entry__list label:hover span,
    .navigation__entry__list a:hover .icon-arrow1-r,
    .navigation__entry__list a:hover span {
      color: #fff; }

.navigation__entry__list--default, .navigation__entry__list--sublinks {
  padding-left: 0; }

.navigation__entry__list--default li, .navigation__entry__list--sublinks li {
  position: relative; }

.navigation__entry__list--default li:before, .navigation__entry__list--sublinks li:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity .2s ease-in-out;
  opacity: 0;
  cursor: pointer; }

.navigation__entry__list--default li:not(:last-child):after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 10%;
  right: 10%;
  background: #fff;
  height: 1px;
  opacity: .5; }

.navigation__entry__list--default a, .navigation__entry__list--default label,
.navigation__entry__list--sublinks a,
.navigation__entry__list--sublinks label {
  font-family: Roboto-Condensed, Roboto, Arial, sans-serif;
  font-style: italic;
  position: relative;
  display: table;
  width: 100%;
  padding: 13px 40px 13px 40px;
  color: #fff;
  border: 1px solid transparent; }
  .navigation__entry__list--default a:hover, .navigation__entry__list--default label:hover,
  .navigation__entry__list--sublinks a:hover,
  .navigation__entry__list--sublinks label:hover {
    opacity: 0.75;
    color: #fff;
    text-decoration: none; }

.navigation__entry__list--default label,
.navigation__entry__list--sublinks label {
  padding-right: 75px; }

.navigation__entry__list--default input[type="checkbox"],
.navigation__entry__list--sublinks input[type="checkbox"] {
  display: inline; }
  .navigation__entry__list--default input[type="checkbox"] ~ .navigation__entry__sublist,
  .navigation__entry__list--sublinks input[type="checkbox"] ~ .navigation__entry__sublist {
    max-height: 0px;
    transition: max-height .2s ease-in-out;
    overflow: hidden; }
  .navigation__entry__list--default input[type="checkbox"]:checked ~ .navigation__entry__sublist,
  .navigation__entry__list--sublinks input[type="checkbox"]:checked ~ .navigation__entry__sublist {
    max-height: 600px; }
  .navigation__entry__list--default input[type="checkbox"]:checked + label .icon-arrow1-r,
  .navigation__entry__list--sublinks input[type="checkbox"]:checked + label .icon-arrow1-r {
    transform: rotate(90deg); }

.navigation__entry__list--default .icon-arrow1-r, .navigation__entry__list--sublinks .icon-arrow1-r {
  position: absolute;
  top: 50%;
  right: 45px;
  width: 20px;
  height: 20px;
  color: #fff;
  margin-top: -10px;
  transition: transform .2s ease-in-out; }

.navigation__entry__list--default .icon-arrow1-r:before, .navigation__entry__list--sublinks .icon-arrow1-r:before {
  position: static;
  width: auto;
  height: auto;
  padding-right: 40px;
  line-height: normal;
  text-align: right; }

.navigation__entry__list--default {
  margin: 0;
  padding-top: 10px; }
  .navigation__entry__list--default a, .navigation__entry__list--default label {
    font-weight: 700; }
    .navigation__entry__list--default a, .navigation__entry__list--default label {
      font-size: 14.4px; }
      @media screen and (min-width: 320px) {
        .navigation__entry__list--default a, .navigation__entry__list--default label {
          font-size: calc(14.4px + 1.6 * ((100vw - 320px) / 80)); } }
      @media screen and (min-width: 400px) {
        .navigation__entry__list--default a, .navigation__entry__list--default label {
          font-size: 16px; } }

.navigation__illustr {
  width: 70%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%; }
  @media (min-width: 480px) {
    .navigation__illustr {
      margin-top: 30px; } }

.navigation__entry__list--sublinks {
  margin-top: 22px;
  margin-bottom: 22px;
  padding: 0; }

@media (min-width: 801px) {
  .navigation__entry__list a {
    padding-left: 40px;
    padding-right: 40px; } }

.navigation__entry__sublist {
  background: #fff; }
  .navigation__entry__sublist li:not(:last-child):after {
    background-color: #0082c3; }
  .navigation__entry__sublist a, .navigation__entry__sublist label {
    color: #0082c3; }
    .navigation__entry__sublist a:hover, .navigation__entry__sublist label:hover {
      color: #0082c3; }
  .navigation__entry__sublist span {
    font-weight: 400; }

.navigation__entry__link {
  display: flex;
  align-items: center;
  width: 100%;
  text-transform: uppercase;
  border-bottom: 1px solid #edeff1;
  background-color: #edeff1;
  transition: background-color .2s;
  padding-right: 20px; }
  .navigation__entry__link:not([data-type=all-about]) {
    min-height: 55px; }
  .navigation__entry__link[data-type=all-about] {
    min-height: 100px; }
  .navigation__entry__link:focus, .navigation__entry__link:hover {
    background-color: #fff;
    color: #0082c3; }
  .navigation__entry__link:focus .icon-arrow1-r:before, .navigation__entry__link:hover .icon-arrow1-r:before {
    color: #0075af;
    transition: .2s ease-in-out; }
  .navigation__entry__link div, .navigation__entry__link span {
    display: table-cell;
    vertical-align: middle; }
  .navigation__entry__link > span {
    padding-left: 30px;
    color: #575859;
    flex-grow: 1; }
    .navigation__entry__link > span span {
      width: 100%;
      font-weight: 700;
      color: #0082c3; }
  .navigation__entry__link > div:first-child {
    min-width: 100px; }
    .navigation__entry__link > div:first-child img {
      width: 100%;
      height: auto; }
    .navigation__entry__link > div:first-child + span {
      padding-left: 10px; }
  .navigation__entry__link .icon-arrow1-r {
    width: 20px;
    text-align: center; }
    .navigation__entry__link .icon-arrow1-r:before {
      width: 20px;
      color: #d4d7d9; }

@media (min-width: 801px) {
  .navigation__entry__link {
    padding-right: 40px; }
    .navigation__entry__link[data-type=all-about] {
      min-height: 140px; }
    .navigation__entry__link > span {
      padding-left: 40px; }
    .navigation__entry__link > div:first-child {
      min-width: 120px; } }

.navigation__footer {
  position: absolute;
  bottom: -90px;
  left: 0;
  width: 100%;
  border-top: 1px solid #0082c3; }
  .navigation__footer > ul {
    display: flex; }

@media (min-width: 801px) {
  .navigation__footer {
    display: none; } }

.navigation__footer__entry {
  flex-grow: 1;
  flex-basis: 0;
  vertical-align: middle;
  height: 76px;
  text-align: center; }
  .navigation__footer__entry > a {
    position: relative;
    z-index: 1;
    display: inline-block;
    width: 100%;
    height: 76px;
    font-size: .7em;
    border-left: 1px solid #006ea5;
    color: #fff;
    background-color: #0075af; }
    .navigation__footer__entry > a .icon {
      display: block;
      margin: 10px auto 5px auto; }
    .navigation__footer__entry > a span {
      display: block;
      font-size: 0.6em;
      line-height: 1.3; }

.navigation__footer__lang {
  position: relative;
  bottom: 0;
  margin: 0;
  padding: 0;
  transition: bottom .2s ease-in-out; }
  .navigation__footer__lang li {
    width: 100%;
    height: 50px;
    border-top: 1px solid #0075af; }
  .navigation__footer__lang a {
    width: 100%;
    height: 100%;
    background-color: #0075af;
    border-bottom: 1px solid #006ea5;
    display: flex;
    align-items: center;
    justify-content: center; }
    .navigation__footer__lang a > span {
      color: #fff; }

.navigation__footer--lang {
  padding-top: 20px; }
  .navigation__footer--lang span {
    font-size: 1.3em; }
  .navigation__footer--lang .icon-arrow-up {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #fff; }

.navigation__footer i {
  margin: 10px auto 0; }
  .navigation__footer i:before {
    font-size: 30px; }

.navigation .associated-content__list {
  background: #edeff1; }
  .navigation .associated-content__list li {
    position: relative;
    padding-top: 36%; }
  .navigation .associated-content__list a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 7%;
    padding-bottom: 7%; }
  .navigation .associated-content__list img {
    width: 75%; }
  .navigation .associated-content__list span {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 11%; }

.associated-content {
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal; }

.associated-content__title {
  margin: 0;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  font-size: .8em;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #7d7e80; }

.associated-content__list {
  overflow: hidden;
  margin: 0;
  padding: 0; }
  .associated-content__list li {
    display: block;
    float: left;
    width: 33.3333%;
    text-align: center;
    box-sizing: border-box; }
  .associated-content__list a {
    display: block;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    transition: background-color .2s ease-in-out; }
    .associated-content__list a:focus, .associated-content__list a:hover {
      background-color: #fff; }
  .associated-content__list img {
    max-width: 100%;
    height: auto; }
  .associated-content__list span {
    display: block;
    overflow: hidden;
    padding-right: 6px;
    padding-left: 6px;
    font-size: .9em;
    text-transform: uppercase;
    color: #575859;
    text-overflow: ellipsis;
    white-space: nowrap; }

.navigation__entry--scroll {
  padding-bottom: 0px; }

i#icon-arrow-menu-all-sports {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 14px; }
  i#icon-arrow-menu-all-sports:before {
    line-height: 14px;
    font-size: 14px; }

.navigation__layout {
  display: none;
  position: fixed;
  top: 75px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9998;
  background-color: #fff;
  opacity: 0;
  transition: opacity .28s ease-in-out;
  cursor: pointer; }
  @media only screen and (min-width: 801px) {
    .navigation__layout.navigation__layout--active {
      display: block;
      opacity: .5; } }

@media only screen and (min-width: 480px) {
  .navigation--closed {
    animation-name: o;
    margin-left: 0;
    animation-duration: .2s; }
  .navigation--opened {
    animation-duration: .2s;
    animation-name: n;
    margin-left: 400px !important; } }

/*! CSS Used keyframes */
@keyframes n {
  0% {
    margin-left: 0 !important; }
  to {
    margin-left: 400px !important; } }

@keyframes o {
  0% {
    margin-left: 400px !important; }
  to {
    margin-left: 0 !important; } }

@keyframes k {
  0% {
    left: -100vw; }
  to {
    left: 0; } }

@keyframes p {
  0% {
    left: -400px; }
  to {
    left: 0; } }

.menu {
  display: none; }
  @media (min-width: 768px) {
    .menu {
      display: block; } }

#header .header-minicart {
  background: #ffea28;
  height: calc(100% + 20px);
  top: 0;
  padding-top: 10px;
  position: relative; }
  #header .header-minicart .picto,
  #header .header-minicart .icon {
    z-index: 3; }
  @media (min-width: 768px) {
    #header .header-minicart {
      padding-top: 5px; } }
  #header .header-minicart:after {
    content: "";
    position: absolute;
    background: #ffea28;
    height: 100%;
    width: 20px;
    top: 0;
    box-sizing: content-box;
    left: -10px;
    z-index: 0;
    transform: skewX(-7deg); }

#header .account-cart-wrapper {
  height: 100%; }

.checkout-cart-index .main-container {
  background: #F5F5F5; }
  @media (min-width: 600px) {
    .checkout-cart-index .main-container {
      background: transparent; } }

@media (min-width: 600px) {
  .checkout-cart-index .cart-totals-wrapper .cart-totals {
    position: relative;
    margin-bottom: 150px; }
  .checkout-cart-index .cart-totals-wrapper .checkout-types.bottom {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0; } }

.checkout-cart-index .cart .cart-totals table tfoot td {
  font-size: 18px; }

.checkout-cart-index #shopping-cart-totals-table {
  margin: 20px auto; }

.checkout-cart-index .cart .btn-qty {
  mix-blend-mode: multiply; }

.checkout-cart-index .cart .btn-card {
  font-size: 14px;
  padding: 15px 20px 15px 20px !important; }
  @media (min-width: 600px) {
    .checkout-cart-index .cart .btn-card:before {
      width: 2.2em; } }

.checkout-cart-index .cart .btn-back {
  padding: 15px 20px 15px 20px !important; }

.checkout-cart-index .cart .btn-back,
.checkout-cart-index .cart .btn-card {
  max-width: inherit;
  width: 100%; }

.checkout-cart-index .cart .cart-totals table tfoot {
  border: none; }

.checkout-cart-index .cart .cart-totals table tbody tr:last-child td, .checkout-cart-index .cart .cart-totals table tbody tr:last-child th {
  padding-bottom: 0; }

.checkout-cart-index .cart .cart-forms .discount,
.checkout-cart-index .cart .cart-forms .giftcard,
.checkout-cart-index .cart .cart-forms .shipping,
.checkout-cart-index .cart .cart-totals {
  background: transparent;
  border: 1px solid #e2e2e2;
  border-radius: 0; }

.checkout-cart-index .cart .title--in-movement p, .checkout-cart-index .cart .page-title.title-buttons p {
  text-transform: uppercase; }
  @media only screen and (max-width: 1025px) {
    .checkout-cart-index .cart .title--in-movement p, .checkout-cart-index .cart .page-title.title-buttons p {
      font-size: 13px; } }

.checkout-cart-index .cart .title--in-movement {
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 1em; }

.checkout-cart-index .cart .page-title.title-buttons {
  border: none;
  padding-top: 20px; }
  @media only screen and (min-width: 1025px) {
    .checkout-cart-index .cart .page-title.title-buttons {
      display: block; } }
  .checkout-cart-index .cart .page-title.title-buttons h1 {
    font-family: "Roboto Condensed Bold", sans-serif;
    font-style: italic; }
  .checkout-cart-index .cart .page-title.title-buttons + form {
    border-top: 1px solid #e2e2e2;
    padding-top: 10px; }
    @media (min-width: 600px) {
      .checkout-cart-index .cart .page-title.title-buttons + form {
        border: none; } }
  .checkout-cart-index .cart .page-title.title-buttons .checkout-types.top {
    display: none; }

.checkout-cart-index .cart .cart-table.data-table {
  border-bottom: 0; }

.checkout-cart-index .cart .cart-table thead th {
  background-color: #efefef;
  font-family: "Roboto Condensed Bold", sans-serif;
  font-style: italic;
  text-transform: uppercase;
  color: #898989; }

.checkout-cart-index .cart .cart-table tbody th, .checkout-cart-index .cart .cart-table tbody td {
  padding-top: 30px;
  padding-bottom: 30px; }

.checkout-cart-index .cart .cart-table tbody .btn-remove {
  top: 3px; }

.checkout-cart-index .cart .cart-table .product-name {
  text-transform: uppercase;
  font-family: "Roboto Condensed Bold", sans-serif; }
  .checkout-cart-index .cart .cart-table .product-name {
    font-size: 13.5px; }
    @media screen and (min-width: 320px) {
      .checkout-cart-index .cart .cart-table .product-name {
        font-size: calc(13.5px + 4.5 * ((100vw - 320px) / 704)); } }
    @media screen and (min-width: 1024px) {
      .checkout-cart-index .cart .cart-table .product-name {
        font-size: 18px; } }

.checkout-cart-index .cart .cart-table .product-cart-actions .qty {
  margin-bottom: 0; }
  @media (min-width: 600px) {
    .checkout-cart-index .cart .cart-table .product-cart-actions .qty {
      margin-bottom: 5px; } }

.checkout-cart-index .cart .cart-table .product-cart-actions .wrapper-qty {
  height: auto; }

.checkout-cart-index .cart .cart-table tr {
  border-width: 1px;
  border-color: #e2e2e2; }

.checkout-cart-index .cart .cart-totals h2 {
  padding: 15px 10px 25px;
  font-size: 18px;
  font-family: "Roboto Condensed Bold", sans-serif;
  font-style: italic; }

.checkout-cart-index .cart .cart-totals .checkout-types li {
  margin-bottom: 0;
  margin-top: 10px; }

.checkout-cart-index .cart .cart-totals .checkout-types .btn-checkout {
  margin-bottom: 0; }

.checkout-cart-index .cart .product-cart-price {
  font-family: "Roboto Condensed Bold", sans-serif;
  font-size: 18px; }

@media (max-width: 599px) {
  .checkout-cart-index .cart .cart-table .product-cart-info {
    padding-right: 115px; }
    .checkout-cart-index .cart .cart-table .product-cart-info .product-cart-price {
      display: none; }
  .checkout-cart-index .cart .cart-table .product-cart-actions {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 0;
    width: auto; }
  .checkout-cart-index .cart .cart-table .product-cart-remove {
    top: 0;
    transform: inherit; }
  .checkout-cart-index .cart .product-cart-total {
    top: 0;
    padding-right: 30px;
    padding-top: 7px; } }

@media (max-width: 599px) {
  .checkout-cart-index thead {
    display: none !important; } }

.checkout-cart-index .messages .success-msg {
  display: none; }

.checkout-cart-index .shipping-info {
  position: relative;
  z-index: 8; }
  .checkout-cart-index .shipping-info .cart-totals h2 {
    margin-bottom: 0;
    text-align: left;
    position: relative; }
    .checkout-cart-index .shipping-info .cart-totals h2 .help-icon {
      width: 24px;
      height: 24px;
      position: absolute;
      top: calc(50% - 5px);
      right: 40px;
      background: transparent url(../images/steps/step5/help.png) no-repeat center center;
      transform: translateY(-50%);
      display: block; }
      .checkout-cart-index .shipping-info .cart-totals h2 .help-icon .tooltip-text {
        position: absolute;
        bottom: calc(100% + 5px);
        background: rgba(0, 130, 195, 0.85);
        color: #fff;
        font-size: 12px;
        padding: 3px 7px;
        max-width: 200px;
        text-transform: none;
        display: none; }
      .checkout-cart-index .shipping-info .cart-totals h2 .help-icon:hover .tooltip-text {
        display: block; }
      @media (min-width: 600px) {
        .checkout-cart-index .shipping-info .cart-totals h2 .help-icon {
          display: block; } }
    @media (min-width: 600px) {
      .checkout-cart-index .shipping-info .cart-totals h2 {
        padding: 15px 40px 25px; } }
  .checkout-cart-index .shipping-info tr:not(:first-child) td {
    border-top: solid 1px #e2e2e2; }
  .checkout-cart-index .shipping-info .shipping-mention {
    text-transform: initial; }
  .checkout-cart-index .shipping-info .price,
  .checkout-cart-index .shipping-info strong {
    font-family: "Roboto Condensed Bold", sans-serif; }
  @media (min-width: 600px) {
    .checkout-cart-index .shipping-info {
      float: left;
      width: calc(100% - 350px); } }
  .checkout-cart-index .shipping-info .cart-totals {
    padding-bottom: 0; }
  .checkout-cart-index .shipping-info #shipping-totals-table {
    max-width: inherit;
    margin-left: -10px;
    margin-right: -10px;
    width: calc(100% + 20px); }
    .checkout-cart-index .shipping-info #shipping-totals-table td {
      padding: 20px; }
      @media (min-width: 600px) {
        .checkout-cart-index .shipping-info #shipping-totals-table td {
          padding: 30px 40px; } }
      .checkout-cart-index .shipping-info #shipping-totals-table td:first-child {
        padding-right: 0; }

.checkout-cart-index .reviews {
  margin-left: -20px;
  margin-right: -20px; }
  @media (min-width: 600px) {
    .checkout-cart-index .reviews:before {
      display: none; } }

@media (max-width: 599px) {
  .checkout-cart-index .cart .cart-forms, .checkout-cart-index .cart .cart-totals-wrapper, .checkout-cart-index .cart .crosssell {
    max-width: inherit; } }

.checkout-onepage-index .col-main,
.checkout-onepage-success .col-main {
  margin-top: 35px; }

.reviews {
  background: #fff;
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 40px; }
  @media (max-width: 1024px) {
    .reviews {
      position: relative;
      padding-top: calc(40px + 10%); }
      .reviews:before {
        content: "";
        top: 0;
        transform: skewY(-5deg) translateY(-50%);
        left: 0;
        right: 0;
        background: #F5F5F5;
        height: 7%;
        min-height: 32px;
        position: absolute; } }
  .reviews__header {
    padding-bottom: 40px; }
    .reviews__header--title {
      margin-top: 0;
      font-family: "Roboto Condensed Bold", sans-serif;
      font-weight: bold;
      font-style: italic;
      font-stretch: condensed;
      line-height: 1.14;
      letter-spacing: -0.4px;
      text-align: center;
      color: #ea6a24; }
      .reviews__header--title {
        font-size: 27px; }
        @media screen and (min-width: 320px) {
          .reviews__header--title {
            font-size: calc(27px + 9 * ((100vw - 320px) / 704)); } }
        @media screen and (min-width: 1024px) {
          .reviews__header--title {
            font-size: 36px; } }
      @media (min-width: 768px) {
        .reviews__header--title span {
          position: relative; }
          .reviews__header--title span:before, .reviews__header--title span:after {
            content: "";
            background: #ea6a24;
            width: 70px;
            height: 2px;
            position: absolute;
            top: 50%;
            margin-top: -1px; }
          .reviews__header--title span:before {
            right: calc(100% + 20px); }
          .reviews__header--title span:after {
            left: calc(100% + 20px); } }
  .reviews__list {
    counter-reset: reviewCounter;
    margin-left: auto;
    margin-right: auto;
    justify-content: center; }

@media (min-width: 320px) and (max-width: 1024px) {
  .reviews__list.flexie {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start; }
    .reviews__list.flexie .flexie__item--4.flexie__item--m25 {
      width: calc((100% / 2) - 20px);
      min-width: inherit;
      margin-left: 10px;
      margin-right: 10px;
      flex: inherit;
      max-width: inherit; } }
    .reviews__list__highlights {
      margin-bottom: 50px;
      display: none; }
      @media (min-width: 1025px) {
        .reviews__list__highlights {
          display: flex;
          justify-content: space-between; }
          .reviews__list__highlights .flexie {
            align-items: initial; }
          .reviews__list__highlights .reviews__list__highlights--title {
            width: 320px; }
            .reviews__list__highlights .reviews__list__highlights--title .title__inner span {
              display: block; }
          .reviews__list__highlights .card {
            display: flex;
            width: calc(100% - 370px); } }
      .reviews__list__highlights .reviews__list__highlights--title {
        margin-top: 60px;
        max-width: 320px;
        margin-left: auto;
        margin-right: auto; }
        .reviews__list__highlights .reviews__list__highlights--title .cta-v2 {
          display: block;
          margin-top: -15px;
          margin-bottom: 10px;
          max-width: 230px;
          margin-left: auto;
          margin-right: auto; }
    .reviews__list--item {
      max-width: 245px;
      min-width: 205px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 40px; }
      .reviews__list--item:before {
        counter-increment: reviewCounter;
        content: counter(reviewCounter) ".";
        font-family: "Roboto Condensed Bold", sans-serif;
        font-weight: bold;
        font-style: italic;
        font-stretch: condensed;
        line-height: 0.46;
        letter-spacing: -2.8px;
        text-align: center;
        color: #ea6a24;
        margin-right: 10px; }
        .reviews__list--item:before {
          font-size: 37.5px; }
          @media screen and (min-width: 320px) {
            .reviews__list--item:before {
              font-size: calc(37.5px + 12.5 * ((100vw - 320px) / 704)); } }
          @media screen and (min-width: 1024px) {
            .reviews__list--item:before {
              font-size: 50px; } }
    .reviews__list--icon {
      height: 40px; }
    .reviews__list--desc {
      font-family: "Roboto Condensed", sans-serif;
      font-weight: normal;
      font-style: italic;
      font-stretch: condensed;
      line-height: 1.37;
      letter-spacing: normal;
      text-align: left;
      color: #7b7b7b; }
      .reviews__list--desc {
        font-size: 11.25px; }
        @media screen and (min-width: 320px) {
          .reviews__list--desc {
            font-size: calc(11.25px + 3.75 * ((100vw - 320px) / 704)); } }
        @media screen and (min-width: 1024px) {
          .reviews__list--desc {
            font-size: 15px; } }
    .reviews__list--title {
      font-family: "Roboto Condensed Bold", sans-serif;
      font-weight: bold;
      font-style: italic;
      font-stretch: condensed;
      line-height: 1.23;
      letter-spacing: normal;
      text-align: left;
      margin-top: 10px;
      color: #0082c3; }
      .reviews__list--title {
        font-size: 13.5px; }
        @media screen and (min-width: 320px) {
          .reviews__list--title {
            font-size: calc(13.5px + 4.5 * ((100vw - 320px) / 704)); } }
        @media screen and (min-width: 1024px) {
          .reviews__list--title {
            font-size: 18px; } }

.cms-index-index .global-reviews__rating__rating, .checkout-cart-index .global-reviews__rating__rating {
  padding-top: 10px; }
  .cms-index-index .global-reviews__rating__rating-wrapper, .cms-index-index .global-reviews__rating__rating .global-reviews__title, .checkout-cart-index .global-reviews__rating__rating-wrapper, .checkout-cart-index .global-reviews__rating__rating .global-reviews__title {
    margin: auto; }
  .cms-index-index .global-reviews__rating__rating .global-reviews__title, .checkout-cart-index .global-reviews__rating__rating .global-reviews__title {
    padding-top: 10px; }

.card {
  padding-top: 70px; }
  .card__item {
    background: #0082c3;
    color: #fff;
    border-radius: 10px;
    padding: 110px 20px 20px 20px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column; }
    .card__item__body {
      padding-bottom: 25px;
      font-size: 16px;
      font-style: italic;
      font-family: "Roboto Condensed", sans-serif; }
    .card__item__footer {
      position: relative;
      margin-top: auto;
      padding-top: 30px; }
      .card__item__footer:before {
        content: "";
        position: absolute;
        top: 0;
        height: 1px;
        left: 10%;
        right: 10%;
        background-color: #0075af;
        transform: rotate(-10deg); }
    .card__item__author {
      font-family: "Roboto Condensed Bold", sans-serif;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: condensed;
      line-height: 1.11;
      letter-spacing: normal;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff; }
    .card__item__date {
      margin-top: 16px;
      border-radius: 10px;
      background-color: #0075af;
      font-family: "Roboto Condensed Bold", sans-serif;
      font-size: 10px;
      font-weight: normal;
      font-style: normal;
      font-stretch: condensed;
      line-height: 1.73;
      letter-spacing: 4.8px;
      color: #ffffff; }
    .card__item__note {
      background: #fff;
      width: 127px;
      height: 127px;
      box-shadow: 0px 5px 3.5px 0.5px rgba(105, 105, 105, 0.25);
      background-color: #ffffff;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #0082c3; }
      .card__item__note--number {
        font-family: "Roboto Condensed Bold", sans-serif;
        font-size: 38px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        font-weight: bold;
        font-style: italic;
        font-stretch: condensed;
        line-height: 0.53;
        letter-spacing: normal;
        text-align: center;
        color: #0082c3; }
        .card__item__note--number .sep {
          font-weight: normal;
          font-family: "Roboto Condensed", sans-serif; }
      .card__item__note .global-reviews__stars {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 95px;
        margin: 10px auto 0; }

.steps {
  overflow: hidden;
  padding-bottom: 10px; }
  .steps img {
    max-width: 100%; }

.step__header {
  width: 100%; }
  @media (min-width: 1025px) {
    .step__header-wrapper {
      background-image: linear-gradient(90deg, #0182B8 51%, #42B6C8 99%);
      overflow: hidden;
      position: relative;
      margin-bottom: -4%;
      padding-left: 15px;
      padding-right: 15px; }
      .step__header-wrapper:before {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background: #F5F5F5;
        height: 100%;
        transform: skewY(-7deg);
        transform-origin: 0 50%; }
      .step__header-wrapper img {
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto; }
        .step__header-wrapper img.visible-desktop {
          min-height: 400px;
          max-height: calc(60vh - 50px);
          width: auto; } }

.step__header-nav {
  background-color: #fff;
  display: none;
  z-index: 20;
  position: relative;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc; }
  @media (min-width: 1025px) {
    .step__header-nav {
      display: block; }
      .step__header-nav ul {
        display: flex;
        margin: 0 -10px;
        overflow: hidden; }
        .step__header-nav ul .step__header-nav__item {
          flex: 1;
          text-align: center;
          position: relative; }
          .step__header-nav ul .step__header-nav__item:not(:last-child):after {
            content: "";
            position: absolute;
            height: 25px;
            width: 1px;
            border-right: 1px solid #ccc;
            right: 0;
            top: 50%;
            transform: translateY(-50%); }
          .step__header-nav ul .step__header-nav__item a {
            display: block;
            color: #29333f;
            padding: 13px 10px;
            text-transform: uppercase;
            font-family: "Roboto Condensed Bold", sans-serif; }
        .step__header-nav ul .step__header-nav__item--active a {
          color: #0082c3; } }

.step__form {
  position: relative;
  z-index: 3; }

@media (min-width: 768px) {
  .step__body .btn-back {
    position: absolute;
    top: .7%; } }

@media (min-width: 1025px) {
  .step__progress {
    color: #0082c3; } }

.step__progress__inner {
  display: flex;
  padding: 10px 10px;
  flex-wrap: wrap; }
  @media (min-width: 1025px) {
    .step__progress__inner {
      padding-top: 20px; } }
  .step__progress__inner .btn-back {
    order: 2;
    margin-right: auto; }
  .step__progress__inner .btn-next {
    order: 3;
    margin-left: auto;
    min-width: 160px; }
  .step__progress__inner ol {
    width: 100%;
    justify-content: center;
    margin-bottom: 20px; }
  @media (min-width: 1025px) {
    .step__progress__inner {
      padding: 20px 30px 30px 30px;
      align-items: center; }
      .step__progress__inner ol {
        width: auto;
        margin-bottom: 0; }
      .step__progress__inner .btn-back {
        order: -1; }
      .step__progress__inner .btn-next {
        order: 3; } }

.step__progress ol {
  display: flex;
  margin-left: auto;
  margin-right: auto; }
  .step__progress ol .step__item {
    margin: 0 6px;
    text-transform: uppercase;
    text-indent: -9999px;
    width: 10px;
    height: 10px;
    background-color: #cbcdcf;
    border-radius: 50%; }
    @media (min-width: 1025px) {
      .step__progress ol .step__item {
        margin: 0 20px;
        border-radius: 0;
        text-indent: inherit;
        padding: 10px 0;
        width: auto;
        height: auto;
        background-color: transparent; } }
    .step__progress ol .step__item--current {
      font-family: "Roboto Condensed Bold", sans-serif;
      background-color: #ffea28; }
      @media (min-width: 1025px) {
        .step__progress ol .step__item--current {
          background-color: transparent;
          border-bottom: 3px solid #0082c3; } }
    .step__progress ol .step__item--active {
      font-family: "Roboto Condensed Bold", sans-serif;
      background-color: #0082c3;
      cursor: pointer; }
      @media (min-width: 1025px) {
        .step__progress ol .step__item--active {
          background-color: transparent;
          border: none; } }
    .step__progress ol .step__item:not(:last-child) {
      position: relative; }
      @media (min-width: 1025px) {
        .step__progress ol .step__item:not(:last-child) {
          margin-right: 40px; } }
      .step__progress ol .step__item:not(:last-child):after {
        content: "\E007";
        font-family: "Decathlon Cube";
        right: -40px;
        top: 0;
        height: 100%;
        width: 2.2em;
        font-size: 1em;
        display: flex;
        position: absolute;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        line-height: 1;
        z-index: 1; }

.card-value {
  text-align: center;
  font-family: "Roboto Condensed Bold", sans-serif;
  font-weight: bold;
  font-style: italic;
  font-stretch: condensed;
  line-height: 2.33;
  letter-spacing: -0.2px;
  text-align: center;
  color: #000000;
  text-transform: uppercase; }
  .card-value {
    font-size: 15.75px; }
    @media screen and (min-width: 320px) {
      .card-value {
        font-size: calc(15.75px + 5.25 * ((100vw - 320px) / 704)); } }
    @media screen and (min-width: 1024px) {
      .card-value {
        font-size: 21px; } }
  @media (min-width: 1025px) {
    .card-value {
      padding-top: 0;
      border-top: none; } }

.step2 button {
  position: relative; }

.step2 .btn-card-mentions {
  position: absolute;
  left: 30%; }
  @media (max-width: 767px) {
    .step2 .btn-card-mentions {
      position: relative;
      margin: auto;
      left: -3%; } }

.step2 .flexie {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 0;
  padding-top: 10px; }
  .step2 .flexie.form--large {
    max-width: 960px; }
  @media (min-width: 1025px) {
    .step2 .flexie {
      padding-top: 20px; } }
  @media (max-width: 1024px) {
    .step2 .flexie .btn-card {
      max-width: 190px;
      margin-top: 0px !important;
      min-width: inherit; } }
  .step2 .flexie .choice-image {
    max-width: 100%;
    position: relative; }
    @media (min-width: 1025px) {
      .step2 .flexie .choice-image {
        max-width: 63%;
        left: 0; } }
  @media (min-width: 1025px) {
    .step2 .flexie {
      border-bottom: 0;
      margin-bottom: 0; }
      .step2 .flexie .choice-image {
        max-width: 100%; } }
  .step2 .flexie > div {
    margin-bottom: 20px; }
    @media (min-width: 1025px) {
      .step2 .flexie > div {
        margin-bottom: 30px; } }

.step2 button:not(.btn-back) {
  border: none;
  background: transparent;
  appearance: none; }
  .step2 button:not(.btn-back) .btn-card {
    padding-right: 2.2em !important;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    left: -3%; }
    .step2 button:not(.btn-back) .btn-card {
      font-size: 12px; }
      @media screen and (min-width: 320px) {
        .step2 button:not(.btn-back) .btn-card {
          font-size: calc(12px + 4 * ((100vw - 320px) / 704)); } }
      @media screen and (min-width: 1024px) {
        .step2 button:not(.btn-back) .btn-card {
          font-size: 16px; } }
    @media (min-width: 1025px) {
      .step2 button:not(.btn-back) .btn-card:before {
        width: 2.2em; } }

.greetings-container {
  background-color: #F5F5F5; }
  .greetings-container .step__body {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto; }
  .greetings-container .title--in-movement {
    margin-bottom: -5px;
    padding-top: 10px; }
    .greetings-container .title--in-movement h1 {
      padding: 10px 15px 20px 15px;
      font-family: "Roboto Condensed Bold", sans-serif; }
      .greetings-container .title--in-movement h1 {
        font-size: 21px; }
        @media screen and (min-width: 320px) {
          .greetings-container .title--in-movement h1 {
            font-size: calc(21px + 9 * ((100vw - 320px) / 704)); } }
        @media screen and (min-width: 1024px) {
          .greetings-container .title--in-movement h1 {
            font-size: 30px; } }
  .greetings-container + .greetings-container {
    margin-top: 40px; }

.card__preview {
  position: relative;
  perspective: 700;
  max-width: calc(100% - 20px);
  margin: 30px auto 20px; }
  .card__preview:before, .card__preview:after {
    content: "";
    position: absolute;
    z-index: -2; }
  .card__preview:before {
    left: 80px;
    bottom: 8px;
    width: 50%;
    height: 15%;
    max-width: 200px;
    box-shadow: -80px 5px 8px rgba(0, 0, 0, 0.2);
    transform: skew(50deg);
    transform-origin: 0 100%; }
  .card__preview:after {
    right: 80px;
    bottom: 8px;
    width: 50%;
    height: 15%;
    max-width: 200px;
    box-shadow: 80px 5px 8px rgba(0, 0, 0, 0.2);
    transform: skew(-50deg);
    transform-origin: 0 100%; }
  .card__preview .card__preview__back {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(to right, #dbdbdb, #eaeaea);
    bottom: 0;
    backface-visibility: visible;
    transition: all 0.5s ease-out;
    transform-origin: 0 0;
    transform: skewY(-5deg) scaleX(0.9);
    z-index: 2; }
  .card__preview .card__preview__content {
    position: relative;
    z-index: 3; }
    @media (min-width: 1025px) {
      .card__preview .card__preview__content:not(.no-customize):before {
        content: "";
        bottom: 100%;
        left: 10%;
        width: 28%;
        padding: 4%;
        background: transparent url(../images/card/bit.png) no-repeat top left;
        background-size: cover;
        position: absolute; } }

.text-or {
  display: none;
  margin: 10px auto 10px auto;
  text-align: center; }
  @media (min-width: 1025px) {
    .text-or {
      display: block; } }
  .text-or span {
    display: inline-block;
    position: relative;
    color: #0082c3;
    font-weight: bold;
    font-size: 18px;
    width: 30px; }
    .text-or span:before, .text-or span:after {
      content: "";
      background: #c6c8cb;
      width: 130px;
      height: 1px;
      position: absolute;
      top: 50%;
      margin-top: -1px; }
    .text-or span:before {
      right: calc(100% + 20px); }
    .text-or span:after {
      left: calc(100% + 20px); }

.card__preview__content {
  border: solid 1px #c6c8cb;
  background: #fff;
  padding: 6%;
  display: flex;
  width: 100%; }
  @media (min-width: 1025px) {
    .card__preview__content {
      padding: 6%;
      background: #fff url(../images/card/icon-photo.svg) no-repeat center center; } }

.card__preview__content__inner {
  border: solid 1px #c6c8cb;
  width: 100%;
  position: relative;
  padding-bottom: 70.04347826086957%; }
  .card__preview__content__inner .box {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 230px;
    transform: translate(-50%, -50%); }
    .card__preview__content__inner .box .btn-default, .card__preview__content__inner .box .btn-primary {
      white-space: nowrap; }
    .card__preview__content__inner .box .btn-default {
      margin-bottom: 10px; }

.card__preview_background__slider.slick-slider .slick-next:before,
.card__preview_background__slider.slick-slider .slick-prev:before {
  content: "";
  font-family: inherit;
  font-size: inherit;
  width: 0;
  height: 0;
  border-style: solid; }

.card__preview_background__slider.slick-slider .slick-prev {
  left: -30px; }
  .card__preview_background__slider.slick-slider .slick-prev:before {
    border-width: 12.5px 7px 12.5px 0;
    border-color: transparent #d7d7d7 transparent transparent; }

.card__preview_background__slider.slick-slider .slick-next {
  right: -30px; }
  .card__preview_background__slider.slick-slider .slick-next:before {
    border-width: 12.5px 0 12.5px 7px;
    border-color: transparent transparent transparent #d7d7d7; }

.card__preview_background__slider .card__preview_background__item {
  padding-left: 3px;
  cursor: pointer;
  padding-right: 3px; }
  .card__preview_background__slider .card__preview_background__item img {
    display: block;
    border: 2px solid #e2e2e2; }
    .card__preview_background__slider .card__preview_background__item img:hover {
      border-color: #dadada; }
  .card__preview_background__slider .card__preview_background__item--active {
    position: relative; }
    .card__preview_background__slider .card__preview_background__item--active img {
      border-color: #0082c3; }
      .card__preview_background__slider .card__preview_background__item--active img:hover {
        border-color: #0082c3; }

.card__preview_background {
  margin-top: 20px;
  width: calc(100% - 20px);
  max-width: 480px;
  padding: 0px 40px 10px;
  margin: 20px auto 10px auto;
  position: relative;
  z-index: 4; }
  @media (min-width: 1025px) {
    .card__preview_background {
      padding: 10px 40px 15px;
      border-radius: 5px;
      box-shadow: 0px 3px 7px 0 rgba(0, 0, 0, 0.1);
      margin-bottom: -40px;
      background-color: #ffffff;
      margin-top: -0.5%;
      max-width: 370px; } }
  .card__preview_background .card__preview_background__title {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1.59;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 8px; }
  @media (min-width: 1025px) {
    .card__preview_background {
      margin-left: 30px; } }

.cat-tabs {
  overflow: hidden;
  margin: 5px 0; }
  .cat-tabs label {
    cursor: pointer; }
  .cat-tabs__inner {
    margin: 0 -10px;
    display: flex;
    width: calc(100% + 20px); }
  .cat-tabs__item {
    transform: skewX(-5deg);
    flex: 1;
    text-align: center;
    box-shadow: 0 0 4.4px 0.7px rgba(0, 0, 0, 0.2); }
    .cat-tabs__item span {
      display: block;
      padding: 7px 10px;
      transform: skewX(5deg);
      text-transform: uppercase;
      font-family: "Roboto Condensed Bold", sans-serif; }

.sport-select,
.event-select {
  display: none; }

#choice-event:checked {
  background: red; }
  #choice-event:checked ~ .cat-tabs label[for="choice-event"] {
    background: #0082c3;
    color: #fff; }
  #choice-event:checked ~ .event-select {
    display: block; }

#choice-sport:checked {
  background: red; }
  #choice-sport:checked ~ .cat-tabs label[for="choice-sport"] {
    background: #0082c3;
    color: #fff; }
  #choice-sport:checked ~ .sport-select {
    display: block; }

.desktop-flexie {
  position: relative;
  z-index: 3; }
  @media (min-width: 1025px) {
    .desktop-flexie {
      z-index: 5; } }
  .desktop-flexie .wrapper-function {
    padding: 0 15px; }
    .desktop-flexie .wrapper-function .btn-primary {
      width: 100%;
      display: flex; }
      .desktop-flexie .wrapper-function .btn-primary span {
        margin-left: auto;
        margin-right: auto; }
      .desktop-flexie .wrapper-function .btn-primary:before {
        content: "";
        display: inline-block;
        width: 15px;
        height: 20px;
        margin-left: 5px;
        background: transparent url(../images/steps/step3/download-icon.svg) no-repeat center center;
        background-size: cover;
        transform: inherit;
        position: relative;
        top: auto;
        left: auto; }
  .desktop-flexie .cat-tabs-container {
    margin-left: -20px;
    margin-right: -20px;
    padding: 5px 20px 0px 20px;
    min-height: 240px; }
  .desktop-flexie .grid-img {
    margin: 10px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: auto; }
    @media (min-width: 1025px) {
      .desktop-flexie .grid-img {
        max-height: 190px; } }
    @media (max-width: 1024px) {
      .desktop-flexie .grid-img {
        height: 65vh;
        overflow-y: scroll; } }
    .desktop-flexie .grid-img__item {
      margin: 2px 0; }
      .desktop-flexie .grid-img__item--hidden {
        display: none; }
      .desktop-flexie .grid-img__item img {
        display: block;
        border: 2px solid #fff; }
      .desktop-flexie .grid-img__item--active {
        position: relative; }
        .desktop-flexie .grid-img__item--active img {
          border-color: #0082c3; }
    .desktop-flexie .grid-img .grid-img__item {
      width: calc(50% - 2.5px);
      cursor: pointer; }
      .desktop-flexie .grid-img .grid-img__item img {
        width: 100%; }
  .desktop-flexie .grid-img__column {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  @media (min-width: 1025px) {
    .desktop-flexie {
      margin-left: 30px;
      margin-right: 30px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .desktop-flexie .grid-img {
        margin: 10px auto;
        justify-content: space-around; }
        .desktop-flexie .grid-img .grid-img__item {
          width: 32%; }
      .desktop-flexie .cat-tabs-container {
        margin-top: 0px; }
      .desktop-flexie .wrapper-function {
        border-radius: 5px;
        box-shadow: 0px 3px 7px 0 rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
        padding: 10px 20px 15px;
        margin-top: 20px; }
      .desktop-flexie .component-desktop {
        width: 370px;
        margin-top: -80px; }
      .desktop-flexie .card__outer_wrapper {
        width: calc(98% - 370px);
        padding-top: 3%; }
        .desktop-flexie .card__outer_wrapper .card__preview {
          max-width: 450px;
          margin-left: 15%; } }

#step3 .desktop-flexie .card__outer_wrapper .card__preview {
  max-width: 430px; }
  @media (min-width: 1025px) {
    #step3 .desktop-flexie .card__outer_wrapper .card__preview {
      margin-left: 22%; } }

.title-gallery {
  color: #0082c3;
  font-size: 18px;
  text-align: center;
  font-family: "Roboto Condensed Bold", sans-serif; }

.component-open {
  overflow: hidden; }
  .component-open .wrapper {
    min-height: inherit; }
  .component-open .col-main {
    margin-bottom: 0 !important; }
  .component-open .block-footer,
  .component-open .reviews {
    display: none; }

#step1 .title--in-movement {
  padding: 0; }

#step1 input[type=text], #step1 input[type=number] {
  border: none; }

@media (max-width: 767px) {
  #step1 input[type=number] {
    padding: 11px 17px 0 0; } }

#step1 .title--in-movement span, #step2 .title--in-movement span {
  display: block; }

.help-arrow {
  width: 150px;
  position: absolute;
  right: 8%;
  top: 12%;
  z-index: 10; }
  @media (max-width: 1199px) {
    .help-arrow {
      width: 100px;
      top: 15%; } }
  @media (max-width: 1025px) {
    .help-arrow {
      display: none; } }

.help-icon {
  width: 95px;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 25px;
  z-index: 10; }
  @media (max-width: 768px) {
    .help-icon {
      width: 65px;
      right: 10px; } }
  @media (max-width: 479px) {
    .help-icon {
      width: 52px;
      right: 15px; } }

@media (min-width: 1025px) {
  #step3 .title--in-movement {
    padding-left: 425px;
    text-align: center;
    top: 20px;
    position: relative; }
    #step3 .title--in-movement h1 {
      text-align: center;
      display: inline-block;
      max-width: 70%; } }

#step3 .step__body {
  position: relative; }

#step3 .card__outer_wrapper {
  max-width: 675px;
  margin-left: 10px;
  margin-right: 10px; }
  @media (min-width: 768px) {
    #step3 .card__outer_wrapper {
      margin-left: auto;
      margin-right: auto; } }

@media (min-width: 1025px) {
  #step3 .card-value {
    margin-left: 400px;
    text-align: left;
    padding-left: 23%;
    margin-top: 50px;
    line-height: 1; } }

.img-notif {
  max-width: 350px;
  border-radius: 30px;
  background-color: #00e381;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  text-align: center;
  padding: 10px;
  margin: -20px 15px 0 15px; }
  .img-notif.too-bad {
    background-color: #e34222; }
  @media (min-width: 1025px) {
    .img-notif {
      margin-left: 28%;
      margin-top: 0;
      margin-bottom: -40px; } }

.smiley {
  position: relative;
  vertical-align: middle;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-radius: 20px;
  margin-right: 10px; }
  .smiley:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 50%;
    width: 7px;
    height: 2px;
    transform: translateX(-50%);
    border-left: 2px solid #fff;
    border-right: 2px solid #fff; }
  .smiley:after {
    content: "";
    position: absolute;
    bottom: 2.5px;
    left: 50%;
    width: 10px;
    height: 4px;
    transform: translateX(-50%);
    border-bottom: 1.5px solid #fff;
    border-left: 1.5px solid #fff;
    border-right: 1.5px solid #fff;
    border-radius: 0 0 5px 5px; }
  .smiley--sad:after {
    bottom: 5px;
    height: 2px;
    border-right: 0;
    border-left: 0;
    border-radius: 3px; }

.card__preview__content__inner .clear-button {
  width: 25px;
  height: 25px;
  background: #fff url(../images/steps/step5/x.svg) no-repeat center center;
  background-size: 15px 15px;
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
  border: 1px solid #0082c3;
  border-radius: 50%;
  display: none; }

.card__preview__content__inner[style*='background'] {
  border: none; }
  .card__preview__content__inner[style*='background'] .box {
    display: none; }
  .card__preview__content__inner[style*='background'] .clear-button {
    display: block; }
    @media (min-width: 1025px) {
      .card__preview__content__inner[style*='background'] .clear-button {
        display: none; } }

.card__preview__content.no-customize {
  background-image: linear-gradient(145deg, #F0EFED 0%, #EFEFEB 91%); }

.card__outer_wrapper .mentions {
  max-width: calc(100% - 20px);
  margin: 0px auto; }
  @media (min-width: 1025px) {
    .card__outer_wrapper .mentions {
      margin-left: 250px;
      margin-top: -15px; } }

.card__preview__content__inner {
  background-position: center center;
  background-repeat: no-repeat; }
  .card__preview__content__inner.no-customize {
    padding-bottom: 0;
    border: none;
    min-height: 197px;
    display: flex;
    width: 100%;
    justify-content: center; }
    .card__preview__content__inner.no-customize .box {
      position: relative;
      top: 0;
      left: 0;
      transform: inherit;
      color: #0082c3;
      font-family: "Open Sans Condensed", sans-serif; }
      .card__preview__content__inner.no-customize .box input[type=email], .card__preview__content__inner.no-customize .box input[type=search], .card__preview__content__inner.no-customize .box input[type=number], .card__preview__content__inner.no-customize .box input[type=password], .card__preview__content__inner.no-customize .box input[type=tel], .card__preview__content__inner.no-customize .box input[type=text] {
        width: auto;
        flex-grow: 1;
        max-width: inherit;
        padding: 1vw 0;
        height: auto;
        text-indent: 5px; }
        @media (min-width: 560px) {
          .card__preview__content__inner.no-customize .box input[type=email], .card__preview__content__inner.no-customize .box input[type=search], .card__preview__content__inner.no-customize .box input[type=number], .card__preview__content__inner.no-customize .box input[type=password], .card__preview__content__inner.no-customize .box input[type=tel], .card__preview__content__inner.no-customize .box input[type=text] {
            padding: 0; } }
      .card__preview__content__inner.no-customize .box label, .card__preview__content__inner.no-customize .box input, .card__preview__content__inner.no-customize .box textarea, .card__preview__content__inner.no-customize .box .textarea {
        font-family: inherit;
        padding: 1%; }
        .card__preview__content__inner.no-customize .box label, .card__preview__content__inner.no-customize .box input, .card__preview__content__inner.no-customize .box textarea, .card__preview__content__inner.no-customize .box .textarea {
          font-size: 11.9px; }
          @media screen and (min-width: 320px) {
            .card__preview__content__inner.no-customize .box label, .card__preview__content__inner.no-customize .box input, .card__preview__content__inner.no-customize .box textarea, .card__preview__content__inner.no-customize .box .textarea {
              font-size: calc(11.9px + 2.1 * ((100vw - 320px) / 704)); } }
          @media screen and (min-width: 1024px) {
            .card__preview__content__inner.no-customize .box label, .card__preview__content__inner.no-customize .box input, .card__preview__content__inner.no-customize .box textarea, .card__preview__content__inner.no-customize .box .textarea {
              font-size: 14px; } }
      .card__preview__content__inner.no-customize .box .text__left {
        font-family: 'Arial', sans-serif;
        font-size: 2.5vw;
        display: block;
        text-align: right;
        color: #5c5c5c;
        margin-top: 1.5vw; }
        @media (min-width: 560px) {
          .card__preview__content__inner.no-customize .box .text__left {
            font-size: 13px;
            margin-top: 10px; } }
      .card__preview__content__inner.no-customize .box__row {
        display: inline-flex;
        align-items: center; }
        .card__preview__content__inner.no-customize .box__row:not(:first-child) {
          margin-top: 2vw; }
        .card__preview__content__inner.no-customize .box__row--full {
          margin-top: 2vw; }
        @media (min-width: 560px) {
          .card__preview__content__inner.no-customize .box__row:not(:first-child) {
            margin-top: 7px; }
          .card__preview__content__inner.no-customize .box__row--full {
            margin-top: 5px; }
            .card__preview__content__inner.no-customize .box__row--full label {
              padding-bottom: 5px; } }
      .card__preview__content__inner.no-customize .box__row, .card__preview__content__inner.no-customize .box__row--full {
        width: 100%; }
        .card__preview__content__inner.no-customize .box__row label, .card__preview__content__inner.no-customize .box__row--full label {
          padding-right: 10px;
          white-space: nowrap; }
        .card__preview__content__inner.no-customize .box__row input, .card__preview__content__inner.no-customize .box__row textarea, .card__preview__content__inner.no-customize .box__row .textarea, .card__preview__content__inner.no-customize .box__row--full input, .card__preview__content__inner.no-customize .box__row--full textarea, .card__preview__content__inner.no-customize .box__row--full .textarea {
          background: transparent;
          border: none;
          color: currentColor;
          font-family: inherit; }
        .card__preview__content__inner.no-customize .box__row input, .card__preview__content__inner.no-customize .box__row--full input {
          background: transparent;
          border: none;
          border-bottom: 1px solid #b5b7b9; }
        .card__preview__content__inner.no-customize .box__row textarea, .card__preview__content__inner.no-customize .box__row .textarea, .card__preview__content__inner.no-customize .box__row--full textarea, .card__preview__content__inner.no-customize .box__row--full .textarea {
          background: #ECEBE8;
          border: 1px solid #ECEBE8;
          height: 30vw;
          width: 100%;
          max-width: inherit;
          resize: none; }
          .card__preview__content__inner.no-customize .box__row textarea:focus::placeholder, .card__preview__content__inner.no-customize .box__row .textarea:focus::placeholder, .card__preview__content__inner.no-customize .box__row--full textarea:focus::placeholder, .card__preview__content__inner.no-customize .box__row--full .textarea:focus::placeholder {
            color: transparent; }
          @media (min-width: 560px) {
            .card__preview__content__inner.no-customize .box__row textarea, .card__preview__content__inner.no-customize .box__row .textarea, .card__preview__content__inner.no-customize .box__row--full textarea, .card__preview__content__inner.no-customize .box__row--full .textarea {
              height: 145px; } }

#step4 .title--in-movement span {
  display: block;
  margin-bottom: 5%; }

#step4 .step__body {
  position: relative; }

#step4 .card__preview .card__preview__back {
  z-index: 6;
  left: 100%;
  width: 100%;
  transform: skewY(-5deg) scaleX(0.9); }
  #step4 .card__preview .card__preview__back .visible-desktop {
    height: 100%; }
    #step4 .card__preview .card__preview__back .visible-desktop .card__preview__back__item {
      height: 100%;
      width: 100%;
      object-fit: cover; }
  @media (min-width: 1025px) {
    #step4 .card__preview .card__preview__back {
      transform: perspective(50px) rotateY(-1deg) scaleX(0.75);
      box-shadow: 80px 50px 80px rgba(0, 0, 0, 0.3); } }

body:not(.overlay--opened) .overlayer {
  display: none; }

.overlay--opened {
  position: relative; }
  .overlay--opened:before {
    content: "";
    background: #000;
    opacity: .8;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100; }
  .overlay--opened .close-icon {
    width: 19px;
    height: 19px;
    z-index: 9999;
    background: transparent url(../images/steps/step5/x.svg) no-repeat center center;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer; }
  .overlay--opened .alert,
  .overlay--opened .contact-submit,
  .overlay--opened .mentions {
    margin-left: 10px;
    margin-right: 10px; }
  .overlay--opened #step3 .overlayer.form {
    padding: 2% 3% 2%; }
  .overlay--opened .overlayer.form {
    position: fixed;
    text-align: center;
    background: #fff;
    border-radius: 5px;
    top: 50%;
    left: 50%;
    z-index: 100;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 790px;
    padding: 0 3% 2%; }
    .overlay--opened .overlayer.form .alert {
      width: auto;
      padding: 15px;
      font-size: 18px; }
    .overlay--opened .overlayer.form .btn-card {
      width: 100%;
      max-width: inherit; }
      .overlay--opened .overlayer.form .btn-card:before {
        width: 2.2em; }
  .overlay--opened .step__body .step__pre-content {
    display: none; }
    @media (min-width: 768px) {
      .overlay--opened .step__body .step__pre-content {
        display: block; } }
  @media (max-width: 767px) {
    .overlay--opened .overlayer .contact-submit {
      flex-direction: column; }
      .overlay--opened .overlayer .contact-submit button:first-child {
        margin-bottom: 10px; } }

@media (min-width: 560px) {
  .card-editor-wrapper .card__preview {
    max-width: 450px; } }

@media (min-width: 1025px) {
  .card-editor-wrapper .card__preview {
    max-width: 450px;
    margin-left: 250px; } }

.colorpicker {
  display: flex;
  margin: 10px auto;
  max-width: 280px;
  justify-content: space-around; }
  @media (min-width: 1025px) {
    .colorpicker {
      position: absolute;
      flex-direction: column;
      align-items: center;
      left: 3%;
      top: 0;
      padding: 20px 0;
      width: 86.45px;
      height: 350px;
      border-radius: 5px;
      box-shadow: 0px 3px 7px 0 rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      top: 170px; }
      .colorpicker__item {
        margin-bottom: 5px; } }
  .colorpicker__item {
    width: 23.8px;
    height: 23.8px;
    border-radius: 50%;
    cursor: pointer; }
    .colorpicker__item--active {
      border: 3px solid #fff;
      box-shadow: 0 0 1px #0082c3, 0 0 1px #0082c3, 0 0 1px #0082c3, 0 0 1px #0082c3; }

.fontpicker {
  display: flex;
  margin: 20px auto;
  max-width: 280px;
  justify-content: space-around;
  font-size: 30px;
  color: #0082c3; }
  @media (min-width: 1025px) {
    .fontpicker {
      margin-top: 0; } }
  .fontpicker__item {
    cursor: pointer; }
    .fontpicker__item[data-font="'Chapaza Regular', serif"] {
      line-height: 1.8; }
    .fontpicker__item--active {
      border-bottom: 3px solid #0082c3; }

.card__view {
  position: relative;
  z-index: 8;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 40px 15px 15% 15px; }
  @media (min-width: 1025px) {
    .card__view {
      max-width: 450px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 8%; } }
  .card__view__item {
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #e2e2e2;
    transition: background-image .25s ease-in;
    background-color: #fafafa;
    background-image: linear-gradient(to top, #e2e2e2, #fafafa);
    width: 32%;
    padding: 10px;
    text-transform: uppercase;
    font-family: "Roboto Condensed Bold", Arial, Helvetica, sans-serif;
    text-align: center;
    cursor: pointer; }
    @media (min-width: 1025px) {
      .card__view__item {
        background: none;
        box-shadow: inherit;
        border: none;
        width: auto; } }
    .card__view__item--active {
      background-color: #fff;
      background-image: linear-gradient(to top, #ffffff, #ffffff); }
      @media (min-width: 1025px) {
        .card__view__item--active {
          background: none;
          border-bottom: 2px solid #000;
          font-style: italic; } }

.step5 .title--in-movement h1 span {
  display: block; }

@media (max-width: 767px) {
  .step5 .btn-back {
    width: 100%;
    margin-bottom: 10px; }
  .step5 .btn-next {
    width: 100%; } }

.overlayer .contact-submit {
  margin-top: 20px;
  display: flex;
  justify-content: space-between; }
  .overlayer .contact-submit .btn-card {
    min-width: 150px; }

.overlayer .alert {
  border-radius: 28.5px;
  background-color: #e8e8e8;
  width: 100%;
  text-align: center;
  color: #0082c3;
  padding: 10px;
  margin-bottom: 30px; }
  .overlayer .alert:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    margin-right: 7px;
    width: 22px;
    height: 19px;
    background: transparent url(../images/steps/step5/alert.png) no-repeat center center;
    background-size: cover; }

@media (max-width: 767px) {
  .overlayer .flexie > div {
    width: 100%; } }

.overlayer.form {
  margin: 0 10px;
  padding: 0; }

.overlayer .mentions {
  font-size: 12px;
  margin-bottom: 20px;
  text-align: right; }

.overlayer .delivery-label, .overlayer .date-label {
  margin: 10px;
  font-size: 18px; }

.overlayer .arrow-more {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 6px;
  border-top: 6px solid #F36F21;
  border-bottom: 6px solid transparent;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  margin-left: 75%; }

.overlayer .more-choice-beneficiary {
  width: calc(50% - 10px);
  margin: auto;
  display: block; }
  .overlayer .more-choice-beneficiary #beneficiary_email {
    height: auto;
    padding: 10px 15px; }

.overlayer .flexie.user-choice {
  justify-content: center; }

.overlayer .card__contact__field {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
  position: relative;
  z-index: 8; }
  .overlayer .card__contact__field label {
    flex: 1;
    border: 1px solid lightgrey;
    cursor: pointer; }
    .overlayer .card__contact__field label p {
      text-align: center;
      padding: 10px; }
    .overlayer .card__contact__field label input[type="radio"] {
      display: none; }
  .overlayer .card__contact__field label.active {
    border: 1px solid #0182c3;
    color: #0182c3; }

.overlayer input[type=email], .overlayer input[type=search], .overlayer input[type=number], .overlayer input[type=password], .overlayer input[type=tel], .overlayer input[type=text] {
  padding: 20px 15px;
  border: solid 1px #e2e2e2;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.15);
  width: 100%;
  color: #000; }
  @media (min-width: 768px) {
    .overlayer input[type=email], .overlayer input[type=search], .overlayer input[type=number], .overlayer input[type=password], .overlayer input[type=tel], .overlayer input[type=text] {
      box-shadow: inherit; } }
  .overlayer input[type=email]::placeholder, .overlayer input[type=search]::placeholder, .overlayer input[type=number]::placeholder, .overlayer input[type=password]::placeholder, .overlayer input[type=tel]::placeholder, .overlayer input[type=text]::placeholder {
    text-transform: uppercase;
    font-style: italic; }

.overlayer .title--in-movement {
  margin-bottom: 20px; }

@media (min-width: 768px) and (max-width: 1024px) {
  .flexie {
    flex-direction: row; }
    .flexie .flexie__item--1.card__contact__field {
      width: calc((100% - 10px));
      margin-left: 10px;
      margin-right: 10px; }
    .flexie .flexie__item--2.card__contact__field {
      width: calc((100% / 2) - 10px);
      margin-left: 10px;
      margin-right: 10px; } }

@keyframes lds-ripple {
  0% {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: 18px;
    left: 18px;
    width: 156px;
    height: 156px;
    opacity: 0; } }

#step5 {
  position: relative; }

.ng-scope {
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center; }
  #step5 .ng-scope {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    background-color: rgba(245, 245, 245, 0.7); }
    #step5 .ng-scope span {
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(60px);
      font-size: 1.1em; }

.lds-ripple {
  position: relative; }

.lds-ripple div {
  box-sizing: content-box;
  position: absolute;
  border-width: 4px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite; }

.lds-ripple div:nth-child(1) {
  border-color: #0082c3; }

.lds-ripple div:nth-child(2) {
  border-color: #0082c3;
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s; }

.lds-ripple {
  width: 120px !important;
  height: 120px !important;
  transform: translate(-60px, -60px) scale(0.6) translate(60px, 60px); }

@media (min-width: 1025px) {
  .card-editor-wrapper {
    margin-top: -30px; } }

.card-overlay {
  position: fixed;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  top: 40%;
  z-index: 9999;
  display: none; }
  .card-overlay .lds-ripple {
    display: block;
    margin: auto; }

body:not(.overlay--opened) .card-overlay {
  display: none; }

.overlay--opened {
  position: relative; }
  @media (min-width: 769px) {
    .overlay--opened:before {
      content: "";
      background: #000;
      opacity: .8;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      z-index: 30; } }

@media (min-width: 768px) {
  .step3 .step__progress__inner ol {
    margin-right: -300px; } }

@media screen and (min-width: 768px) and (min-width: 1024px) and (max-width: 1080px) {
  .step3 .step__progress__inner ol {
    margin-right: -250px; } }

@media (min-width: 768px) {
  .step4 .btn-back {
    top: 1.1%; }
  .step4 .step__progress__inner ol {
    margin-right: -160px; }
  .step5 .step__progress__inner ol {
    margin-right: -200px; } }

@media (max-width: 479px) {
  .step3 .title--in-movement h1 {
    max-width: 78%; } }

#toggle-menu + .menu {
  display: none; }

/* IE 1O+ Fix */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .step2 .flexie button {
    max-width: 100%; }
  .step2 .flexie .flexie__item--2 .flexie__item--m50 {
    width: 50%; }
  .step2 .flexie .choice-image {
    margin: auto; } }

.card__preview__back__item {
  height: 100%; }

.card-cover.card__bg_img {
  box-shadow: 0px -2px 5px inset rgba(60, 57, 57, 0.15); }

.card-wrapper {
  list-style: none;
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 210px; }
  @media (min-width: 768px) {
    .card-wrapper {
      min-height: 280px; } }
  @media (min-width: 1025px) {
    .card-wrapper {
      min-height: 350px; } }
  .card-wrapper .card-wrapper__item {
    position: relative;
    width: 400px;
    z-index: 1;
    perspective: 1800px;
    width: 270px; }
    @media (min-width: 768px) {
      .card-wrapper .card-wrapper__item {
        width: 360px; } }
    @media (min-width: 1025px) {
      .card-wrapper .card-wrapper__item {
        width: 450px; } }

.card-wrapper .card-wrapper__item {
  /* FIX IE */
  /********/ }
  .card-wrapper .card-wrapper__item .card-preview {
    position: absolute;
    width: 100%;
    transform-style: preserve-3d;
    transition: transform .5s;
    transform: rotate3d(4, 2, -2, 43deg);
    height: 180px; }
    @media (min-width: 768px) {
      .card-wrapper .card-wrapper__item .card-preview {
        min-height: 240px; } }
    @media (min-width: 1025px) {
      .card-wrapper .card-wrapper__item .card-preview {
        height: 300px; } }
    .card-wrapper .card-wrapper__item .card-preview > div {
      display: block;
      position: absolute; }
  .card-wrapper .card-wrapper__item .card-front {
    transform-style: preserve-3d;
    transform-origin: 0% 50%;
    transition: transform .5s;
    transform: translate3d(0, 0, 0) rotate3d(0, 1, 0, -17deg);
    z-index: 10; }
    .card-wrapper .card-wrapper__item .card-front > div {
      display: block;
      position: absolute;
      z-index: 1;
      backface-visibility: hidden;
      transform-style: preserve-3d; }
  .card-wrapper .card-wrapper__item .card-page {
    height: 180px;
    width: 240px; }
    @media (min-width: 768px) {
      .card-wrapper .card-wrapper__item .card-page {
        height: 240px;
        width: 320px; } }
    @media (min-width: 1025px) {
      .card-wrapper .card-wrapper__item .card-page {
        width: 400px;
        height: 300px; } }
  .card-wrapper .card-wrapper__item .card-front, .card-wrapper .card-wrapper__item .card-back, .card-wrapper .card-wrapper__item .card-front > div {
    height: 180px;
    width: 240px; }
    @media (min-width: 768px) {
      .card-wrapper .card-wrapper__item .card-front, .card-wrapper .card-wrapper__item .card-back, .card-wrapper .card-wrapper__item .card-front > div {
        height: 240px;
        width: 320px; } }
    @media (min-width: 1025px) {
      .card-wrapper .card-wrapper__item .card-front, .card-wrapper .card-wrapper__item .card-back, .card-wrapper .card-wrapper__item .card-front > div {
        width: 400px;
        height: 300px; } }
  .card-wrapper .card-wrapper__item .card-back {
    transition: transform .5s;
    transform: rotate3d(0, 0, 0, -18deg) scale3d(1, 1, 1) translate3d(0, 0, 0);
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.3);
    transform-style: preserve-3d; }
    .card-wrapper .card-wrapper__item .card-back > div {
      display: block;
      position: absolute;
      top: 0;
      z-index: 1;
      backface-visibility: hidden;
      transform-style: preserve-3d; }
    .card-wrapper .card-wrapper__item .card-back .card-back-cover {
      top: 0;
      transform: rotateY(180deg);
      height: 180px;
      width: 240px; }
      @media (min-width: 768px) {
        .card-wrapper .card-wrapper__item .card-back .card-back-cover {
          height: 240px;
          width: 320px; } }
      @media (min-width: 1025px) {
        .card-wrapper .card-wrapper__item .card-back .card-back-cover {
          width: 400px;
          height: 300px; } }
  .card-wrapper .card-wrapper__item .card-cover-back {
    background-color: #fff;
    transform: rotate3d(0, 1, 0, -179deg); }
  .card-wrapper .card-wrapper__item .card-viewinside .card-front {
    transform: translate3d(0, 0, 20px) rotate3d(0, 1, 0, -180deg); }
  .card-wrapper .card-wrapper__item .card-viewinside .card-back {
    transform: rotate3d(0, 1, 0, -18deg) scale3d(1, 1, 1) translate3d(15px, 0, 77px); }
  .card-wrapper .card-wrapper__item .card-preview.card-viewdefault .card-cover-back {
    backface-visibility: visible;
    display: none; }
  .card-wrapper .card-wrapper__item .card-preview.card-viewinside {
    transform: translate3d(50%, 0, 150px); }
    .card-wrapper .card-wrapper__item .card-preview.card-viewinside .card-front > div {
      backface-visibility: visible; }
    .card-wrapper .card-wrapper__item .card-preview.card-viewinside .card__bg_img {
      display: none; }
  .card-wrapper .card-wrapper__item .card-preview.card-viewback {
    transform: translate3d(-20px, -7px, 80px) rotate3d(-0.2, 1.7, -0.2, -190deg); }
    @media (min-width: 768px) {
      .card-wrapper .card-wrapper__item .card-preview.card-viewback {
        transform: translate3d(0, -30px, 80px) rotate3d(-0.2, 1.7, -0.2, -190deg); } }
    .card-wrapper .card-wrapper__item .card-preview.card-viewback .card-front {
      transform: translate3d(0, 0, 0) rotate3d(0, 1, 0, -1deg); }
  .card-wrapper .card-wrapper__item .card-page {
    background-color: #fff; }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .card-wrapper .card-wrapper__item .card-front {
      transition: transform .0s; }
    .card-wrapper .card-wrapper__item .card-viewdefault {
      transform: none; }
      .card-wrapper .card-wrapper__item .card-viewdefault .card-front {
        transform: none; }
    .card-wrapper .card-wrapper__item .card-viewinside .card-front {
      transform: translate3d(-400px, 0px, 20px); }
      .card-wrapper .card-wrapper__item .card-viewinside .card-front .card-cover-back {
        backface-visibility: visible;
        transform: none; }
      .card-wrapper .card-wrapper__item .card-viewinside .card-front .card__bg_img {
        display: none; }
    .card-wrapper .card-wrapper__item .card-viewinside .card-back {
      transform: none; }
    .card-wrapper .card-wrapper__item .card-viewback {
      transform: none !important; }
      .card-wrapper .card-wrapper__item .card-viewback .card-front {
        z-index: 0; }
      .card-wrapper .card-wrapper__item .card-viewback .card-back-cover {
        backface-visibility: visible;
        transform: none; } }

.card-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 30px;
  overflow: hidden;
  background: #fff;
  opacity: 0;
  pointer-events: none;
  backface-visibility: hidden;
  transition: opacity 0.3s ease-in-out;
  cursor: default;
  padding: 18px;
  color: #0082c3;
  font-family: "Open Sans Condensed", sans-serif; }
  .card-content p {
    padding: 0 0 10px;
    -webkit-font-smoothing: antialiased;
    color: currentColor;
    font-size: 6.6px;
    line-height: 1.6;
    text-align: justify;
    -webkit-touch-callout: none;
    user-select: none; }
  @media (min-width: 768px) {
    .card-content {
      padding: 24px; }
      .card-content p {
        font-size: 8.8px; } }
  @media (min-width: 1025px) {
    .card-content {
      padding: 30px; }
      .card-content p {
        font-size: 11px; } }

.card-content-current {
  opacity: 1;
  pointer-events: auto; }
  .card-content-current .card-supplier {
    text-align: right; }
  .card-content-current-message {
    white-space: pre-wrap;
    overflow-wrap: break-word; }

.card-preview .card-cover {
  background-color: #fff;
  background-repeat: no-repeat; }
  .card-preview .card-cover .card__main_img {
    position: absolute;
    top: 18px;
    right: 18px;
    bottom: 18px;
    left: 18px;
    background: no-repeat;
    background-position: center center; }
    @media (min-width: 768px) {
      .card-preview .card-cover .card__main_img {
        top: 24px;
        right: 24px;
        bottom: 24px;
        left: 24px; } }
    @media (min-width: 1025px) {
      .card-preview .card-cover .card__main_img {
        top: 30px;
        right: 30px;
        bottom: 30px;
        left: 30px; } }

.card-preview .card-back p {
  color: #fff;
  font-size: 13px;
  padding: 40px;
  text-align: center;
  font-weight: 700; }

@media (max-width: 529px) {
  .card-wrapper .card-wrapper__item .card-preview.card-viewinside {
    transform: translate3d(45%, 0, 150px) rotate3d(0, 1, 0, 0deg) scale(0.7); } }

.customer-account-login .col2-set .buttons-set .btn-card {
  display: block;
  margin: auto; }

.card-tips {
  display: flex;
  padding: 10px; }
  @media only screen and (max-width: 479px) {
    .card-tips {
      flex-direction: column; } }
  .card-tips .btn-solde-left a {
    margin-left: -65px; }
    @media only screen and (max-width: 1126px) {
      .card-tips .btn-solde-left a {
        margin-left: 0; } }
    @media only screen and (max-width: 479px) {
      .card-tips .btn-solde-left a {
        width: 100%; } }
  .card-tips .btn-solde-right {
    margin-left: auto; }
    @media only screen and (max-width: 479px) {
      .card-tips .btn-solde-right {
        width: 100%; } }
    .card-tips .btn-solde-right a {
      margin-right: -65px; }
      @media only screen and (max-width: 1126px) {
        .card-tips .btn-solde-right a {
          margin-right: 0; } }
      @media only screen and (max-width: 479px) {
        .card-tips .btn-solde-right a {
          width: 100%;
          margin-top: 5px; } }

.data-table .product-cart-info .item-options {
  margin-left: 0;
  padding-left: 0; }
  .data-table .product-cart-info .item-options dd {
    padding-left: 0; }

#checkout-review-table-wrapper .data-table tbody td .item-options {
  margin-left: 0; }
  #checkout-review-table-wrapper .data-table tbody td .item-options dt:after {
    content: none; }
  #checkout-review-table-wrapper .data-table tbody td .item-options dd {
    padding-left: 0; }

.bottom-checkout-review {
  display: flex;
  justify-content: space-between; }
  @media only screen and (max-width: 905px) {
    .bottom-checkout-review {
      flex-direction: column; } }
  .bottom-checkout-review .additional-text-checkout {
    width: 70%; }
    @media only screen and (max-width: 905px) {
      .bottom-checkout-review .additional-text-checkout {
        width: 100%; } }
  .bottom-checkout-review #review-buttons-container {
    width: 23%;
    height: 48px; }

#cookiealert {
  display: none; }

.row {
  margin: 10px 0; }
  .row input[type=email], .row input[type=search], .row input[type=number], .row input[type=password], .row input[type=tel], .row input[type=text] {
    width: auto; }

fieldset {
  border-bottom: 1px solid #aaa;
  padding: 20px; }

.row input, .row select {
  height: 40px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #aaa;
  color: #636363; }
